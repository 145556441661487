<template>
  <v-sheet>
    <v-row>
      <v-col cols="12" class="text-h5 font-weight-bold">Billing Details</v-col>
      <v-col cols="6">
        <v-text-field
          v-model="billingInfo.company_name"
          label="Company name"
          outlined
          dense
        />
      </v-col>
      <v-col cols="6">
        <google-address
          v-model="billingInfo.address"
          label="Company address"
          @change="locationChanged"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="billingInfo.company_vat"
          label="VAT ID"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <div class="d-flex justify-end">
      <v-btn color="primary" small @click="updateBillingDetails">Save</v-btn>
    </div>
    <v-row class="align-center">
      <v-col cols="9" class="text-h5 font-weight-bold">Bills</v-col>
      <v-col class="d-flex justify-end" cols="3">
        <div class="d-flex">
          <div class="me-4">
            <add-credit-card :tenant-id="tenantId" :last4="last4" />
          </div>
          <month-year-picker
            :is-yearly="true"
            :min-year="2024"
            :max-year="new Date().getFullYear()"
            @change="onDateChange"
          />
        </div>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
      color="info"
      indeterminate
      rounded
    ></v-progress-linear>
    <v-row>
      <v-col cols="12">
        <v-expansion-panels>
          <v-expansion-panel v-for="(log, index) in logs" :key="index">
            <v-expansion-panel-header>
              <v-sheet max-width="100px" class="pa-0 font-weight-semibold">
                {{ $moment(log.stats_date).format('MMMM') }}
              </v-sheet>
              <v-sheet max-width="40px">|</v-sheet>
              <v-sheet class="pa-0 font-weight-bold"
                >{{ toMoney(monthlyCharge(log)) }}
                <span
                  class="pa-0 ms-2 font-weight-semibold text-caption text--secondary"
                  >({{ log.chargable_listings_amount }}
                  Charged listings x
                  {{ toMoney(log.per_door) }}
                  Per door)
                </span>
              </v-sheet>
              <div
                v-if="log.charged"
                class="d-flex flex-center font-weight-bold text-body-2 text--secondary text--darken-3"
              >
                <v-icon color="success" class="me-2">
                  mdi-check-decagram
                </v-icon>
                <div>
                  <span class="me-2"> Charged: {{ toMoney(log.charged) }}</span>
                  <a
                    target="_blank"
                    :href="log.receipts[0]"
                    class="text-decoration-underline blue--text"
                    >Invoice</a
                  >
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="text-body-2">
              <div class="font-weight-bold my-2">Active Listings:</div>
              <div class="d-flex flex-wrap" style="line-height: 2rem">
                <div v-for="(nickname, key) in log.active_listings" :key="key">
                  <a
                    class="grey--text text--darken-3 me-3"
                    :href="`/dashboard/edit/${key}`"
                    target="_blank"
                  >
                    {{ nickname }}</a
                  >
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import MonthYearPicker from 'components/common/month-year-picker'
import { mapActions, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import AddCreditCard from 'components/reservation/add-credit-card.vue'
import GoogleAddress from 'components/common/google-address.vue'

export default {
  components: {
    GoogleAddress,
    MonthYearPicker,
    AddCreditCard,
  },
  mixins: [formattersMixin],
  props: ['tenantId', 'last4'],
  data() {
    return {
      loading: false,
      logs: [],
      billingInfo: {
        company_vat: null,
        company_name: null,
        address: null,
        zip_code: null,
        country: null,
        city: null,
      },
      place: null,
    }
  },
  async mounted() {
    this.getBilling()
    this.billingInfo = { ...this.billingInfo, ...this.settings.billing_info }
  },
  methods: {
    ...mapActions('billing', ['fetchBilling']),
    ...mapActions(['updateSettings']),
    locationChanged(data) {
      this.billingInfo.address = data.address
      this.billingInfo.zip_code = data.zip_code
      this.billingInfo.country = data.country
      this.billingInfo.city = data.city
    },
    updateBillingDetails() {
      this.updateSettings({ billing_info: this.billingInfo })
    },
    monthlyCharge(log) {
      return log.per_door * log.chargable_listings_amount
    },
    async getBilling(year) {
      this.loading = true
      const { data } = await this.fetchBilling({
        year,
        tenant_id: this.tenantId,
      })
      this.loading = false
      this.logs = data.logs
    },
    onDateChange({ year }) {
      this.getBilling(year)
    },
  },
  computed: {
    ...mapState(['settings']),
  },
}
</script>
