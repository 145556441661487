<template>
  <v-row>
    <v-radio-group v-model="typeToggle" row class="mt-2 mx-3">
      <v-radio class="mr-2" value="owner">
        <template #label>
          <div :class="radioClass">Owner</div>
        </template></v-radio
      >
      <v-radio class="mr-2" value="reservations">
        <template #label>
          <div :class="radioClass">Reservations</div>
        </template></v-radio
      >
      <v-radio value="contractor">
        <template #label>
          <div :class="radioClass">Contractors</div>
        </template></v-radio
      >
    </v-radio-group>
    <v-col v-show="typeToggle === 'owner'" cols="12">
      <v-row>
        <v-col col="12" md="6">
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Dept to owners"
              color="#8126C4"
              :value="dollarFormatter(stats.total_month_balance)"
            />
            <dashboard-data-box
              title="Paid owners"
              color="#E69B51"
              :value="dollarFormatter(stats.total_paid_balance || 0)"
            />
          </v-sheet>
          <v-sheet rounded class="pa-4 my-2" elevation="1">
            <sliders-chart title="Depts" :items="stats.balance_by_listing" />
          </v-sheet>
        </v-col>
        <v-col col="12" md="6">
          <v-sheet rounded class="pa-4" elevation="1">
            <line-chart
              graph-id="balance"
              label="Balance"
              title="Yearly Owner Balance"
              :stats-object="chartsConfig"
              :config="chartsConfig"
              color="#02BC77"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-show="typeToggle === 'contractor'" cols="12">
      <v-row>
        <v-col>
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Paid 3rd Contractors"
              color="#8126C4"
              :value="dollarFormatter(stats.none_payrole_paid || 0)"
            />
            <dashboard-data-box
              title="UN-Paid 3rd contractors"
              color="#4367B5"
              :value="dollarFormatter(stats.none_payrole_unpaid || 0)"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet rounded class="pa-4" elevation="1">
            <line-chart
              graph-id="c-balance"
              label="Contractors Balance"
              title="Yearly Contractors Balance"
              :stats-object="chartsContractorsConfig"
              :config="chartsContractorsConfig"
              color="#02BC77"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-show="typeToggle === 'reservations'" cols="12">
      <v-row>
        <v-col>
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Pre paid"
              color="#8126C4"
              :value="dollarFormatter(stats.pre_paid_reservations || 0)"
            />
          </v-sheet>
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Pending Channel Payment"
              color="#4367B5"
              :value="dollarFormatter(stats.pending_payment_reservations || 0)"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet rounded class="pa-4" elevation="1">
            <line-chart
              graph-id="pre-paid"
              title="Yearly Reservation Balance"
              :stats-object="chartsReservationsConfig"
              :config="chartsReservationsConfig"
              color="#02BC77"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DashboardDataBox from 'components/accounting/dashboard-data-box.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import LineChart from 'components/line-chart.vue'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import SlidersChart from 'components/accounting/sliders-chart.vue'

export default {
  name: 'AccountingExpensesTab',
  mixins: [FormattersMixin],
  components: { SlidersChart, LineChart, DashboardDataBox },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    dates: {
      type: Object,
    },
    year: {
      type: Number,
    },
    yearly: {
      type: Boolean,
    },
  },
  data() {
    return {
      radioClass: 'text-caption text-capitalize font-weight-medium',
      typeToggle: 'owner',
    }
  },
  computed: {
    months() {
      return MONTHS
    },
    chartsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data: this.stats.monthly_balance.map(v => round(v, 2)) || [],
              borderColor: '#ff4757',
              backgroundColor: '#ff4757',
              fill: false,
              label: 'Remaining Balance',
            },
            {
              data: this.stats.paid_month_balance.map(v => round(v, 2)) || [],
              borderColor: '#02BC77',
              backgroundColor: '#02BC77',
              fill: false,
              label: 'Paid Balance',
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
              },
            ],
          },
        },
      }
    },
    chartsContractorsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data:
                this.stats.none_payrole_unpaid_month_paid.map(v =>
                  round(v, 2)
                ) || [],
              borderColor: '#ff4757',
              backgroundColor: '#ff4757',
              fill: false,
              label: 'Remaining Balance',
            },
            {
              data:
                this.stats.none_payrole_paid_month_balance.map(v =>
                  round(v, 2)
                ) || [],
              borderColor: '#02BC77',
              backgroundColor: '#02BC77',
              fill: false,
              label: 'Paid Balance',
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
              },
            ],
          },
        },
      }
    },
    chartsReservationsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data:
                this.stats.pre_paid_reservations_months.map(v => round(v, 2)) ||
                [],
              borderColor: '#02BC77',
              backgroundColor: '#02BC77',
              fill: false,
              label: 'Pre Paid Deposit',
            },
            {
              data:
                this.stats.pending_payment_reservations_months.map(v =>
                  round(v, 2)
                ) || [],
              borderColor: '#ff4757',
              backgroundColor: '#ff4757',
              fill: false,
              label: 'Pending Payment',
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
              },
            ],
          },
        },
      }
    },
  },
}
</script>

<style scoped></style>
