<template>
  <v-row :class="{ 'd-inline-block mx-0 flex-grow-0': !thumbnails }">
    <div v-if="thumbnails" class="d-flex flex-wrap flex">
      <!--      <v-col-->
      <!--        v-if="uploadable"-->
      <!--        :cols="cols"-->
      <!--        class="d-flex justify-center align-center"-->
      <!--      >-->
      <!--        <div class="box">-->
      <!--          <media-upload-->
      <!--            :camera="true"-->
      <!--            :thumbnail="true"-->
      <!--            :disabled="disabled"-->
      <!--            :button-text="buttonText"-->
      <!--            :on-change="imageUploaded"-->
      <!--          />-->
      <!--        </div>-->
      <!--      </v-col>-->
      <v-col
        v-if="uploadable"
        :cols="buttonWidth || buttonHeight ? 12 : cols"
        class="d-flex justify-center align-center"
      >
        <div
          class="box"
          :style="{
            height: buttonHeight ? buttonHeight + 'px' : '100%',
            paddingTop: buttonHeight ? buttonHeight : '100%',
          }"
        >
          <media-upload
            :is-android="isAndroid"
            :thumbnail="true"
            :limitation="limitation"
            :folder="folder"
            :compress="compress"
            :button-height="buttonHeight"
            :disabled="disabled"
            :uploading.sync="uploading"
            :button-text="$t(buttonText)"
            :on-change="imageUploaded"
          />
        </div>
      </v-col>
      <div
        ref="imgsContainerRef"
        class="imgs-container"
        :style="draggable ? 'display: flex' : 'display: contents'"
      >
        <v-col v-for="img in formattedImages" :key="img.src" :cols="cols">
          <div
            v-if="img.src !== 'loading'"
            class="box"
            :style="{
              paddingTop: buttonHeight ? buttonHeight : '100%',
            }"
          >
            <v-tooltip v-if="img.class" bottom color="warning">
              <template #activator="{ on }">
                <div class="d-flex" v-on="on">
                  <ImageView
                    :readonly="readonly"
                    :on-remove="removeImg"
                    class="hover-image-view"
                    :class="img.class"
                    :meta="imageMeta(img.src)"
                    :src="img.src"
                    small-width="100%"
                    @click="imageClicked"
                  />
                </div>
              </template>
              <span class="text-caption">Too small, min 1024x768</span>
            </v-tooltip>
            <ImageView
              v-else
              :readonly="readonly"
              :on-remove="removeImg"
              class="hover-image-view"
              :meta="imageMeta(img.src)"
              :caption="img.caption"
              :src="img.src"
              small-width="100%"
              @click="imageClicked"
            />
          </div>
          <div v-else class="box">
            <v-skeleton-loader width="100%" height="100%" type="image" />
          </div>
        </v-col>
      </div>
    </div>
    <span v-else class="pr-2 text-caption pointer" @click.stop="imageClicked"
      ><v-icon small :color="iconColor">{{ icon }} </v-icon
      ><span v-if="!hideFileCount">({{ images.length || 0 }})</span>
    </span>
    <v-dialog
      v-if="dialog"
      v-model="dialog"
      content-class="rounded-xl"
      class="white"
      :width="zoomIn ? 1600 : 500"
      @click:outside="currImage = null"
    >
      <v-sheet class="rounded-xl overflow-auto">
        <v-carousel
          v-model="currImage"
          :hide-delimiters="mappedImages.length > 10"
          height="auto"
        >
          <v-carousel-item
            v-for="slide in formattedImages"
            :key="slide.src"
            class="pb-10"
            :value="slide.src"
          >
            <div class="pa-3 d-flex justify-space-between">
              <v-btn
                v-if="!readonly"
                class="white top-left"
                fab
                icon
                x-small
                @click.stop="rotate(slide.src)"
              >
                <v-icon color="darkpurple">$rotate</v-icon>
              </v-btn>
              <v-btn
                class="white bottom-left"
                fab
                icon
                x-small
                @click.stop="downloadItem(slide.src)"
              >
                <v-icon color="darkpurple">fas fa-arrow-alt-circle-down</v-icon>
              </v-btn>

              <CopyClipboard color="darkpurple" :text="slide.src" />
              <v-btn
                v-if="listingId"
                class="white top-right"
                fab
                icon
                x-small
                @click="showInfoModal(slide.src)"
              >
                <v-icon color="darkpurple">fas fa-home</v-icon>
              </v-btn>
              <v-btn
                v-if="!readonly"
                class="white top-right"
                fab
                icon
                x-small
                @click.stop="remove(slide.src)"
              >
                <v-icon color="warning">$trash</v-icon>
              </v-btn>
            </div>
            <iframe
              v-if="isPdf(slide.src)"
              width="100%"
              height="700px"
              :src="`${slide.src}`"
            />
            <video
              v-else-if="isVideo(slide.src) && currImage === slide.src"
              width="100%"
              autoplay
              controls
              height="500"
              :src="slide.src"
            />
            <v-img
              v-else
              :class="{ 'image-zoom': zoomIn }"
              class="zoom-hover"
              contain
              :src="slide.src"
              @click="zoom"
            >
            </v-img>
            <div v-if="showCaption" class="d-flex pa-1 my-2 align-center">
              <div class="pa-1 d-flex col-12">
                <v-text-field
                  v-model="slide.caption"
                  label="Caption"
                  dense
                  outlined
                  class="mr-1"
                  hide-details
                  @input="debouncedInput(slide)"
                ></v-text-field>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-sheet>
    </v-dialog>
  </v-row>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ImageView from 'components/common/image-view'
import CopyClipboard from 'components/copy-clipboard'
import MediaUpload from 'components/common/media-upload.vue'
import { mapGetters } from 'vuex'
import get from 'lodash/fp/get'
import isEmpty from 'lodash/fp/isEmpty'
import Sortable from 'sortablejs'
import debounce from 'lodash/debounce'

export default {
  name: 'Gallery',
  components: { MediaUpload, CopyClipboard, ImageView },
  mixins: [CommonFunctions, PermissionsMixin],
  props: {
    images: {
      type: Array,
      default: () => [],
    },
    limitation: {
      type: Function,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: 'Upload',
    },
    buttonWidth: {
      type: [String, Number],
    },
    buttonHeight: {
      type: [String, Number],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    thumbnails: {
      type: Boolean,
      default: false,
    },
    uploadable: {
      type: Boolean,
      default: false,
    },
    rotateImage: {
      type: Function,
      default: () => ({}),
    },
    removeImg: {
      type: Function,
      default: () => ({}),
    },
    icon: {
      type: String,
    },
    listingId: {
      type: Number,
    },
    iconColor: {
      type: String,
    },
    folder: {
      type: String,
    },
    cols: {
      type: Number,
      default: 6,
    },
    onChange: {
      type: Function,
      default: () => ({}),
    },
    compress: {
      type: Boolean,
      default: true,
    },
    imageValue: {
      type: String,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    showCaption: {
      type: Boolean,
      default: false,
    },
    hideFileCount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hovered: false,
      zoomIn: false,
      uploading: false,
      dialog: false,
      currImage: '',
      imgsContainerRef: null,
      formattedImages: [],
    }
  },
  mounted() {
    if (this.draggable) {
      Sortable.create(this.$refs.imgsContainerRef, {
        animation: 150,
        onUpdate: this.onDragged,
        ghostClass: 'ghost',
      })
    }
    this.formattedImages = this.imageValue
      ? this.images.map(i => ({
          src: i[this.imageValue],
          caption: i.caption,
          sort: i['sort'],
        }))
      : this.images.map(i => ({ src: i }))

    if (this.limitation) {
      this.formattedImages.forEach((image, index) => {
        this.validateImage(image.src, index)
      })
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'currentListingTask']),
    imagesLength() {
      return this.images && this.images.length
    },
    getDeviceType() {
      const userAgent = window.navigator.userAgent
      if (/Android/i.test(userAgent)) {
        return 'Android'
      } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        return 'iPhone'
      } else {
        return 'Unknown'
      }
    },
    isAndroid() {
      return this.getDeviceType === 'Android'
    },
    mappedImages() {
      return this.imageValue
        ? this.images.map(i => ({ src: i[this.imageValue] }))
        : this.images.map(i => ({ src: i }))
    },
  },
  watch: {
    uploading(val) {
      if (val) {
        this.$emit('upload-started')
      }
    },
    imagesLength(value, oldValue) {
      if (!value) {
        this.dialog = false
      }
      if (oldValue !== value) {
        this.formattedImages = this.imageValue
          ? this.images.map(i => ({
              src: i[this.imageValue],
              caption: i['caption'],
              sort: i['sort'],
            }))
          : this.images.map(i => ({ src: i }))
        if (this.limitation) {
          this.formattedImages.forEach((image, index) => {
            this.validateImage(image.src, index)
          })
        }
      }
    },
  },
  methods: {
    validateImage(src, index) {
      const img = new Image()
      img.onload = () => {
        if (img.width < 1024 || img.height < 683) {
          this.$set(this.formattedImages, index, {
            ...this.formattedImages[index],
            class: 'invalid-resolution',
          })
        }
      }
      img.src = src
    },
    showInfoModal(src) {
      this.$store.commit('showModal', {
        name: 'InfoItemModal',
        props: { listingId: this.listingId, imageUrl: src },
      })
    },
    imageMeta(img) {
      return get(['media_meta', img], this.currentListingTask)
    },
    imageClicked(img) {
      if (isEmpty(this.images)) {
        return
      }
      this.currImage = img
      this.dialog = true
    },
    hoverPic() {
      this.hovered = true
    },
    zoom() {
      this.zoomIn = !this.zoomIn
    },
    outPic() {
      this.hovered = false
    },
    rotate(oldLink) {
      this.rotateImage(oldLink)(this.rotateImg(oldLink))
    },
    remove(link) {
      this.removeImg(link)
    },
    imageUploaded(images) {
      this.onChange(images)
    },
    onDragged(e) {
      this.$emit('on-dragged', e)
    },
    debouncedInput: debounce(async function (image) {
      this.images.find(a => a.sort == image.sort).caption = image.caption
      this.$emit('save')
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.hover-image-view:hover {
  cursor: pointer;
}

.zoom-hover:hover {
  cursor: zoom-in;
}

.zoom-hover.image-zoom:hover {
  cursor: zoom-out;
}
.invalid-resolution {
  border: 2px solid red;
}
.box {
  width: 100%;
  position: relative;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}
.imgs-container {
  flex-wrap: wrap;
  width: 100%;
}
.ghost {
  opacity: 0.1;
}
</style>
