<template>
  <div>
    <v-row class="justify-space-between flex-wrap">
      <v-row v-if="notContractorAndInvestor" class="align-center mx-0">
        <v-col cols="7" class="d-flex align-center">
          <v-select
            :label="$t('Saved Filters')"
            outlined
            dense
            clearable
            :value="selectedSavedFilter"
            :items="savedFilters"
            hide-details
            @change="changeFilter"
          >
            <template
              v-for="slotName in ['item', 'selection']"
              :slot="slotName"
              slot-scope="{ item }"
            >
              <v-list-item-title
                :key="slotName"
                class="d-flex"
                @click="
                  slotName === 'item' &&
                    selectedSavedFilter === item.value &&
                    changeFilter(item.value)
                "
              >
                <v-icon
                  v-if="item.text === defaultUserCalendarFilterName"
                  small
                  class="mr-1"
                  color="primary"
                  >fa fa-star
                </v-icon>
                <div>{{ item.text }}</div>
              </v-list-item-title>
            </template>
          </v-select>
        </v-col>
        <v-btn
          color="primary"
          class="white--text"
          depressed
          @click="toggleFilters"
        >
          <v-icon :class="filtersOpen ? 'fa-rotate-90' : ''" left small
            >$filters
          </v-icon>
          Filters
        </v-btn>
        <ConfirmationModal
          v-if="selectedSavedFilter"
          :text="`$t('Are you sure you want to delete') <b>${selectedSavedFilter}</b> $t('filter') ?`"
          @action="deleteFilter"
        >
          <template #activator="slotData">
            <v-icon class="mx-2" color="warning" small v-on="slotData.on"
              >$trash
            </v-icon>
          </template>
        </ConfirmationModal>
        <ConfirmationModal
          v-if="selectedSavedFilter && filterChanged"
          :text="`$t('Are you sure you want to override') <b>${selectedSavedFilter}</b> $t('filter') ?`"
          @action="overrideFilters"
        >
          <template #activator="slotData">
            <v-btn class="ml-3" color="error" small depressed v-on="slotData.on"
              >Override
            </v-btn>
          </template>
        </ConfirmationModal>
        <v-btn
          v-if="
            selectedSavedFilter &&
            selectedSavedFilter !== defaultUserCalendarFilterName
          "
          class="ml-3"
          color="primary"
          small
          outlined
          @click="setFilterAsDefault"
          >{{ $t('Set as Default') }}
        </v-btn>
      </v-row>
      <v-col v-else />
    </v-row>
    <v-slide-y-transition>
      <v-row v-show="filtersOpen" class="flex-wrap">
        <v-col
          v-if="!excludedFiltersSet.has('division')"
          cols="12"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <v-combobox
            :items="divisions"
            :clearable="!disabledFiltersSet.has('division')"
            :readonly="disabledFiltersSet.has('division')"
            :label="$t('Division')"
            :value="filters.division"
            dense
            hide-details
            outlined
            @change="updateFilters({ key: 'division' }, $event)"
          />
        </v-col>
        <v-col
          v-if="!excludedFiltersSet.has('searchTerm')"
          cols="12"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <v-text-field
            hide-details
            prepend-inner-icon="search"
            outlined
            :value="filters.searchTerm"
            dense
            :label="$t('Search by description')"
            :readonly="disabledFiltersSet.has('searchTerm')"
            @keyup="descChanged"
          />
        </v-col>
        <v-col
          v-if="
            notContractorAndInvestor &&
            !excludedFiltersSet.has('availabilities')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Availabilities')"
            :value="filters.availabilities"
            :items="availabilitiesOptions"
            :selection-func="item => `${item.text}|${item.group}`"
            :readonly="disabledFiltersSet.has('availabilities')"
            @change="
              updateFilters(
                {
                  key: 'availabilities',
                },
                $event
              )
            "
          />
        </v-col>
        <v-col
          v-if="
            notContractorAndInvestor && !excludedFiltersSet.has('contractor_id')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <contractor-select
            :filter-change="
              updateFilters.bind(null, {
                key: 'contractor_id',
              })
            "
            :include-in-active="true"
            :initials="true"
            :label="$t('Assigned To')"
            :value="filters.contractor_id"
            :allow-multiple="true"
            :readonly="disabledFiltersSet.has('contractor_id')"
          />
        </v-col>
        <v-col
          v-if="
            !currentListingId &&
            notContractorAndInvestor &&
            !excludedFiltersSet.has('listings_id')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <multi-property-picker
            :filter-change="
              updateFilters.bind(null, {
                key: 'listings_id',
              })
            "
            :value="filters.listings_id"
            :hide-btns="true"
            :readonly="disabledFiltersSet.has('listings_id')"
          />
        </v-col>
        <v-col
          v-if="
            notContractorAndInvestor && !excludedFiltersSet.has('project_id')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <v-combobox
            :items="activeProjects"
            :clearable="!disabledFiltersSet.has('project_id')"
            :label="$t('Project Name')"
            item-text="name"
            item-value="id"
            :value="filters.project_id"
            dense
            hide-details
            outlined
            :readonly="disabledFiltersSet.has('project_id')"
            @change="updateFilters({ key: 'project' }, $event)"
          />
        </v-col>
        <v-col
          v-if="notContractorAndInvestor && !excludedFiltersSet.has('cities')"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Cities')"
            :items="listingCities"
            :value="filters.cities"
            :readonly="disabledFiltersSet.has('cities')"
            @change="updateFilters({ key: 'cities' }, $event)"
          />
        </v-col>
        <v-col
          v-if="notContractorAndInvestor && !excludedFiltersSet.has('regions')"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Regions')"
            :items="listingRegions"
            :value="filters.regions"
            :selection-func="item => item.text"
            :readonly="disabledFiltersSet.has('regions')"
            @change="updateFilters({ key: 'regions' }, $event)"
          />
        </v-col>
        <v-col
          v-if="notContractorAndInvestor && !excludedFiltersSet.has('openedBy')"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <contractor-select
            :filter-change="updateFilters.bind(null, { key: 'openedBy' })"
            :initials="true"
            :label="$t('Opened By')"
            :value="filters.openedBy"
            :allow-multiple="true"
            :readonly="disabledFiltersSet.has('openedBy')"
          />
        </v-col>
        <v-col
          v-if="
            notContractorAndInvestor &&
            onlyItemsFiltered.length &&
            !excludedFiltersSet.has('Show Only')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Show Only')"
            :value="filters.onlyItems"
            :selection-func="item => item.text"
            :items="onlyItemsFiltered"
            :readonly="disabledFiltersSet.has('Show Only')"
            @change="updateFilters({ key: 'onlyItems' }, $event)"
          />
        </v-col>
        <v-col
          v-if="
            notContractorAndInvestor && !excludedFiltersSet.has('priorities')
          "
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Priority')"
            :value="filters.priorities"
            :items="['Critical', 'High', 'Med', 'Low']"
            :readonly="disabledFiltersSet.has('priorities')"
            @change="updateFilters({ key: 'priorities' }, $event)"
          />
        </v-col>
        <v-col
          v-if="!excludedFiltersSet.has('categories')"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <FiltersSelect
            :items="listingTaskTypes"
            :label="$t('Topics')"
            :value="filters.categories"
            :readonly="disabledFiltersSet.has('categories')"
            @change="updateFilters({ key: 'categories' }, $event)"
          />
        </v-col>
        <v-col
          v-if="!excludedFiltersSet.has('status')"
          cols="6"
          sm="4"
          md="3"
          lg="2"
          class="pb-0"
        >
          <filters-select
            :label="$t('Status')"
            :value="filters.status"
            :items="ltStatuses"
            :readonly="disabledFiltersSet.has('status')"
            @change="updateFilters({ key: 'status' }, $event)"
          />
        </v-col>
        <v-col
          v-if="!excludedFiltersSet.has('listingCatalogItemId')"
          cols="6"
          sm="4"
          class="pb-0"
        >
          <catalog-quick-select
            :simple-mode="true"
            :filter-change="
              updateFilters.bind(null, {
                key: 'listingCatalogItemId',
              })
            "
            :value="filters.listingCatalogItemId"
            :readonly="disabledFiltersSet.has('listingCatalogItemId')"
          />
        </v-col>
        <v-col cols="12">
          <SaveFilterModal :filters="filters" @saved="onFilterSave" />
          <v-btn
            :disabled="!filterChanged && !selectedSavedFilter"
            text
            color="warning"
            @click="clearAllFilters"
            >Clear All
          </v-btn>
          <v-btn
            v-if="$listeners['on-fetch']"
            :disabled="filterFetched"
            color="success"
            @click="apply"
            >Apply
          </v-btn>
        </v-col>
      </v-row>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { debounce } from 'lodash/fp'
import CalendarMixin from 'components/mixins/calendar-mixin'
import CommonMixin from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ContractorSelect from 'components/contractor-select'
import MultiPropertyPicker from 'components/multi-property-picker'
import FiltersSelect from 'components/form-fields/filters-select'
import SaveFilterModal from 'components/calendar/save-filter-modal'
import ConfirmationModal from 'components/modals/confirmation-modal'
import CatalogQuickSelect from 'components/catalog-quick-select'
import { initialFilters } from '@/modules/CalendarModule'

export default {
  name: 'TaskCalendarFilters',
  components: {
    ConfirmationModal,
    SaveFilterModal,
    FiltersSelect,
    MultiPropertyPicker,
    ContractorSelect,
    CatalogQuickSelect,
  },
  mixins: [CalendarMixin, CommonMixin, PermissionsMixin],
  props: ['listingId', 'openOnChange'],
  data() {
    return {
      filtersOpen: false,
      availabilitiesOptions: this.generateAvailabilitiesOptions(),
      filterChanged: false,
      filterFetched: true,
      filters: initialFilters,
    }
  },
  created() {
    if (!this.listingId) {
      this.filtersOpen = this.openByDefault
      const initialSavedFilters = this.initDefaultUserCalendarFilters()
      if (initialSavedFilters) {
        this.filters = initialSavedFilters
      } else if (this.currentUser.region) {
        this.filters.regions = [+this.currentUser.region]
      }
    }
  },
  computed: {
    ...mapState(['listingTasks']),
    ...mapState('taskCalendar', [
      'selectedSavedFilter',
      'excludedFilters',
      'forcedFilters',
    ]),
    ...mapState('projects', ['activeProjects']),
    ...mapState('regions', ['regions']),
    ...mapGetters('taskCalendar', {
      savedUserCalendarFilters: 'savedUserCalendarFilters',
      stateFilters: 'filters',
      defaultUserCalendarFilterName: 'defaultUserCalendarFilterName',
    }),
    ...mapGetters(['currentListingId', 'listingCities', 'currentUser']),
    excludedFiltersSet() {
      return new Set(this.excludedFilters)
    },
    disabledFiltersSet() {
      return new Set(Object.keys(this.forcedFilters))
    },
    onlyItemsFiltered() {
      return this.showOnlyItems.filter(
        i => !this.excludedFiltersSet.has(i.value)
      )
    },
    openByDefault() {
      return this.isOperationsPersonal && this.isDesignedVr
    },
    notContractorAndInvestor() {
      return !this.isContractor && !this.isInvestor
    },
    subTopicItems() {
      return this.subTopicsSelect(this.filters.categories)
    },
    savedFilters() {
      return Object.keys(this.savedUserCalendarFilters || {}).map(key => ({
        text: key,
        value: key,
      }))
    },
    listingRegions() {
      return this.regions.map(r => ({ text: r.name, value: r.id }))
    },
  },
  methods: {
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen
    },
    descChanged: debounce(500, function (event) {
      this.updateFilters({ key: 'searchTerm' }, event.target.value)
    }),
    updateFilters({ key }, values) {
      if (key === 'contractor_id') {
        this.$store.commit('updateMapContractorId', values)
        this.filters.categories = []
      }
      if (key === 'listings_id') {
        this.filters.categories = []
      }
      this.filterFetched = false
      this.filterChanged = true
      this.filters = { ...this.filters, [key]: values }
      if (!this.$listeners['on-fetch']) {
        this.$store.commit('taskCalendar/updateCalendarFilters', this.filters)
        this.$emit('change')
      }
    },
    apply() {
      this.$store.commit('taskCalendar/updateCalendarFilters', this.filters)
      this.$emit('on-fetch')
      this.filterFetched = true
      this.filterChanged = false
    },
    changeFilter(filterName) {
      if (filterName) {
        this.$store.commit('taskCalendar/updateSelectedSavedFilter', filterName)
        this.filters = this.savedUserCalendarFilters[filterName]
        this.filterChanged = false
        this.$store.commit('taskCalendar/updateCalendarFilters', this.filters)
        this.$emit('on-fetch')
      } else {
        this.resetFilters()
        this.filterFetched = false
      }
      this.$emit('change')
      if (this.openOnChange !== false) this.filtersOpen = true
    },
    resetFilters() {
      this.filters = { ...initialFilters }
      if (!this.$listeners['on-fetch']) {
        this.$store.commit('taskCalendar/updateCalendarFilters', this.filters)
      }
    },
    clearAllFilters() {
      this.changeFilter(null)
    },
    overrideFilters() {
      this.filterChanged = false
      const payload = {
        filters: {
          ...this.filters,
          searchTerm: '',
        },
        key: this.selectedSavedFilter,
      }
      this.$store.dispatch('users/saveCalendarFilter', payload)
    },
    setFilterAsDefault() {
      this.$store.dispatch('users/setFilterAsDefault', {
        key: this.selectedSavedFilter,
      })
    },
    async deleteFilter() {
      await this.$store.dispatch('users/deleteFilter', {
        key: this.selectedSavedFilter,
      })
      this.changeFilter(this.defaultUserCalendarFilterName)
    },
    onFilterSave({ key }) {
      this.$store.commit('taskCalendar/updateSelectedSavedFilter', key)
    },
  },
}
</script>

<style scoped></style>
