<template>
  <v-container class="pa-0 fill-height pb-2" :fluid="isMobileOrIPad">
    <v-overlay v-if="fetching" :opacity="0.1" absolute class="flex-center">
      <v-progress-circular color="primary" indeterminate size="50" />
    </v-overlay>
    <v-dialog fullscreen :value="isMobileOrIPad && feedOpen">
      <v-slide-x-transition>
        <lt-feed
          class="d-flex flex-column"
          :logs="logs"
          :task-id="currentListingTask.id"
          :users="activeUsers"
          :unseen="unseenComments"
          :comments-list="commentsList"
          :internal-comments="internalComments"
          @add-message="addComment"
          @update-comment="commentInternal"
        />
      </v-slide-x-transition>
    </v-dialog>
    <v-sheet
      v-if="currentListingTask"
      width="100%"
      class="fill-height listing-task-field-container"
    >
      <div class="d-flex justify-space-between align-center py-1">
        <v-list-item>
          <v-list-item-title>
            <div
              v-if="contractorId"
              class="d-flex justify-space-between align-center"
            >
              <div v-if="currentlyWorking" class="text-right">
                <v-btn class="warning" elevation="0" dark icon x-small>
                  <v-icon
                    x-small
                    @click="stopClocking(contractorId, currentListingTask.id)"
                    >mdi-stop
                  </v-icon>
                </v-btn>
                <span class="ml-2">{{ clock[contractorId] }}</span>
              </div>
              <div v-else class="text-right">
                <v-btn class="primary" elevation="0" dark icon x-small>
                  <v-icon x-small @click="continueTask">mdi-play </v-icon>
                </v-btn>
                <span class="ml-2"
                  >{{
                    cleanerTotalTime(contractorId, currentListingTask)
                  }}
                  min</span
                >
              </div>
            </div>
          </v-list-item-title>
        </v-list-item>
        <div class="d-flex align-center">
          <v-btn
            v-if="managementRoles || isCleaningManager || isCleaningSupervisor"
            color="black"
            height="32"
            elevation="0"
            icon
            class="mx-3"
            x-small
            @click="changeToManage"
          >
            <v-icon>$settings</v-icon>
          </v-btn>
          <v-btn
            :to="{
              name: 'service-calls',
              params: { paramsTab: 'today-tasks' },
            }"
            icon
            class="mx-3"
          >
            <v-icon size="18" color="black">$calendar</v-icon>
          </v-btn>
          <div class="mx-3" @click="showFeed">
            <v-badge
              v-if="commentsAmount !== 0"
              :color="unseenCommentsAmount !== 0 ? 'error' : 'black'"
              bordered
              class="mr-4"
              overlap
              small
              :content="
                unseenCommentsAmount !== 0
                  ? unseenCommentsAmount
                  : commentsAmount
              "
            >
              <v-icon size="22" color="inherit">$chat</v-icon>
            </v-badge>
            <v-icon v-else size="22" color="inherit">$chat</v-icon>
          </div>
        </div>
      </div>
      <lt-status
        :listing-task="currentListingTask"
        :hide-done="true"
        :small="false"
      />
      <v-stepper v-model="step" class="fill-height">
        <v-stepper-items>
          <component
            :is="step.component"
            v-for="(step, ind) in steps"
            :ref="step.component"
            :key="step.component"
            :step="ind + 1"
            :is-last-step="isLastStep"
            @toggle-loading="toggleLoading"
          />
        </v-stepper-items>
      </v-stepper>
      <v-sheet
        style="bottom: 0; position: fixed"
        width="100%"
        :value="true"
        height="50px"
        class="d-flex"
        fixed
      >
        <v-btn
          v-if="step !== 1"
          class="flex-fill rounded-0"
          :rounded="false"
          height="100%"
          text
          @click="step = step - 1"
        >
          {{ $t('Back') }}
        </v-btn>
        <v-btn
          v-if="needAccept"
          :rounded="false"
          height="100%"
          color="red"
          :loading="taskInitiating"
          :disabled="nextDisabled"
          class="white--text rounded-0 text-subtitle-1 flex-fill"
          @click="acceptRejectOffer(false)"
        >
          Reject
        </v-btn>
        <v-btn
          v-if="needAccept"
          :rounded="false"
          height="100%"
          color="primary"
          :loading="taskInitiating"
          :disabled="nextDisabled"
          class="white--text rounded-0 text-subtitle-1 flex-fill"
          @click="acceptRejectOffer(true)"
        >
          Accept
        </v-btn>
        <v-btn
          v-if="!needAccept"
          :rounded="false"
          height="100%"
          color="primary"
          :loading="taskInitiating"
          :disabled="nextDisabled"
          class="white--text rounded-0 text-subtitle-1 flex-fill"
          @click="stepForward"
        >
          {{ isNew ? $t('Start') : isLastStep ? $t('Done') : $t('Next') }}
        </v-btn>
      </v-sheet>
    </v-sheet>
  </v-container>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ClockInsMixin from 'components/mixins/clock-ins-mixin'

import deviceMixin from 'components/mixins/device-mixin'
import MentionMixin from 'components/mixins/mention-mixin'
import ListingTaskViewMixin from 'components/listing-tasks/mixins/listing-task-view-mixin'
import LtSubtaskList from 'components/listing-tasks/lt-subtask-list.vue'
import Gallery from 'components/common/gallery.vue'
import Expander from 'components/common/expander.vue'
import InitialStep from 'components/listing-tasks/contractor-flow/initial-step.vue'
import MediaStep from 'components/listing-tasks/contractor-flow/media-step.vue'
import ChecklistStep from 'components/listing-tasks/contractor-flow/checklist-step.vue'
import SummaryStep from 'components/listing-tasks/contractor-flow/summary-step.vue'
import { mapGetters } from 'vuex'
import Toaster from '@/utils/toaster'
import LtFeed from 'components/listing-tasks/lt-feed.vue'
import { filter } from 'lodash'
import customAxios from '@/axios/config'
import LtStatus from 'components/listing-tasks/lt-status.vue'

export default {
  name: 'ListingTaskFieldView',
  components: {
    LtFeed,
    SummaryStep,
    ChecklistStep,
    MediaStep,
    InitialStep,
    Expander,
    Gallery,
    LtSubtaskList,
    LtStatus,
  },
  mixins: [
    CommonFunctions,
    deviceMixin,
    PermissionsMixin,
    ClockInsMixin,
    MentionMixin,
    ListingTaskViewMixin,
  ],
  data() {
    return {
      step: 1,
      taskInitiating: false,
      navTab: 'overview',
      commentInput: '',
      fetching: false,
    }
  },
  watch: {},
  props: ['taskId', 'changeToManage'],
  async mounted() {
    const id = this.taskId || this.$route.params.id
    this.fetching = true
    await this.$store.dispatch('getListingTask', {
      id,
      router: this.$router,
      disableLoading: this.taskId,
    })
    if (this.currentListingTask.status === 'Done') {
      this.step = 3
    }
    this.fetching = false
    if (!this.$route.query.tab) {
      this.$router.replace({ query: { ...this.$route.query, tab: 'overview' } })
    }
    if (this.currentlyWorkingClockins.length) {
      this.currentlyWorkingClockins.forEach(item => {
        this.clocking(item.user_details.id, new Date(item.clocked_in))
      })
    }
  },
  beforeDestroy() {
    if (this.currentListingTask.id.toString() !== this.$route.params.id) {
      this.$store.commit('updateCurrentListingTask', null)
    }
  },
  methods: {
    showFeed() {
      this.$router.push({
        query: this.feedOpen ? {} : { comment: 'true' },
      })
    },
    toggleLoading(value) {
      this.taskInitiating = value
    },
    async acceptRejectOffer(accepted) {
      try {
        const payload = {
          id: this.currentListingTask.id,
          accepted: accepted,
        }
        return await customAxios.post(
          `/api/listing-tasks/accept-reject`,
          payload
        )
      } catch (e) {
        console.log(e)
      } finally {
        const id = this.currentListingTask.id
        await this.$store.dispatch('getListingTaskBg', id)
        if (!accepted) {
          this.$router.push({
            name: 'service-calls',
            params: { paramsTab: 'today-tasks' },
          })
        }
      }
    },
    continueTask() {
      this.startClocking(
        this.contractorId,
        this.currentListingTask.id,
        this.$moment().subtract(
          this.cleanerTotalTime(this.contractorId, this.currentListingTask),
          'minutes'
        )
      )
    },
    async stepForward() {
      const { status } = this.currentListingTask
      if (this.step === 1) {
        this.step += 1
        if (!this.isDone(status) && !this.isInProgress) {
          this.taskInitiating = true
          await this.$store.dispatch('clockInUser', {
            id: this.currentListingTask.id,
          })
          await this.updateTask({ status: 'In Progress' })
          this.taskInitiating = false
          this.currentlyWorkingClockins.forEach(item => {
            this.clocking(item.user_details.id, new Date(item.clocked_in))
          })
        } else if (
          this.contractorId === this.currentUserId &&
          !this.currentlyWorking
        ) {
          this.continueTask()
        }
      } else if (this.isLastStep) {
        if (!this.isDone(status)) {
          const currentStep = this.steps[this.step - 1]
          const component = this.$refs[currentStep.component][0]
          await this.updateTask({
            amount: component.amount,
            total_hours: component.totalHours,
            status: 'Done',
          })
          Toaster.show([{ type: 'success', text: 'Task completed' }])
        }
        if (
          this.lastClocking &&
          this.lastClocking.clocked_in &&
          !this.lastClocking.clocked_out
        ) {
          await this.$store.dispatch('clockOutUser', {
            id: this.currentListingTask.id,
          })
        }
        this.$router.push({
          name: 'service-calls',
          params: { paramsTab: 'today-tasks' },
        })
      } else {
        this.step += 1
      }
    },
  },
  computed: {
    ...mapGetters(['currentUserId', 'currentUser']),
    url() {
      return window.location.href
    },
    currentlyWorking() {
      return !this.isEmpty(this.currentlyWorkingClockins)
    },
    currentlyWorkingClockins() {
      return filter(
        this.currentListingTask.working_cleaners,
        clockIn =>
          clockIn.user_id === this.currentUserId && clockIn.duration === null
      )
    },
    contractorId() {
      return this.currentListingTask.assigned_contractor_id
    },
    isNew() {
      return this.currentListingTask && this.currentListingTask.status == 'New'
    },
    needAccept() {
      const currentUserId = this.$store.state.user.id
      return (
        this.requiresAccept &&
        this.noContractorAssigned &&
        [
          this.currentListingTask.assigned_contractor1_id,
          this.currentListingTask.assigned_contractor2_id,
          this.currentListingTask.assigned_contractor3_id,
          this.currentListingTask.assigned_contractor4_id,
        ].includes(currentUserId)
      )
    },
    requiresAccept() {
      return this.currentListingTask && this.currentListingTask.require_accept
    },
    noContractorAssigned() {
      return this.currentListingTask.assigned_contractor_id === null
    },
    steps() {
      if (this.hasSubTasks) {
        return [
          { component: 'InitialStep' },
          { component: 'ChecklistStep', required: true },
          { component: 'SummaryStep' },
        ]
      }
      return [
        { component: 'InitialStep' },
        { component: 'MediaStep' },
        { component: 'SummaryStep' },
      ]
    },
    lastClocking() {
      return this.currentListingTask.working_cleaners.find(
        c =>
          c.user_id === this.currentUserId &&
          c.listing_task_id === this.currentListingTask.id &&
          !c.clocked_out
      )
    },
    isLastStep() {
      return this.step === this.steps.length
    },
    nextDisabled() {
      const currentStep = this.steps[this.step - 1]
      if (!this.$refs[currentStep.component]) {
        return false
      }
      const component = this.$refs[currentStep.component][0]
      if (currentStep.required) {
        return !component.isValid
      }
      return false
    },
    isInProgress() {
      return this.currentListingTask.status === 'In Progress'
    },
  },
}
</script>

<style>
.listing-task-field-container {
  padding-bottom: 60px;
}
.lt-status-label {
  position: relative;
}

.lt-status-label::before {
  content: '';
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 5px;
  left: 0;
  position: absolute;
  background-color: currentColor;
  opacity: 0.12;
}
</style>
