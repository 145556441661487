<template>
  <v-row>
    <v-col cols="12" md="6" class="d-flex flex-column">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1 pa-0 pr-2">
            <dashboard-data-box
              :title="$t('Channels income')"
              color="#4367B5"
              injected-class="green--text"
              :value="dollarFormatter(totalIncome)"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <dashboard-data-box
              :title="$t('Management fees')"
              color="#E69B51"
              :value="dollarFormatter(stats.management_fee)"
            />
            <dashboard-data-box
              :title="$t('Cleaning fees')"
              color="#7ed9d9"
              :value="dollarFormatter(stats.cleaning_income)"
            />
            <dashboard-data-box
              v-if="stats.store_profits"
              :title="$t('Store profits')"
              color="#4367B5"
              :value="dollarFormatter(stats.store_profits)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels v-if="stats.on_payrole_amount" class="mt-3">
        <v-expansion-panel>
          <v-expansion-panel-header class="text-subtitle-1 pa-0 pr-2">
            <dashboard-data-box
              :title="$t('Other income')"
              color="#42A5F5"
              injected-class="green--text"
              :value="dollarFormatter(stats.on_payrole_amount)"
            />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-for="obj in sortedPayrole" :key="obj[0]">
              <dashboard-data-box
                v-if="obj[1]"
                :title="$t(obj[0])"
                color="#42A5F5"
                :value="dollarFormatter(obj[1])"
              />
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-sheet elevation="1" rounded class="d-flex flex-column mt-3">
        <v-expansion-panels class="mt-3">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-subtitle-1 pa-0 pr-2">
              <dashboard-data-box
                :title="$t('Business expenses')"
                color="#8126C4"
                injected-class="red--text"
                :value="dollarFormatter(stats.business_expenses_sum)"
              />
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div v-for="obj in sortedBusinessExpenses" :key="obj[0]">
                <dashboard-data-box
                  v-if="obj[1]"
                  :title="$t(obj[0])"
                  color="#42A5F5"
                  :value="dollarFormatter(obj[1])"
                />
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
      <v-sheet elevation="1" rounded class="d-flex flex-column mt-3">
        <dashboard-data-box
          :title="$t('Net')"
          color="#42A5F5"
          injected-class="green--text"
          :value="dollarFormatter(netProfit)"
        />
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6" class="d-flex flex-column">
      <v-sheet rounded class="pa-4" elevation="1">
        <line-chart
          graph-id="profits"
          :title="$t('Yearly Profits')"
          :stats-object="chartsConfig"
          :config="chartsConfig"
          color="#02BC77"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import DashboardDataBox from 'components/accounting/dashboard-data-box.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import DeviceMixin from 'components/mixins/device-mixin'
import LineChart from 'components/line-chart.vue'

export default {
  name: 'AccountingManagementFeeTab',
  mixins: [FormattersMixin, DeviceMixin],
  components: { LineChart, DashboardDataBox },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    dates: {
      type: Object,
    },
    year: {
      type: Number,
    },
    yearly: {
      type: Boolean,
    },
  },
  computed: {
    months() {
      return MONTHS
    },
    sortedPayrole() {
      let copy = this.stats.on_payrole_sources
      return Object.entries(copy).sort((a, b) => b[1] - a[1])
    },
    sortedBusinessExpenses() {
      if (this.business_expenses_sum_sources) {
        let copy = this.stats.business_expenses_sum_sources
        return copy.sort((a, b) => b[1] - a[1])
      }
      return null
    },
    totalIncome() {
      return (
        this.stats.management_fee +
        this.stats.cleaning_income +
        this.stats.store_profits
      )
    },
    netProfit() {
      return (
        this.totalIncome +
        this.stats.on_payrole_amount -
        this.stats.business_expenses_sum
      )
    },
    chartsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data: this.stats.profit_months.map(v => round(v, 2)),
              borderColor: '#169CA8',
              backgroundColor: '#169CA8',
              fill: false,
              label: this.year,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            labels: {
              usePointStyle: true,
            },
            display: true,
            align: 'start',
            textAlign: 'left',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
}
</script>

<style scoped></style>
