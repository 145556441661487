import { render, staticRenderFns } from "./accounting-management-fee-tab.vue?vue&type=template&id=c433acd6&scoped=true&"
import script from "./accounting-management-fee-tab.vue?vue&type=script&lang=js&"
export * from "./accounting-management-fee-tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c433acd6",
  null
  
)

export default component.exports