<template>
  <div>
    <conversation-drawer />
    <v-row>
      <v-col cols="6" sm="4" md="2">
        <date-picker
          v-model="fromDate"
          label="Created from"
          :hide-details="true"
          :max="parseISODateWithOffset(new Date()).substr(0, 10)"
          :min="
            parseISODateWithOffset(
              new Date(new Date().setMonth(new Date().getMonth() - 20))
            ).substr(0, 10)
          "
          @change="saveFrom"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="statusFilter"
          :items="filterStatuses"
          :label="$t('Status')"
          outlined
          dense
          multiple
          hide-details
          :clearable="true"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <v-select
          v-model="channels"
          :items="channelsItems"
          label="Channel"
          outlined
          dense
          multiple
          hide-details
          :clearable="true"
        />
      </v-col>
      <v-col cols="6" sm="4" md="2">
        <property-picker
          :not-top-header="true"
          :clearable="true"
          :after-change="listingChanged"
        />
      </v-col>
      <v-col cols="6" sm="2" md="1">
        <v-select
          v-model="scoreFrom"
          :items="pointsFrom"
          label="From"
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="6" sm="2" md="1">
        <v-select
          v-model="scoreTo"
          :items="pointsTo"
          label="To"
          outlined
          dense
          hide-details
        />
      </v-col>
      <v-col cols="auto" class="align-baseline">
        <v-radio-group v-model="replied" row class="mt-0 mx-1">
          <v-radio class="mr-1" :value="null">
            <template #label>
              <div :class="radioClass">All</div>
            </template></v-radio
          >
          <v-radio class="mr-1" :value="true">
            <template #label>
              <div :class="radioClass">Replied</div>
            </template></v-radio
          >
          <v-radio class="mr-1" :value="false">
            <template #label>
              <div :class="radioClass">Not Reply</div>
            </template></v-radio
          >
        </v-radio-group>
      </v-col>
    </v-row>
    <v-card elevation="0" :loading="listingLoading">
      <review-reply-popup v-if="currentReview" />
      <reviews-data-table
        :pagination="reviewsPagination"
        :items="items"
        @pagination="pageChanged"
      />
    </v-card>
  </div>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ReviewReplyPopup from './review-reply-popup'
import { mapGetters } from 'vuex'
import PropertyPicker from 'components/property-picker'
import ReviewsDataTable from 'components/reviews/reviews-data-table'
import DatePicker from 'components/form-fields/date-picker.vue'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import { CHANNELS } from 'components/channel-management/config'

export default {
  components: {
    ConversationDrawer,
    DatePicker,
    ReviewsDataTable,
    PropertyPicker,
    ReviewReplyPopup,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['management', 'guestReservations'],
  data() {
    return {
      radioClass: 'text-caption text-capitalize font-weight-medium',
      scoreMatrics: {
        Communication: 'normilized_communication',
        Cleaning: 'normilized_cleaning',
        Accuracy: 'normilized_accuracy',
        Location: 'normilized_location',
        Checkin: 'normilized_checkin',
        Value: 'normilized_value',
      },
      statuses: [
        { text: 'New', color: 'red' },
        { text: 'In Progress', color: 'yellow' },
        { text: 'No Action', color: 'green' },
        { text: 'Replied', color: 'green' },
        { text: 'Removed - Refund', color: 'green' },
        { text: 'Removed - Rules', color: 'green' },
      ],
      filterStatuses: [
        'New',
        'In Progress',
        'No Action',
        'Replied',
        'Removed - Refund',
        'Removed - Rules',
      ],
      channels: [],
      statusFilter: ['New', 'In Progress'],
      page: 1,
      perPage: 10,
      pagination: {
        itemsPerPage: 10,
      },
      pointsFrom: [1, 2, 3, 4, 5],
      scoreFrom: 1,
      scoreTo: 5,
      replied: null,
      fromDate: this.parseISODateWithOffset(
        this.$moment.utc().subtract(14, 'days')
      ).substr(0, 10),
      toDate: new Date(),
      listingId: null,
    }
  },
  watch: {
    channels(val) {
      this.page = 1
      this.channels = val
      this.getReviews()
    },
    statusFilter(val) {
      this.page = 1
      this.statusFilter = val
      this.getReviews()
    },
    scoreFrom(val) {
      this.page = 1
      this.scoreFrom = val
      this.getReviews()
    },
    scoreTo(val) {
      this.page = 1
      this.scoreTo = val
      this.getReviews()
    },
    replied(val) {
      this.page = 1
      this.replied = val
      this.getReviews()
    },
  },
  mounted() {
    this.$store.dispatch('getReviews', this.filters)
  },
  methods: {
    pageChanged({ page, itemsPerPage }) {
      this.page = page
      this.perPage = itemsPerPage
      this.getReviews()
    },
    getReviews() {
      this.$store.dispatch('getReviews', this.filters)
    },
    listingChanged(listingId) {
      this.listingId = listingId
      this.page = 1
      this.getReviews()
    },
    saveTo(to) {
      this.to_date = to
      this.page = 1
      this.getReviews()
    },
    saveFrom(from) {
      this.from_date = from
      this.page = 1
      this.getReviews()
    },
    showReviewModal(review, nonNormalized) {
      this.$store.commit('showModal', {
        name: 'ReviewCard',
        props: {
          review,
          nonNormalized,
        },
        isPersistent: false,
      })
    },
  },
  computed: {
    channelsItems() {
      return [
        { text: 'Survey', value: 'survey' },
        { text: 'Checkup', value: 'checkup' },
      ].concat(CHANNELS)
    },
    filters() {
      return {
        filters: {
          page: this.page,
          per_page: this.perPage,
          status: this.statusFilter,
          from: this.scoreFrom,
          to: this.scoreTo,
          from_date: this.fromDate,
          to_date: this.toDate,
          id: this.$route.query.id,
          replied: this.replied,
          listing_ids: this.listingId,
          channels: this.channels,
        },
      }
    },
    reviewsPagination() {
      return this.$store.state.reviews.reviewsPagination
    },
    items() {
      return this.$store.state.reviews.currentReviews
    },
    ...mapGetters(['currentReview']),
    pointsTo() {
      return this.pointsFrom.filter(n => n > this.scoreFrom).reverse()
    },
  },
}
</script>
