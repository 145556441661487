var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isInvestor),expression:"!isInvestor"}]},[_c('div',{staticClass:"grey lighten-4"},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"pb-0 max-w-1600"},[_c('v-tabs',{attrs:{"slider-color":"white","center-active":"","background-color":"#f6f6f7","active-class":"white","grow":_vm.isMobile},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[(
            _vm.isAdmin ||
            _vm.isOperationsPersonal ||
            _vm.isCommunicationPerson ||
            _vm.isSalesAgent ||
            _vm.isSalesManager
          )?_c('v-tab',{attrs:{"href":"#area-report"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$overall_status")]),_vm._v(" "+_vm._s(_vm.$t('Area Report'))+" ")],1):_vm._e(),_c('v-tab',{attrs:{"href":"#today-tasks"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$tasks")]),_vm._v(" "+_vm._s(_vm.$t('My tasks'))+" ")],1),(!_vm.isCleaner)?_c('v-tab',{attrs:{"href":"#tasks-calendar"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$calendar_check")]),_vm._v(" "+_vm._s(_vm.$t('Calendar'))+" ")],1):_vm._e(),(
            _vm.isAdmin ||
            _vm.isPropertyManager ||
            _vm.isCoordinator ||
            _vm.hasAbility('pm-summary')
          )?_c('v-tab',{attrs:{"href":"#pm-summary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$maintenance")]),_vm._v(" "+_vm._s(_vm.$t('PM Summary'))+" ")],1):_vm._e(),(
            _vm.isDesignedVr &&
            (_vm.isAdmin || _vm.hasAbility(['super-pm', 'ge-summary']))
          )?_c('v-tab',{attrs:{"href":"#ge-summary"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$activity")]),_vm._v(" "+_vm._s(_vm.$t('GE Summary'))+" ")],1):_vm._e(),(!_vm.isCleaner)?_c('v-tab',{attrs:{"href":"#accepted"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$search_task")]),_vm._v(" "+_vm._s(_vm.$t('Search'))+" ")],1):_vm._e(),(
            !_vm.user.on_payrole &&
            (_vm.isContractor || _vm.isCleaningSupervisor || _vm.isCleaningManager)
          )?_c('v-tab',{attrs:{"href":"#payments"},on:{"click":function($event){return _vm.getDetails({
              id: _vm.currentUserId,
              from: _vm.contractorPaymentsDateRange.from,
              to: _vm.contractorPaymentsDateRange.to,
            })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$money")]),_vm._v(" "+_vm._s(_vm.$t('Payments'))+" ")],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.isCoordinator)?_c('v-tab',{attrs:{"href":"#housekeeping"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$hotel_reception")]),_vm._v(" "+_vm._s(_vm.$t('House Keeping'))+" ")],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.isCoordinator)?_c('v-tab',{attrs:{"href":"#listing-info"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$info_circle_alt")]),_vm._v(" "+_vm._s(_vm.$t('Info def'))+" ")],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.isCoordinator)?_c('v-tab',{attrs:{"href":"#projects"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$complete")]),_vm._v(" "+_vm._s(_vm.$t('Projects'))+" ")],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.hasAbility('catalog-manager'))?_c('v-tab',{attrs:{"href":"#catalog"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$files")]),_vm._v(" "+_vm._s(_vm.$t('Catalog'))+" ")],1):_vm._e(),(
            _vm.isAdmin || _vm.isPropertyManager || _vm.hasAbility('auto-rules-manager')
          )?_c('v-tab',{attrs:{"href":"#auto-tasks"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("$rules")]),_vm._v(" "+_vm._s(_vm.$t('Automations'))+" ")],1):_vm._e()],1)],1)],1),_c('div',[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.listingLoading),expression:"listingLoading"}],staticStyle:{"margin":"0"},attrs:{"color":"secondary","absolute":"","indeterminate":true}}),(_vm.paramsTab === 'accepted')?_c('v-tab-item',{staticClass:"main-background",attrs:{"value":"accepted"}},[_c('listing-tasks-table')],1):_vm._e(),(_vm.paramsTab === 'housekeeping')?_c('v-tab-item',{staticClass:"pl-4 pb-2 pr-4 main-background",attrs:{"value":"housekeeping"}},[_c('housekeeping-tab')],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.hasAbility('catalog-manager'))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"catalog"}},[(_vm.paramsTab === 'catalog')?_c('listing-task-catalog-items-table'):_vm._e()],1):_vm._e(),(_vm.paramsTab === 'tasks-calendar')?_c('v-tab-item',{staticClass:"main-background",attrs:{"value":"tasks-calendar"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm11":""}},[_c('tasks-calendar',{attrs:{"initial-view":_vm.isInvestor ? 'listWeek' : 'timeGridDay'}})],1)],1)],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager)?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"projects"}},[(_vm.paramsTab === 'projects')?_c('projects'):_vm._e()],1):_vm._e(),(
          _vm.isAdmin ||
          _vm.isPropertyManager ||
          _vm.isCoordinator ||
          _vm.hasAbility('pm-summary')
        )?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"pm-summary","eager":""}},[(_vm.paramsTab === 'pm-summary')?_c('daily-summary'):_vm._e()],1):_vm._e(),(_vm.paramsTab === 'area-report')?_c('v-tab-item',{staticClass:"main-background",attrs:{"value":"area-report","eager":""}},[_c('router-view')],1):_vm._e(),(_vm.isAdmin || _vm.hasAbility(['super-pm', 'ge-summary']))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"ge-summary","eager":""}},[(_vm.paramsTab === 'ge-summary')?_c('daily-summary'):_vm._e()],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager)?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"schedule-helper"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('LtDailyList')],1)],1)],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager || _vm.isGarbageUser)?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"garbage"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[(_vm.paramsTab === 'garbage')?_c('lt-checklist',{attrs:{"filter-func":_vm.isContractor ? _vm.filterGarbage : null,"list-type":[
                'garbage patrol',
                'waste management',
                'day patrol',
              ]}}):_vm._e()],1)],1)],1):_vm._e(),(_vm.isAdmin || _vm.isPropertyManager)?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"listing-info"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[_c('listing-info-definitions-table',{staticClass:"mt-4"})],1)],1)],1):_vm._e(),(_vm.paramsTab === 'today-tasks')?_c('v-tab-item',{attrs:{"value":"today-tasks"}},[_c('todays-tasks-tab')],1):_vm._e(),(
          _vm.isAdmin ||
          _vm.isPropertyManager ||
          _vm.isSecurityUser ||
          _vm.isCommunicationManager ||
          _vm.isCommunicationAgent
        )?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"security"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm10":""}},[(_vm.paramsTab === 'security')?_c('lt-checklist',{attrs:{"list-type":"security patrol"}}):_vm._e()],1)],1)],1):_vm._e(),_c('v-tab-item',{staticClass:"px-4",attrs:{"value":"payments"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('contractor-listing-task-details')],1)],1)],1),(_vm.isAdmin || _vm.isPropertyManager)?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"tasks-stats"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"sm10":""}},[_c('listing-tasks-stats')],1)],1)],1):_vm._e(),(
          _vm.isAdmin || _vm.isPropertyManager || _vm.hasAbility('auto-rules-manager')
        )?_c('v-tab-item',{staticClass:"pl-2 pr-2",attrs:{"value":"auto-tasks"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{staticClass:"pb-2",attrs:{"sm10":""}},[(!_vm.loading && false)?_c('v-switch',{attrs:{"mt-2":"","mb-2":"","label":_vm.$t('Show rules per house')},model:{value:(_vm.showRuleSummary),callback:function ($$v) {_vm.showRuleSummary=$$v},expression:"showRuleSummary"}}):_vm._e(),_c('auto-listing-task-rules-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRuleSummary),expression:"!showRuleSummary"}],attrs:{"general-mode":true}}),_c('auto-listing-task-rules-warnings',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRuleSummary),expression:"showRuleSummary"}]})],1)],1)],1):_vm._e(),(_vm.isAdmin || _vm.hasAbility('super-pm'))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"bi"}},[_c('iframe',{attrs:{"title":"Pms","width":"100%","height":"1500","src":"https://app.powerbi.com/reportEmbed?reportId=9d57c9d3-2b53-4227-9a46-1962259b41ec&appId=49f02176-238c-41a1-847c-97192c95335b&autoAuth=true&ctid=cfaa53b4-8414-410c-ad73-470f4cecfeab&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9","frameborder":"0","allowFullScreen":"true"}})]):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }