<template>
  <tr :class="{ 'super-light-red': item.pause }">
    <td class="py-3">
      <p class="d-flex mb-4 align-center">
        <listing-setup-label
          v-if="item.extra_data.investor_name"
          :name="item.extra_data.investor_name"
          color="purple"
          role="Investor"
        />
        <listing-setup-label
          v-if="item.listing.personal.project_manager"
          class="ml-2"
          :name="nameById(item.listing.personal.project_manager)"
          color="info"
          role="PM"
        />
        <listing-setup-label
          v-if="item.listing.personal.designer"
          class="ml-2"
          color="pink"
          :name="nameById(item.listing.personal.designer)"
          role="Designer"
        />
        <listing-setup-label
          v-if="item.listing.personal.customer_success"
          class="ml-2"
          color="dark-yellow"
          :name="nameById(item.listing.personal.customer_success)"
          role="CSM"
        />
        <listing-setup-label
          v-for="flow in item.extra_data.active_flows_names"
          :key="`label-${flow}`"
          class="ml-2"
          icon="account_tree"
          color="green"
          :role="flow"
        />
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-chip
              v-if="item.priority"
              v-bind="attrs"
              class="d-md-none ml-2"
              :color="`${priorityColor(item.priority)} lighten-4`"
              label
              small
              v-on="on"
            >
              <span
                :class="[
                  `${priorityColor(item.priority)}--text`,
                  'text-capitalize',
                ]"
                >{{ item.priority }}</span
              >
            </v-chip>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(priority, index) in priorities"
              :key="index"
              @click=";[(item.priority = priority), updateListingSetup(item)]"
            >
              <v-list-item-title class="text-capitalize">
                <v-icon class="mr-2" :color="priorityColor(priority)" x-small
                  >mdi-circle
                </v-icon>
                <span class="text-capitalize">
                  {{ priority }}
                </span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </p>
      <div class="d-flex align-center mb-2">
        <v-tooltip top>
          <template #activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              :color="isSetupDone ? 'green lighten-4' : 'orange lighten-4'"
              class="mr-2 pa-1"
              small
              v-on="on"
            >
              <v-icon :color="isSetupDone ? 'green' : 'orange'" small>
                {{ isSetupDone ? 'mdi-check' : 'mdi-progress-clock' }}
              </v-icon>
            </v-chip>
          </template>
          <span>Status: {{ item.status }}</span>
        </v-tooltip>
        <router-link
          target="_blank"
          :to="`/dashboard/listing-setup/${item.id}`"
          class="font-weight-bold text-decoration-none black--text"
        >
          {{ item.listing_address }}
        </router-link>
        <v-btn-toggle
          v-model="currentFeed"
          group
          color="primary"
          class="mx-2"
          dense
        >
          <v-btn :value="item.id" text @click="showFeed">
            <v-badge
              v-if="unseenCommentsAmount !== 0"
              :value="unseenCommentsAmount !== 0"
              color="error"
              bordered
              overlap
              :content="unseenCommentsAmount"
            >
              <v-icon color="inherit">comment</v-icon>
            </v-badge>
            <v-badge
              v-else
              :value="commentsAmount !== 0"
              color="grey"
              bordered
              overlap
              small
              :content="commentsAmount"
            >
              <v-icon color="inherit">comment</v-icon>
            </v-badge>
          </v-btn>
        </v-btn-toggle>
        <ConfirmationModal
          :text="`Are you sure you want to  <b>${
            item.pause ? 'remove pause' : 'pause project'
          }</b> ?`"
          @action="togglePause"
        >
          <template #activator="slotData">
            <v-icon
              class="mr-2"
              :class="item.pause ? 'red--text' : 'green--text'"
              v-on="slotData.on"
              >{{ item.pause ? 'pause_circle_filled' : 'play_circle_outline' }}
            </v-icon>
          </template>
        </ConfirmationModal>
      </div>
      <p class="d-md-none text-caption mb-0">Service calls</p>
      <listing-setup-stats
        v-if="item.extra_data.stats"
        :item="item.extra_data.stats.listing_tasks"
        :color="progressColor(listingTaskStatsVal)"
        :value="listingTaskStatsVal"
        no-stats-text="No service calls"
        class="d-md-none mb-2"
      />
      <v-chip
        color="transparent"
        class="text-caption secondary--text darken-1 pointer"
        @click="$emit('open-units-edit-dialog', item.id)"
      >
        <v-icon color="secondary darken-1" small>$bed_doubled</v-icon>
        <span class="ml-1">{{ item.listing_info.beds }}</span>
        <v-icon color="secondary darken-1" class="ml-4" small>$shower</v-icon>
        <span class="ml-1">{{ item.listing_info.baths }}</span>
        <v-icon
          v-show="item.listing_info.has_pool"
          color="secondary darken-1"
          class="ml-4"
          small
          >mdi-swim
        </v-icon>
        <v-icon
          v-show="item.listing_info.has_garden"
          color="secondary darken-1"
          class="ml-4"
          small
          >mdi-tree
        </v-icon>
        <v-icon
          v-show="item.listing_info.has_jacuzzi"
          color="secondary darken-1"
          class="ml-4"
          small
          >mdi-chart-bubble
        </v-icon>
      </v-chip>
    </td>
    <td class="d-none d-md-table-cell">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <v-chip v-if="item.priority" v-bind="attrs" small v-on="on">
            <v-icon class="mr-2" :color="priorityColor(item.priority)" x-small
              >mdi-circle
            </v-icon>
            <span class="text-capitalize">
              {{ item.priority }}
            </span>
          </v-chip>
          <v-chip v-else v-bind="attrs" class="text-lowercase" small v-on="on">
            <span class="text-caption"> set priority </span>
          </v-chip>
        </template>
        <v-list dense>
          <v-list-item
            v-for="(priority, index) in priorities"
            :key="index"
            @click=";[(item.priority = priority), updateListingSetup(item)]"
          >
            <v-list-item-title class="text-capitalize">
              <v-icon class="mr-2" :color="priorityColor(priority)" x-small
                >mdi-circle
              </v-icon>
              <span class="text-capitalize">
                {{ priority }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </td>
    <td style="width: 120px">
      <v-menu
        v-model="launchDateMenu"
        transition="scale-transition"
        offset-y
        required
        min-width="290px"
      >
        <template #activator="{ on }">
          <v-chip small v-on="on">
            {{ item.launch_date || 'set the date' }}
          </v-chip>
        </template>
        <v-date-picker
          v-model="item.launch_date"
          min="2017-01-01"
          @change="updateListingSetup(item)"
        />
      </v-menu>
    </td>
    <td style="width: 160px">
      <div
        v-for="scope in orderedScopes"
        :key="scope.value"
        class="d-flex align-center mb-2 pt-1 min-w-200"
      >
        <button
          class="d-flex text-capitalize"
          @click=";[(scope.is_done = !scope.is_done), updateListingSetup(item)]"
        >
          <v-icon
            v-if="!scope.is_done"
            color="green lighten-3"
            class="mr-1"
            small
          >
            mdi-circle-outline
          </v-icon>
          <v-icon v-else color="grey lighten-1" class="mr-1" small
            >mdi-check-circle-outline
          </v-icon>
          <del
            v-if="scope.is_done"
            :class="[{ bolder: isActiveScope(scope) }, 'text-left']"
            class="grey--text lighten-1"
            >{{ scope.name }}
          </del>
          <span
            v-else
            :class="[{ bolder: isActiveScope(scope) }, 'text-left']"
            >{{ scope.name }}</span
          >
          <v-icon v-if="isBehind(scope)" x-small class="red--text"
            >warning
          </v-icon>
        </button>
      </div>
      <listing-setup-scope-modal
        v-if="item"
        :scope="item.scope"
        @on-update="updateSetup($event, 'scope', item)"
      />
    </td>
    <td style="width: 100px" class="pb-4">
      <div
        v-for="(scope, index) in item.scope"
        :key="`start-menu-${index}`"
        class="d-flex align-center"
      >
        <div v-if="!scope.is_done">
          <v-menu
            v-model="startDateMenus[index]"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-chip class="mb-2" small v-on="on"
                >{{ scope.start_date }}
              </v-chip>
            </template>
            <v-date-picker
              v-model="scope.start_date"
              min="2017-01-01"
              @change="updateSetup($event, `scope.${scope}.start_date`, item)"
            />
          </v-menu>
        </div>
        <del v-else class="text-caption grey--text lighten-1 mb-2 pt-1"
          >{{ scope.start_date }}
        </del>
      </div>
    </td>
    <td style="width: 100px" class="pb-4">
      <div
        v-for="(scope, index) in item.scope"
        :key="`end-menu-${index}`"
        class="d-flex align-center"
      >
        <div v-if="!scope.is_done">
          <v-menu
            v-model="endDateMenus[index]"
            transition="scale-transition"
            offset-y
            required
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-chip class="mb-2" small v-on="on">
                <span v-if="scope.end_date">
                  {{ scope.end_date }}
                </span>
                <span v-else>set end date</span>
              </v-chip>
            </template>
            <v-date-picker
              v-model="scope.end_date"
              @change="updateSetup($event, `scope.${scope}.end_date`, item)"
            />
          </v-menu>
        </div>
        <del v-else class="text-caption grey--text lighten-1 mb-2 pt-1"
          >{{ scope.end_date }}
        </del>
      </div>
    </td>
    <td class="d-none d-md-table-cell">
      <listing-setup-stats
        v-if="item.extra_data.stats"
        :item="item.extra_data.stats.listing_tasks"
        :color="progressColor(listingTaskStatsVal)"
        :value="listingTaskStatsVal"
        no-stats-text="No service calls"
      />
    </td>
    <td style="width: 40px" class="px-1">
      <ConfirmationModal
        v-if="isAdmin || isOperationsPersonal"
        :text="`Are you sure you want to set status as ${
          isSetupDone ? 'Not done' : 'Done'
        } ?`"
        @action="toggleStatus"
      >
        <template #activator="slotData">
          <v-btn icon v-on="slotData.on">
            <v-icon small>
              {{ isSetupDone ? 'mdi-progress-clock' : 'mdi-check' }}
            </v-icon>
          </v-btn>
        </template>
      </ConfirmationModal>
      <v-btn v-if="isAdmin || isOperationsPersonal" icon>
        <v-icon small @click="updateSetup(false, 'active', item)">
          $trash
        </v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import MentionMixin from 'components/mixins/mention-mixin'
import ListingSetupLabel from 'components/listing-setup-label'
import ColorsMixin from 'components/mixins/colors-mixin'

import ListingSetupStats from 'components/listing-setup-stats'
import ListingSetupScopeModal from 'components/listing-setup-scope-modal'
import toNumber from 'lodash/fp/toNumber'
import orderBy from 'lodash/fp/orderBy'
import ConfirmationModal from 'components/modals/confirmation-modal'

export default {
  components: {
    ConfirmationModal,
    ListingSetupScopeModal,
    ListingSetupLabel,
    ListingSetupStats,
  },
  mixins: [CommonFunctions, PermissionsMixin, MentionMixin, ColorsMixin],
  props: ['item', 'afterSave'],
  data: function () {
    return {
      currentFeed: null,
      startDateMenus: {},
      endDateMenus: {},
      launchDateMenu: false,
      priorities: ['low', 'med', 'high'],
    }
  },

  methods: {
    ...mapActions(['updateListingSetup']),
    togglePause() {
      this.updateSetup(!this.item.pause, 'pause', this.item)
    },
    toggleMananged() {
      this.updateSetup(!this.item.mananged_by_dvr, 'mananged_by_dvr', this.item)
    },
    toggleStatus() {
      this.updateSetup(
        this.isSetupDone ? 'Not done' : 'Done',
        'status',
        this.item
      )
    },
    isBehind(scope) {
      const end = this.$moment.utc(scope.end_date, 'YYYY-MM-DD  HH:mm:ss')
      const now = this.$moment.utc()
      return end.isBefore(now) && !scope.is_done
    },
    updateSetup(newVal, key, listingSetup) {
      if (key === 'active') {
        const userConfirm = confirm('Are you sure you want to delete?')
        if (!userConfirm) {
          return
        }
      }
      listingSetup[key] = newVal
      this.updateListingSetup(listingSetup)
      if (key === 'active') {
        setTimeout(() => {
          this.afterSave()
        }, 400)
      }
    },
    showFeed() {
      this.$router.push({
        query:
          this.feedOpenId && this.feedOpenId === this.item.id
            ? {}
            : { feed_id: this.item.id },
      })
    },
  },
  watch: {
    feedOpenId(val) {
      if (val) {
        this.currentFeed = val
      } else {
        this.currentFeed = null
      }
    },
  },
  mounted() {
    if (this.feedOpenId) {
      this.currentFeed = this.feedOpenId
    }
  },
  computed: {
    ...mapState(['listingSetups']),
    ...mapGetters(['currentUserId']),
    commentsList() {
      return orderBy(
        ['created_at'],
        ['desc'],
        this.item.listing_setup_comments.filter(c => !c.parent_id)
      )
    },
    feedOpenId() {
      return toNumber(this.$route.query.feed_id)
    },
    listingTaskStatsVal() {
      const stats = this.item.extra_data.stats
      if (stats && stats.listing_tasks.all != 0) {
        return (stats.listing_tasks.status_done / stats.listing_tasks.all) * 100
      }
      return null
    },
    orderedScopes() {
      const scope = this.item.scope
      if (scope && scope.length) {
        return scope.sort((a, b) => {
          return new Date(a.start_date) - new Date(b.start_date)
        })
      } else {
        return []
      }
    },
    isSetupDone() {
      return this.item.status === 'Done'
    },
  },
}
</script>
