<template>
  <div>
    <v-tabs
      v-model="paramsTab"
      icons-and-text
      class="tabs-menu main-background"
    >
      <v-tab href="#channel-management">
        {{ $t('Listings') }}
        <v-icon>mdi-home-assistant</v-icon>
      </v-tab>
      <v-tab href="#channels">
        {{ $t('Channels') }}
        <v-icon>$promotion</v-icon>
      </v-tab>
      <v-tab href="#taxes">
        {{ $t('Tax Profiles') }}
        <v-icon>$calculator</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="paramsTab">
      <v-tab-item value="channels">
        <channel-management v-if="paramsTab === 'channels'" />
      </v-tab-item>
      <v-tab-item value="taxes">
        <tax-profile-table />
      </v-tab-item>
      <v-tab-item value="channel-management">
        <channel-management-table />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PermissionsMixin from 'components/mixins/permissions-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'
import { CHANNELS } from 'components/channel-management/config'
import Common_functions from 'components/mixins/common_functions'
import ChannelManagement from 'components/channel-management/channel-management.vue'
import TaxProfileTable from 'components/tax-profiles/tax-profile-table.vue'
import ChannelManagementTable from 'components/channel-settings/channel-management-table.vue'

export default {
  components: {
    ChannelManagementTable,
    TaxProfileTable,
    ChannelManagement,
  },
  mixins: [PermissionsMixin, formattersMixin, Common_functions],
  methods: {},

  computed: {
    channelsItems() {
      return CHANNELS
    },
  },
  data() {
    return {}
  },
  async mounted() {},
}
</script>
