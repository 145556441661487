<template>
  <v-sheet width="100%" class="mt-4">
    <v-card class="pa-2">
      <div>
        <filters-select
          :value.sync="value.selectedReports"
          class="mt-4 mb-8"
          label="Report"
          outlined
          dense
          clearable
          hide-details
          multiple
          :selection-func="item => item.text"
          :items="reportItems"
          small-chips
          @change="onReportChange"
        />
        <v-select
          v-model="value.approvedModules"
          class="my-2"
          dense
          outlined
          hide-details
          multiple
          small-chips
          label="Modules"
          :items="items"
          @change="onApproveModulesChange"
        />
      </div>
    </v-card>
  </v-sheet>
</template>

<script>
import FiltersSelect from 'components/form-fields/filters-select.vue'

export default {
  components: { FiltersSelect },
  props: ['value'],
  data() {
    return {
      items: [
        'ops',
        'ai',
        'accounting',
        'comm',
        'bi',
        'guest-exp',
        'channel-manager',
        'projects',
        'store',
        'hotels',
        'website',
        'pricing',
      ],
    }
  },
  computed: {
    invoiceDateDisplay() {
      return this.$moment({
        year: this.invoiceYear,
        month: this.invoiceMonth - 1,
      }).format('MMM YYYY')
    },
    reportItems() {
      return this.value.reportItems.map(r => ({
        text: r.title,
        value: r.key,
      }))
    },
  },
  methods: {
    onApproveModulesChange(val) {
      this.$emit('update:approved-modules', val)
    },
    onReportChange(val) {
      this.$emit('update:selected-reports', val)
    },
  },
}
</script>

<style></style>
