var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"headers":_vm.headers,"expanded":_vm.expanded,"show-expand":"","item-key":"id","items":_vm.items},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.type",fn:function({ item }){return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.type)+" ")])]}},{key:"item.amount",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.dollarFormatter(item.amount))+" ")])]}},{key:"item.paid_at",fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(item.paid_at)+" ")])]}},{key:"item.status",fn:function({ item }){return [_c('div',{class:[
        'text-capitalize',
        'text-caption font-weight-medium',
        'mr-2',
        'line-spacing',
        item.status === 'succeeded' || item.status === 'requires_capture'
          ? 'green--text'
          : 'red--text',
      ]},[_vm._v(" "+_vm._s(_vm.formatStatus(item.status))+" ")])]}},{key:"item.actions",fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end align-center grid-gap-10"},[(
          (_vm.isAdmin || _vm.hasAbility('refund')) &&
          item.type === 'accommodation' &&
          item.status === 'succeeded' &&
          item.isRefundable
        )?_c('refund-dialog',{attrs:{"type":"refund","intent-id":item.payment_intent_id,"total":item.amount},on:{"success":_vm.getReservation}}):_vm._e(),(
          item.type === 'accommodation' &&
          item.status === 'succeeded' &&
          !item.isRefundable
        )?_c('span',{staticClass:"text-caption green--text"},[_vm._v(" Fully refunded ")]):_vm._e(),_c('CopyClipboard',{attrs:{"description":"Copy payment link","text":`${_vm.portalDomain}/checkout/${item.payment_intent_id}`}},[_vm._v("Copy Link")]),_c('a',{staticClass:"text-decoration-noneå",attrs:{"target":_vm.isMobile ? '_self' : '_blank',"href":`${_vm.portalDomain}/checkout/${item.payment_intent_id}`}},[_c('v-btn',{attrs:{"x-small":"","color":"green lighten-3","depressed":""}},[_vm._v("Payment link")])],1),(
          (_vm.isAdmin || _vm.hasAbility('refund') || _vm.isCommunicationManager) &&
          item.type === 'deposit' &&
          item.status === 'requires_capture'
        )?_c('refund-dialog',{attrs:{"type":"capture","intent-id":item.payment_intent_id,"total":item.amount}}):_vm._e(),(
          ((_vm.isCommunicationAgent || _vm.managementRoles) &&
            item.type === 'accommodation' &&
            item.status === 'requires_source') ||
          item.status === 'requires_payment_method'
        )?_c('charge-split-modal',{attrs:{"balance":item.amount,"intent":item,"on-change":_vm.getReservation}}):_vm._e(),(
          ((_vm.isCommunicationAgent || _vm.managementRoles) &&
            item.type === 'accommodation' &&
            item.status === 'requires_source') ||
          item.status === 'requires_payment_method'
        )?_c('charge-modal',{attrs:{"can-charge-third":_vm.isHotelReception || _vm.isAdmin,"guest-email":_vm.reservation.guest.emails[0],"balance":item.amount,"intent":item},on:{"charge-success":_vm.getReservation,"payment-canceled":function($event){return _vm.getReservation({ refresh: true })}}}):_vm._e(),(item.type === 'deposit' && item.status === 'succeeded')?_c('span',{staticClass:"text-caption green--text"},[_vm._v(" Captured("+_vm._s(_vm.dollarFormatter(item.amount_received))+") ")]):_vm._e()],1)]}},{key:"expanded-item",fn:function({ item }){return [_c('td'),_c('td',_vm._l((item.charges),function(charge){return _c('div',{key:charge.id,staticClass:"py-1"},[(charge.reason)?_c('span',[_vm._v(" Refund "),_c('info-tooltip',[_vm._v(_vm._s(charge.reason))])],1):_c('span',[_vm._v("Charged")]),(
            charge.payment_method_details &&
            charge.payment_method_details.card
          )?_c('span',[_vm._v(" (xxxx-"+_vm._s(charge.payment_method_details.card.last4)+") ")]):_vm._e()])}),0),_c('td',_vm._l((item.charges),function(charge){return _c('div',{key:charge.id,staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.dollarFormatter(charge.amount / 100))+" ")])}),0),_c('td',_vm._l((item.charges),function(charge){return _c('div',{key:charge.id,staticClass:"py-1",class:charge.status === 'succeeded' ? 'green--text' : 'red--text'},[_vm._v(" "+_vm._s(charge.status)+" ")])}),0),_c('td',_vm._l((item.charges),function(charge){return _c('div',{key:charge.id,staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.parseTimestamp(charge.created, 'ddd, MMM Do HH:mm', { local: true, }))+" ")])}),0)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }