<template>
  <v-sheet>
    <v-form ref="updateListing" lazy-validation @submit.prevent="updateListing">
      <div class="d-flex justify-space-between align-center my-3">
        <div class="text-h6">{{ $t('Finance information') }}</div>
        <v-btn small color="primary" type="submit">Save </v-btn>
      </div>
      <v-row>
        <v-col cols="12" sm="4">
          <v-select
            v-model="listing.prices_info.security_deposit_type"
            value="5"
            label="Deposit Type"
            :items="depositTypesItems"
            outlined
            dense
            :rules="[required]"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model.number="listing.prices_info.securityDepositFee"
            label="Deposit amount"
            type="number"
            :min="1"
            :rules="[required]"
            outlined
            dense
          >
            <template #prepend-inner
              ><div class="currency-sign">{{ currencySign }}</div></template
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model.number="listing.actual_cleaning"
            label="Cleaning Fee"
            type="number"
            :min="1"
            :rules="[required]"
            outlined
            dense
          >
            <template #prepend-inner
              ><div class="currency-sign">{{ currencySign }}</div></template
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-text-field
            v-model.number="listing.prices_info.extra_person_fee"
            label="Extra person fee"
            type="number"
            :min="1"
            outlined
            dense
          >
            <template #prepend-inner
              ><div class="currency-sign">
                {{ currencySign }}
              </div></template
            >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-select
            v-model="defaultTaxProfile"
            label="Tax Profile"
            :items="taxProfiles"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="listing.business_tax_id"
            label="Airbnb Business Tax ID (Optional)"
            outlined
            dense
          />
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field
            v-model="listing.tot_registration_id"
            label="Airbnb TOT Registration ID (Optional)"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <div class="text-h6">{{ $t('Cancellation Policy') }}</div>
      <v-card-text class="px-6">
        <div
          v-for="(policy, ind) in cancellationPolicyBreakdown"
          :key="ind"
          class="pa-2"
        >
          <v-row class="align-center">
            <v-col cols="3">
              <v-text-field
                v-model="policy.from"
                type="number"
                outlined
                dense
                label="Days From"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="policy.to"
                type="number"
                outlined
                dense
                label="Days to"
                :rules="[required]"
              />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="policy.amount"
                type="number"
                outlined
                dense
                label="Charge (%)"
                required
              />
            </v-col>
            <v-col cols="1">
              <v-icon
                class="ml-auto"
                small
                color="error"
                @click="removePolicy(ind)"
                >$trash</v-icon
              >
            </v-col>
          </v-row>
          <v-divider />
        </div>
        <v-btn outlined class="my-3" color="info" @click="addPolicy"
          ><v-icon left>fas fa-plus</v-icon> Add Policy
        </v-btn>
      </v-card-text>
    </v-form>
  </v-sheet>
</template>

<script>
import axios from 'axios'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapGetters } from 'vuex'

export default {
  name: 'ListingChannelFinance',
  mixins: [FormRules, FormattersMixin],
  props: ['listing'],
  data() {
    return {
      taxProfiles: [],
      defaultTaxProfile: this.listing.prices_info.tax_profile_id,
      cancellationPolicyBreakdown:
        this.listing.extra_info['cancellation'] || [],
    }
  },
  async mounted() {
    this.fetchData()
  },
  computed: {
    ...mapGetters(['depositTypes']),
    depositTypesItems() {
      return Object.entries(this.depositTypes || []).map(([value, text]) => ({
        text,
        value,
      }))
    },
  },
  methods: {
    async updateListing() {
      if (this.$refs.updateListing.validate()) {
        this.$store.commit('updateLoading', true)
        await this.$store
          .dispatch('listings/updateListing', {
            id: this.$route.params.id,
            payload: {
              actual_cleaning: this.listing.actual_cleaning,
              tot_registration_id: this.listing.tot_registration_id,
              business_tax_id: this.listing.business_tax_id,
              prices_info: {
                extra_person_fee: this.listing.prices_info.extra_person_fee,
                tax_profile_id: this.defaultTaxProfile,
                securityDepositFee: this.listing.prices_info.securityDepositFee,
                security_deposit_type:
                  this.listing.prices_info.security_deposit_type,
              },
              extra_info: {
                cancellation: this.cancellationPolicyBreakdown,
              },
            },
          })
          .finally(this.$store.commit('updateLoading', false))
      }
    },
    async fetchTaxProfiles() {
      const { data } = await axios.get('/api/tax-profiles/stats')
      this.taxProfiles = data.tax_profiles.map(t => ({
        text: t.name,
        value: t.id,
      }))
      if (!this.defaultTaxProfile && this.taxProfiles.length) {
        this.defaultTaxProfile = this.taxProfiles[0].value
      }
    },
    fetchData() {
      this.loadingData = true
      const tp = this.fetchTaxProfiles()
      Promise.all([tp]).finally(() => {
        this.loadingData = false
      })
    },
    addPolicy() {
      this.cancellationPolicyBreakdown.push({
        from: '',
        to: '',
        amount: '',
      })
    },
    removePolicy(idx) {
      this.cancellationPolicyBreakdown.splice(idx, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.currency-sign {
  font-size: 24px;
  padding-right: 4px;
}
</style>
