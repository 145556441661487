<template>
  <div>
    <form-dialog title="Split Charge" class="p-relative" @submit="submit">
      <template #activator="{ on }">
        <v-btn
          x-small
          color="orange lighten-3"
          depressed
          :loading="updating"
          v-on="on"
          >Split</v-btn
        >
      </template>
      <v-text-field
        v-model.number="amount"
        type="number"
        :rules="[required]"
        outlined
        disabled
        label="Full amount"
        dense
      />
      <v-slider
        v-model="split"
        label="Split %"
        dense
        :thumb-size="26"
        thumb-label="always"
        hide-details
        class="pt-2"
      >
        <template #append>
          <v-text-field
            v-model="split"
            dense
            style="width: 80px"
            type="number"
            outlined
          ></v-text-field>
        </template>
      </v-slider>
      <v-text-field
        v-model.number="toCharge"
        type="number"
        outlined
        disabled
        label="Amount to Charge"
        dense
      />
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import Toaster from '@/utils/toaster'
import FormattersMixin from 'components/mixins/formatters-mixin'

export default {
  name: 'ChargeModal',
  components: { FormDialog },
  mixins: [FormRules, FormattersMixin],
  props: ['balance', 'intent', 'onChange'],
  data() {
    return {
      amount: this.balance,
      dialog: false,
      updating: false,
      split: 0,
    }
  },
  watch: {
    guestEmail(value) {
      this.email = value
    },
    balance(value) {
      this.amount = value
    },
  },
  computed: {
    toCharge() {
      return Math.round(this.amount * (this.split / 100), 2)
    },
  },
  methods: {
    async submit() {
      this.updating = true
      try {
        await this.$store.dispatch('stripe/createManualCharge', {
          amount: this.toCharge,
          intent_id: this.intent.payment_intent_id,
        })
        this.dialog = true
        this.onChange && this.onChange()
      } catch (e) {
        console.error(e)
      }
      this.updating = false
    },
  },
}
</script>

<style scoped></style>
