<template>
  <form-dialog
    title="Reservation Edit"
    :disable-submit="!reservation.listingId"
    @submit="submit"
    @open="getRates"
  >
    <template #activator="{ on }">
      <v-btn
        depressed
        icon
        :class="showBtnBorder ? 'btn-border' : ''"
        v-on="on"
      >
        <v-icon :color="color || 'info'" small>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-row :no-gutters="!isInvestor">
      <v-col v-if="!isInvestor" cols="12">
        <v-autocomplete
          v-model="reservation.listingId"
          label="Listing"
          :items="listingsItems"
          dense
          hide-details
          item-text="nickname"
          item-value="id"
          outlined
          @change="getRates"
        />
        <div v-if="!isEditable" class="text-caption warning--text py-4">
          {{
            `* Moving this reservation to another listing won't release those dates
          in ${source}`
          }}
        </div>
      </v-col>
      <v-col cols="12">
        <confirmation-modal
          ref="confirmDateChangeModal"
          :text="
            $t('The selected dates are blocked. Please confirm this action.')
          "
          title="Date change confirmation"
          :actions="[
            {
              text: 'Cancel',
              color: 'error',
              onClick: setResDateRange,
            },
            {
              text: 'Confirm',
              color: 'success',
              onClick: () => {},
            },
          ]"
        />
        <date-picker
          v-model="reservation.range"
          :range="true"
          class="mt-3"
          :hide-details="true"
          :allowed-dates="allowedDates"
          :event-color="eventColors"
          :disabled="nonEditableChannel"
          label="Dates"
        />
        <div v-if="!isInvestor" class="d-flex justify-space-between mt-2">
          <span class="text-subtitle-2">Origin accommodation fare:</span>
          <span class="text-caption">{{
            dollarFormatter(originAccommodation)
          }}</span>
        </div>
        <div v-if="!isInvestor" class="d-flex justify-space-between">
          <span class="text-subtitle-2">New accommodation fare:</span>
          <span class="text-caption">{{
            dollarFormatter(totalAccommodation)
          }}</span>
        </div>
        <div v-if="!isInvestor">
          <v-checkbox
            v-model="preservePrice"
            :disabled="nonEditableChannel"
            dense
            class="mt-0"
            label="Preserve price"
          />
          <v-text-field
            v-if="!preservePrice"
            v-model.number="reservation.fareAccommodation"
            label="Accommodation Fare"
            dense
            outlined
          />
        </div>
        <div v-if="!isInvestor" class="text-caption warning--text pb-3 pt-1">
          * Taxes are calculated automatically
        </div>
      </v-col>
      <v-col cols="12">
        <v-select
          v-model="reservation.status"
          outlined
          dense
          :disabled="nonEditableChannel"
          :items="['confirmed', 'inquiry', 'canceled']"
          :label="$t('Status')"
        />
      </v-col>
      <!--      <v-col v-if="!isEditable" cols="12">-->
      <!--        <div class="warning&#45;&#45;text">-->
      <!--          ** By saving your changes, this reservation will no longer receive-->
      <!--          updates from the PMS.-->
      <!--        </div>-->
      <!--      </v-col>-->
    </v-row>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import DatePicker from 'components/form-fields/date-picker'
import CommonFunctions from 'components/mixins/common_functions'
import { mapGetters } from 'vuex'
import equal from 'lodash/fp/isEqual'
import isEmpty from 'lodash/fp/isEmpty'
import FormattersMixin from 'components/mixins/formatters-mixin'
import ConfirmationModal from 'components/modals/confirmation-modal'
import PermissionsMixin from 'components/mixins/permissions-mixin'

export default {
  name: 'ReservationEditModal',
  components: { DatePicker, FormDialog, ConfirmationModal },
  mixins: [CommonFunctions, FormattersMixin, PermissionsMixin],
  props: [
    'checkIn',
    'checkOut',
    'isEditable',
    'listing',
    'reservationId',
    'color',
    'source',
    'status',
    'fareAccommodation',
    'cleaningFee',
    'showBtnBorder',
  ],
  data() {
    return {
      preservePrice: true,
      currentRates: {},
      reservation: {
        range: null,
        listingId: this.listing.id,
        status: this.status,
        fareAccommodation: this.fareAccommodation,
      },
    }
  },
  async mounted() {
    this.currentRates = await this.$store.dispatch(
      'listings/getListingRates',
      this.reservation.listingId
    )
    this.setResDateRange()
  },
  watch: {
    totalAccommodation(val) {
      this.reservation.fareAccommodation = val
    },
    'reservation.range': function (newRange, oldRang) {
      if (newRange !== oldRang && newRange !== this.initialResRange) {
        if (this.isBlocked(newRange)) {
          this.$refs.confirmDateChangeModal.open()
        }
      }
    },
  },
  computed: {
    ...mapGetters(['listingsPicker']),
    isOwnerVacation() {
      return this.source === 'Owner'
    },
    nonEditableChannel() {
      return ['Airbnb', 'Booking.com'].includes(this.source)
    },
    listingsItems() {
      if (this.listing.is_multi_unit) {
        return this.listingsPicker.filter(
          l => l.is_multi_unit || !l.container_multi_id
        )
      }
      return this.listingsPicker
    },
    originAccommodation() {
      if (this.listing.is_resort) {
        return this.fareAccommodation + (this.cleaningFee || 0)
      }
      return this.fareAccommodation
    },
    totalAccommodation() {
      if (isEmpty(this.currentRates)) {
        return 0
      }
      const dateArr = this.generateDateRangeArray(
        this.reservation.range[0],
        this.reservation.range[1]
      )
      return dateArr.reduce((sum, item) => {
        if (!this.currentRates[item]) {
          return sum
        }
        return sum + this.currentRates[item].price
      }, 0)
    },
    initialResRange() {
      return [this.parseDate(this.checkIn), this.parseDate(this.checkOut)]
    },
  },
  methods: {
    async getRates() {
      if (!this.reservation.listingId) return
      this.currentRates = await this.$store.dispatch(
        'listings/getListingRates',
        this.reservation.listingId
      )
    },
    allowedDates(date) {
      if (isEmpty(this.currentRates)) {
        return true
      }
      const dayBefore = this.$moment(date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      return (
        (this.currentRates[date] &&
          this.currentRates[date].status === 'available') ||
        this.$moment(date).isBetween(
          this.parseDate(this.checkIn),
          this.parseDate(this.checkOut),
          undefined,
          '[]'
        ) ||
        (this.currentRates[dayBefore] &&
          this.currentRates[date] &&
          this.currentRates[date].status !== 'available' &&
          this.currentRates[dayBefore].status === 'available' &&
          this.reservation.listingId === this.listing.id)
      )
    },
    eventColors(date) {
      if (
        this.currentRates[date] &&
        this.currentRates[date].status === 'available'
      ) {
        return 'green lighten-1'
      }
      return ''
    },
    async submit() {
      const payload = {}
      // payload.pms_unsync = !this.isEditable
      payload.listing_id = this.reservation.listingId
      payload.fare_accommodation = this.preservePrice
        ? this.originAccommodation
        : this.reservation.fareAccommodation
      if (
        !equal(this.reservation.range, [
          this.parseDate(this.checkIn),
          this.parseDate(this.checkOut),
        ])
      ) {
        payload.check_in = this.reservation.range[0]
        payload.check_out = this.reservation.range[1]
      }
      if (this.reservation.status !== this.status) {
        payload.status = this.reservation.status
      }
      this.$store.commit('updateLoading', true)
      const response = await this.$store.dispatch(
        'reservation/alterReservation',
        {
          id: this.reservationId,
          ...payload,
        }
      )
      this.$store.commit('updateLoading', false)
      if (response) {
        this.$emit('change')
      }
    },
    isBlocked(range) {
      const dateRange = this.generateDateRangeArray(range[0], range[1])
      const isAnyDateUnavailable = dateRange.some(
        date => this.currentRates[date].status !== 'available'
      )
      return isAnyDateUnavailable
    },
    setResDateRange() {
      this.reservation.range = this.initialResRange
    },
  },
}
</script>

<style scoped>
.btn-border {
  border: solid 1px #dfe1e6;
}
</style>
