import axios from '@/axios/config'
import orderBy from 'lodash/fp/orderBy'
import { errorStatus } from '@/store'
import Toaster from '@/utils/toaster'
import { get } from 'lodash'

export default {
  state: {
    currentSubTaskId: null,
    showTemplateModal: false,
    currentEditTemplate: null,
    currentInspectionTaskId: null,
    imageProgress: 0,
    uploadError: null,
    listingTaskTemplates: [],
    showListingTaskFeed: false,
    displaySetupMode: false,
    listingTaskFlows: [],
    quickTasksCreated: 0,
  },
  mutations: {
    showListingSubTaskModal(state, taskId) {
      state.currentSubTaskId = taskId
    },
    updateShowTemplateModal(state, taskId) {
      state.showTemplateModal = taskId
    },
    showInspectionSubTaskModal(state, taskId) {
      state.currentInspectionTaskId = taskId
    },
    updateListingTaskTemplates(state, value) {
      state.listingTaskTemplates = value
    },
    updateListingTaskFlows(state, value) {
      state.listingTaskFlows = value
    },
    updateCurrentEditTemplate(state, value) {
      state.currentEditTemplate = value
      state.showTemplateModal = !!value
    },
    showUploadError(state, value) {
      state.uploadError = value
    },
    toggleListingTaskFeed(state, value) {
      state.showListingTaskFeed = value
    },
    updateDisplaySetupMode(state, value) {
      state.displaySetupMode = value
    },
    updateQuickTasksCreated(state) {
      state.quickTasksCreated++
    },
  },
  actions: {
    async blockAction({ dispatch }, { id, ...payload }) {
      try {
        await axios.post('/api/listing-tasks/block/' + id, {
          block: payload.block,
        })
        return await dispatch('getListingTaskBg', id)
      } catch (err) {
        alert(err)
      }
    },
    async getListingTaskFlows(
      { commit },
      { with_roots, division, commitToStore = true }
    ) {
      try {
        const { data } = await axios.get(
          `/api/listing-task-flows${division ? '/' + division : ''}`,
          { params: { with_roots } }
        )
        commitToStore && commit('updateListingTaskFlows', data)
        return data
      } catch (err) {
        console.error(err)
      }
    },
    async createListingTaskFlow({ dispatch }, { flow, division }) {
      try {
        await axios.post(`/api/listing-task-flows`, flow)
        dispatch('getListingTaskFlows', { division })
      } catch (err) {
        console.error(err)
      }
    },
    async updateListingTaskFlow({ dispatch }, { flow, division }) {
      try {
        await axios.patch(`/api/listing-task-flows/${flow.id}`, flow)
        dispatch('getListingTaskFlows', { division })
      } catch (err) {
        console.error(err)
      }
    },
    async startListingTaskFlow(store, { flowId, listingId }) {
      try {
        await axios.post(`/api/listing-task-flows/${flowId}/start`, {
          listing_id: listingId,
        })
      } catch (err) {
        console.error(err)
      }
    },
    async removeListingTaskFlow({ dispatch }, { flowId, division }) {
      try {
        await axios.delete(`/api/listing-task-flows/${flowId}`)
        dispatch('getListingTaskFlows', { division })
      } catch (err) {
        console.error(err)
      }
    },
    async getListingTaskTemplates({ commit }, flowId) {
      try {
        commit('updateLoading', true)
        const { data } = await axios.get(
          `/api/listing-task-flows/${flowId}/templates`
        )
        commit('updateListingTaskTemplates', data)
        commit('updateLoading', false)
      } catch (err) {
        alert(err)
      }
    },
    async getListingTasksRunningFlows(store, { division, ...params }) {
      const url = division
        ? `/api/listing-task-flows/${division}/running-flows`
        : `/api/listing-task-flows/running-flows`
      return await axios
        .get(url, { params })
        .then(res => {
          return res
        })
        .catch(alert)
    },
    async getRunningFlowSubFlows(store, id) {
      return await axios
        .get(`/api/listing-task-flows/running-flows/${id}/sub-flows`)
        .then(res => {
          return res
        })
        .catch(alert)
    },
    async createListingTaskTemplate({ dispatch, commit }, { ...payload }) {
      try {
        commit('updateLoading', true)
        await axios.post(`/api/listing-task-templates`, payload)
        dispatch('getListingTaskTemplates', payload.listing_task_flow_id)
        commit('updateLoading', false)
      } catch (err) {
        console.error(err)
      }
    },
    async updateListingTaskTemplate({ dispatch, state }, { id, ...payload }) {
      try {
        await axios.post(`/api/listing-task-templates/${id}`, payload)
        const flowId = state.listingTaskTemplates[0].listing_task_flow_id
        dispatch('getListingTaskTemplates', flowId)
      } catch (err) {
        console.error(err)
      }
    },
    async sendListingTaskThread(store, { id, ...payload }) {
      try {
        await axios.post(`/api/listing-setup-tasks/${id}/send-msg`, payload)
      } catch (err) {
        console.error(err)
      }
    },
    async updateListingTask({ dispatch, commit }, { id, ...payload }) {
      try {
        commit('updateListingTaskLoading', true)
        await axios.post(`/api/listing-tasks-new/${id}`, payload)
        return await dispatch('getListingTaskBg', id)
      } catch (err) {
        commit('updateListingTaskLoading', true)
        Toaster.show([
          { type: 'error', text: `Listing task #${id} is not found` },
        ])
      }
    },
    async ownerApproval({ commit }, { id, user_id }) {
      try {
        commit('updateListingTaskLoading', true)
        return await axios.post(`/api/listing-tasks/${id}/owner-approval`, {
          user_id,
        })
      } catch (err) {
        console.error(err)
      }
      commit('updateListingTaskLoading', false)
    },
    async requestOwnerApproval({ commit, dispatch }, { id, user_id }) {
      try {
        commit('updateListingTaskLoading', true)
        await axios.post(`/api/listing-tasks/${id}/request-owner-approval`, {
          user_id,
        })
        await dispatch('getListingTaskBg', id)
      } catch (err) {
        console.error(err)
      }
      commit('updateListingTaskLoading', false)
    },
    async deleteListingTask(store, id) {
      try {
        await axios.delete(`/api/listing-tasks/${id}`)
      } catch (err) {
        console.error(err)
      }
    },
    async approveListingTask(
      { dispatch, commit },
      { id, showLoading, listingId, ...payload }
    ) {
      try {
        showLoading && commit('updateLoading', true)
        await axios.post(`/api/listing-tasks-new/${id}/approve`, payload)
        await dispatch('getListingTaskBg', id)
        const params = new URL(window.location.href).searchParams
        const regionIds = params.get('region_ids')
        if (params.get('filter')) {
          const payload = {
            filter: params.get('filter'),
          }
          if (regionIds) payload.region_ids = regionIds
          if (!params.has('region_ids')) payload.listingId = listingId
          await dispatch('listingReport/getListingReport', payload)
        }

        showLoading && commit('updateLoading', false)
      } catch (err) {
        console.error(err)
      }
    },
    async getContinuationHierarchy(store, id) {
      return axios.get(`/api/listing-tasks/${id}/continuation-hierarchy`)
    },
    async createCleaningTask({ dispatch }, id) {
      try {
        await axios.post(`/api/listing-tasks/${id}/create/cleaning`)
        await dispatch('getListingTaskBg', id)
      } catch (error) {
        alert(error)
      }
    },
    async addRepairMaterials({ dispatch }, { id, ...payload }) {
      try {
        await axios.post(`/api/listing-tasks/${id}/materials`, payload)
        await dispatch('getListingTaskBg', id)
      } catch (error) {
        alert(error)
      }
    },
    async removeRepairMaterials({ dispatch }, { id, expenseId }) {
      try {
        await axios.delete(`/api/listing-tasks/${id}/materials/${expenseId}`)
        await dispatch('getListingTaskBg', id)
      } catch (error) {
        alert(error)
      }
    },
    async continueListingTaskFlow({ dispatch }, id) {
      try {
        await axios.post(`/api/listing-tasks/${id}/continue-flow`)
        await dispatch('getListingTaskBg', id)
      } catch (error) {
        console.error(error)
      }
    },
    createListingSubTask: async function (context, payload) {
      try {
        const { data } = await axios.post('/api/listing-sub-tasks', payload)
        await context.dispatch('getListingTaskBg', data.listing_task_id)
      } catch (error) {
        if (![403, 504].includes(errorStatus(error))) {
          alert(error)
        }
      }
    },
    async updateListingSubTask({ dispatch }, { id, ...payload }) {
      try {
        const { data } = await axios.patch(
          `/api/listing-sub-tasks/${id}`,
          payload
        )
        await dispatch('getListingTaskBg', data.listing_task_id)
      } catch (err) {
        console.error(err)
      }
    },
    async deleteListingSubTask({ dispatch, getters }, { ids, listingTaskId }) {
      try {
        await axios.delete(`/api/listing-sub-tasks/?ids=${ids.join(',')}`)
        const listingTask = getters.currentListingTask
        listingTask.sub_tasks = listingTask.sub_tasks.filter(
          t => !ids.includes(t.id)
        )
        await dispatch('getListingTaskBg', listingTaskId)
      } catch (err) {
        console.error(err)
      }
    },
    async createTaskFromSubTasks({ dispatch }, { id, payload }) {
      return await axios
        .post('/api/listing-tasks/create-from-tasks/lst', payload)
        .then(res => {
          setTimeout(() => {
            dispatch('getListingTaskBg', id)
          }, 250)
          const ltId = get(res, 'data.id')
          const link = ltId ? `/dashboard/listing-task/${ltId}` : undefined
          Toaster.show([
            { type: 'success', text: 'Task Created successfully', link },
          ])
          return res
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })
    },
    async createContinuationTask(store, payload) {
      try {
        const { data } = await axios.post(
          '/api/listing-tasks/continuation',
          payload
        )
        return data
      } catch (error) {
        console.error(error)
      }
    },
    async addComment({ dispatch, commit }, { id, ...payload }) {
      commit('addCommentToTask', { id, ...payload })
      await axios
        .post(`/api/listing-tasks/${id}/comments`, payload)
        .then(() => {
          setTimeout(() => {
            dispatch('getListingTaskBg', id)
          }, 250)
        })
        .catch(error => {
          console.log(error)
          alert(error)
        })
    },
    async seenComments(store, id) {
      await axios.put(`/api/listing-tasks/${id}/comments/seen`)
    },
    async combineServiceCalls(store, payload) {
      return await axios.post(`/api/listing-tasks/combine/`, payload)
    },
    async getSubTasks(_, { id }) {
      const { data } = await axios.get(`/api/listing-tasks/${id}/subtasks`)
      return data
    },
    async getPeriodicTasks(_, { listingId }) {
      const { data } = await axios.get(
        `/api/listing/${listingId}/periodic-tasks`
      )
      return data
    },
    async createPeriodicTask(_, { listingId, ...payload }) {
      const { data } = await axios.post(
        `/api/listing/${listingId}/periodic-tasks`,
        payload
      )
      return data
    },
    async deletePeriodicTask(_, { listingId, id }) {
      const { data } = await axios.delete(
        `/api/listing/${listingId}/periodic-tasks/${id}`
      )
      return data
    },
    async updatePeriodicTask(_, { listingId, id, ...payload }) {
      const { data } = await axios.put(
        `/api/listing/${listingId}/periodic-tasks/${id}`,
        payload
      )
      return data
    },
    incrementQuickTasks({ commit }) {
      return commit('updateQuickTasksCreated')
    },
  },
  getters: {
    listingTaskFlows(state) {
      return state.listingTaskFlows
    },
    listingTaskTemplates(state) {
      return state.listingTaskTemplates
    },
    editedSubTask(state, getters, rootState, rootGetters) {
      return rootGetters.currentListingTask.sub_tasks.find(
        t => t.id === state.currentSubTaskId
      )
    },
    rejectedSubTask(state, getters, rootState, rootGetters) {
      return rootGetters.currentListingTask.sub_tasks.find(
        t => t.id === state.currentInspectionTaskId
      )
    },
    imageProgress(state) {
      return state.imageProgress
    },
    uploadError(state) {
      return state.uploadError
    },
    rejectsList(state, getters, rootState, rootGetters) {
      if (rootGetters.currentListingTask) {
        return orderBy(
          ['created_at'],
          ['desc'],
          rootGetters.currentListingTask.listing_task_rejects.map(t => {
            const user = rootGetters['users/usersMap'][t.user_id] || {}
            return {
              done_by: user.name,
              ...t,
            }
          })
        )
      }
      return []
    },
    commentsList(state, getters, rootState, rootGetters) {
      if (rootGetters.currentListingTask) {
        return orderBy(
          ['created_at'],
          ['desc'],
          rootGetters.currentListingTask.listing_task_comments.filter(
            c => !c.parent_id
          )
        )
      }
      return []
    },
    internalComments(state, getters, rootState, rootGetters) {
      if (rootGetters.currentListingTask) {
        return rootGetters.currentListingTask.internal_comments || ''
      }
      return ''
    },
    feedOpen(state) {
      return state.showListingTaskFeed
    },
    quickTasksCount(state) {
      return state.quickTasksCreated
    },
  },
}
