<template>
  <v-tabs
    v-model="paramsTab"
    icons-and-text
    class="tabs-menu main-background invetor-tab"
  >
    <v-tab href="#auto-tasks">
      Auto rules
      <v-icon>$rules</v-icon>
    </v-tab>
    <v-tab href="#templates">
      Templates Flows
      <v-icon>mdi-sitemap-outline</v-icon>
    </v-tab>
    <v-tab v-if="hasAbility('company-config')" href="#config">
      Config
      <v-icon>mdi-cog-outline</v-icon>
    </v-tab>
    <v-tabs-items v-model="paramsTab">
      <v-progress-linear
        v-show="listingLoading"
        color="secondary"
        style="margin: 0"
        absolute
        :indeterminate="true"
      />
      <v-tab-item class="pl-2 pr-2" value="auto-tasks">
        <v-layout wrap justify-center>
          <v-flex sm10>
            <v-switch
              v-if="!loading && false"
              v-model="showRuleSummary"
              mt-2
              mb-2
              :label="$t('Show rules per house')"
            >
            </v-switch>
            <auto-listing-task-rules-table
              v-show="!showRuleSummary"
              :general-mode="true"
            />
            <auto-listing-task-rules-warnings
              v-show="showRuleSummary"
            ></auto-listing-task-rules-warnings>
          </v-flex>
        </v-layout>
      </v-tab-item>

      <v-tab-item value="templates" class="pl-2 pr-2" eager>
        <listing-task-templates />
      </v-tab-item>
      <v-tab-item value="config" class="pl-2 pr-2" eager>
        <automations-config />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import AutoListingTaskRulesTable from 'components/auto-listing-task-rules-table'
import AutoListingTaskRulesWarnings from 'components/auto-listing-task-rules-warnings'
import ListingTaskTemplates from 'components/listing-tasks/listing-task-templates'
import AutomationsConfig from 'components/automations-config'

export default {
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      showRuleSummary: false,
    }
  },
  components: {
    AutomationsConfig,
    ListingTaskTemplates,
    AutoListingTaskRulesWarnings,
    AutoListingTaskRulesTable,
  },
}
</script>
