<template>
  <v-sheet>
    <conversation-drawer />
    <div class="ma-2 ma-sm-0">
      <task-calendar-filters
        :open-on-change="false"
        @change="onFiltersChanged"
      />
    </div>
    <v-divider class="mb-4" />
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      disable-sort
      fixed-header
      hide-default-footer
      height="70vh"
      :loading="listingLoading"
    >
      <template #item.priority="{ item }">
        <div v-if="item.priority">
          <lt-priority :value="item.priority" :readonly="true" />
        </div>
        <div v-else>-</div>
      </template>
      <template #item.scheduled_at="{ item }">
        {{ parseDate(item.scheduled_at) }}
      </template>
      <template #item.task="{ item }">
        <a
          class="text-decoration-underline blue--text font-weight-medium"
          @click="openTaskDrawer(item.id)"
          >#{{ item.id }}</a
        >
      </template>
      <template #item.status="{ item }">
        <v-sheet width="140px">
          <lt-status
            :listing-task="item"
            small="true"
            @change="onStatusChange({ id: item.id, status: $event })"
            @after-change="getTasks"
          />
        </v-sheet>
      </template>
      <template #item.assigned_to_name="{ item }">
        {{ item.assigned_to_name || '-' }}
      </template>
    </v-data-table>
    <v-pagination
      v-if="listingTasksPagination"
      v-model="page"
      :length="pageLength"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
      @input="getTasks"
    />
  </v-sheet>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters.vue'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import LtMixin from 'components/mixins/lt-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'
import LtStatus from 'components/listing-tasks/lt-status'
import LtPriority from 'components/listing-tasks/lt-priority'

export default {
  name: 'Requests',
  components: {
    TaskCalendarFilters,
    ConversationDrawer,
    LtStatus,
    LtPriority,
  },
  mixins: [LtMixin, formattersMixin],
  data() {
    return {
      page: 1,
      headers: [
        {
          text: 'Priority',
          value: 'priority',
          align: 'center',
          width: '150px',
        },
        { text: 'Task', value: 'task', width: '120px', align: 'center' },
        { text: 'Description', value: 'description' },
        {
          text: 'Scheduled At',
          value: 'scheduled_at',
          width: '150px',
          align: 'center',
        },
        { text: 'Assigned To', value: 'assigned_to_name', align: 'center' },
        { text: 'Status', value: 'status', align: 'center', width: '150px' },
      ],
    }
  },
  async mounted() {
    this.getTasks()
  },
  methods: {
    ...mapActions(['getListingTasks']),

    openConversation(conversation) {
      this.$router.push({ query: { conversation } })
    },
    openTaskDrawer(taskId) {
      this.addToQuery({ reportTaskDrawer: taskId })
    },
    onFiltersChanged() {
      this.page = 1
      this.getTasks()
    },
    getTasks() {
      const filters = this.prepareFiltersPayload(this.filters, {})
      this.getListingTasks({
        filters: {
          ...filters,
          triggered_by_ai: true,
          page: this.page,
        },
      })
    },
  },
  computed: {
    ...mapState(['listingTasks', 'listingTasksPagination', 'listingLoading']),
    ...mapGetters('taskCalendar', ['filters']),
    pageLength() {
      return Math.ceil(
        this.listingTasksPagination.count / this.listingTasksPagination.per_page
      )
    },
    items() {
      return [...this.listingTasks]
    },
  },
}
</script>

<style></style>
