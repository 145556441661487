<template>
  <v-sheet
    height="40vh"
    rounded
    class="scrollable-dvr pa-3 grey--text font-weight-medium"
  >
    <div class="text-subtitle-1 grey--text">{{ title }}</div>
    <v-divider class="my-3" />
    <v-sheet v-for="(item, i) in normalizedNumbers" :key="i" class="">
      <div v-if="item[1]" class="my-2 d-flex flex-column">
        <div class="text-subtitle-2s grey--text text-capitalize">
          {{ item[0] }}
        </div>
        <div class="d-flex align-center">
          <v-sheet :width="`${item.normalizedValue}%`">
            <v-progress-linear
              rounded
              height="8"
              color="primary rounded"
              :value="100"
            />
          </v-sheet>
          <span class="px-1 text-caption grey--text font-weight-medium">{{
            dollarFormatter(item[1], 0)
          }}</span>
        </div>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import { cloneDeep } from 'lodash'

export default {
  name: 'SlidersChart',
  props: ['title', 'items'],
  mixins: [FormattersMixin],
  computed: {
    normalizedNumbers() {
      let copy = cloneDeep(this.items)
      return copy
        .sort((a, b) => {
          return b[1] - a[1]
        })
        .map(item => ({
          normalizedValue: this.max == 0 ? 0 : (item[1] / this.max) * 100,
          ...item,
        }))
    },
    max() {
      return Math.max(...this.items.map(item => item[1])) * 1.3
    },
  },
}
</script>

<style scoped></style>
