<template>
  <v-data-table
    :options="paginationRows"
    :server-items-length="pagination.count"
    :headers="tableHeaders"
    :loading="fetching"
    :items="items"
    height="100%"
    class="elevation-1 d-flex flex-column flex-grow-1"
    :footer-props="{
      itemsPerPageOptions: [10, 50, 100],
    }"
    @pagination="paginationChanged($event)"
  >
    <template #item.guest="{ item }">
      <a
        v-if="item.pms_conversation_id"
        @click="openChat(item.pms_conversation_id)"
        >{{ item.fullName }}</a
      >
      <span v-else>
        {{ item.fullName }}
      </span>
    </template>
    <template #item.status="{ item }">
      <v-select
        v-model="item.status"
        :items="statuses"
        dense
        hide-details
        @change="updateReview($event, 'status', item)"
      >
        <template slot="selection" slot-scope="{ item: selectionItem }">
          <v-icon class="mr-2" :color="selectionItem.color" x-small
            >mdi-circle</v-icon
          >
          {{ selectionItem.text }}
        </template>
        <template slot="item" slot-scope="{ item: selectItem }">
          <v-icon class="mr-2" :color="selectItem.color" x-small
            >mdi-circle</v-icon
          >
          {{ selectItem.text }}
        </template>
      </v-select>
    </template>
    <template #item.notes="{ item }">
      <v-textarea
        v-model="item.notes"
        rows="1"
        label="Notes"
        @change="updateReview($event, 'notes', item)"
      />
    </template>
    <template #item.listing="{ item }">
      <span>{{ item.listing_nickname }}</span>
    </template>
    <template #item.created="{ item }">
      <span class="min-w-150">
        {{ parseDate(item.created_at_guesty) || parseDate(item.created_at) }}
      </span>
    </template>
    <template #item.channel="{ item }">
      <div>
        {{ item.source }}
        <div v-if="item.source === 'Airbnb'" class="mx-2 text-caption">
          ({{ item.account_name }})
        </div>
      </div>
    </template>
    <template #item.score="{ item }">
      <v-btn
        :class="scoreClass(item.normilized_scrore_overall)"
        @click="showReviewModal(item)"
      >
        {{ round(item.normilized_scrore_overall, 1) / 2 }}
      </v-btn>
    </template>
    <template #item.tags="{ item }">
      <v-tooltip
        v-for="[tag, value] in Object.entries(item.ai_tags || [])"
        :key="tag"
        content-class="custom-tooltip"
        top
      >
        <template #activator="{ on }">
          <v-chip label color="primary lighten-1" class="ma-1" small v-on="on"
            >{{ tag }}
          </v-chip>
        </template>
        <div class="text-body-2">
          {{ value }}
        </div>
      </v-tooltip>
    </template>
    <template #item.review_link="{ item }">
      <v-btn
        v-if="item.review_link"
        color="primary"
        x-small
        icon
        target="_blank"
        :href="item.review_link"
      >
        <v-icon left small>mdi-open-in-new</v-icon>
      </v-btn>
    </template>
    <template #item.replied="{ item }">
      <review-reply-modal
        v-if="!item.response && bookingOrAirbnb(item)"
        :review="item"
      />
      <v-tooltip v-else-if="item.response" top>
        <template #activator="{ on }">
          <v-icon v-on="on">$info_circle_alt</v-icon>
        </template>
        <v-card class="white pa-3">
          <div>{{ item.response }}</div>
        </v-card>
      </v-tooltip>
      <div v-else class="secondary--text">No reply</div>
    </template>
    <template #item.actions="{ item }">
      <badge-button
        :content="noneCreatedTasks(item)"
        :hide-badge="noneCreatedTasks(item) === 0"
      >
        <v-list>
          <v-list-item v-if="!isEmpty(item.ai_service_calls)" disabled>
            <v-list-item-content>
              <v-list-item-title>AI Suggested</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="([dep, sc], ind) in Object.entries(
              item.ai_service_calls || {}
            )"
            :key="ind"
          >
            <v-list-item-content class="pl-5">
              <v-list-item-title>
                <span class="text-uppercase">{{ dep }} - </span>
                <v-chip
                  :color="priorityColor(sc.priority)"
                  x-small
                  label
                  class="mx-1 white--text"
                >
                  {{ sc.priority }}
                </v-chip>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-icon v-bind="attrs" small v-on="on">
                      $info_circle_alt
                    </v-icon>
                  </template>
                  <div>
                    {{ sc.reference }}
                  </div>
                </v-tooltip>
              </v-list-item-title>
              <div class="secondary--text text-caption text-capitalize">
                <div v-for="issue in sc.issues" :key="issue" class="my-1">
                  {{ issue }}
                </div>
              </div>
            </v-list-item-content>

            <v-list-item-action>
              <sc-create-btn
                v-if="!sc.created_id"
                :data="sc"
                :type="dep"
                :review-id="item.id"
                @created="afterTaskCreate"
              />
              <a
                v-else
                class="primary--text"
                target="_blank"
                :href="`/dashboard/listing-task/${sc.created_id}`"
                >#{{ sc.created_id }}
              </a>
            </v-list-item-action>
          </v-list-item>
          <v-list-item disabled>
            <v-list-item-content>
              <v-list-item-title>Manual service calls</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content class="pl-5">
              <v-list-item-title v-if="item.listing_task">
                <span class="text-uppercase"
                  >{{ item.listing_task.task_type }} -
                </span>
                <v-chip
                  :color="priorityColor(item.listing_task.priority)"
                  x-small
                  label
                  class="mx-1 white--text"
                >
                  {{ item.listing_task.priority }}
                </v-chip>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.listing_task">
                {{ item.listing_task.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <listing-task-module
                v-if="!item.listing_task"
                :review-id="item.id"
                :pre-fields="{
                  listings_ids: getListingId(item),
                  ...quickFieldCatalog,
                }"
                :after-save-func="afterTaskCreate"
              >
                <template #customBtn="{ on }">
                  <v-btn
                    :ripple="false"
                    small
                    outlined
                    color="black"
                    :loading="loading"
                    v-on="on"
                  >
                    <v-icon small left>add</v-icon>
                    Task
                  </v-btn>
                </template>
              </listing-task-module>
              <a
                v-else
                class="primary--text"
                target="_blank"
                :href="`/dashboard/listing-task/${item.listing_task.id}`"
                >#{{ item.listing_task.id }}
              </a>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </badge-button>
    </template>
  </v-data-table>
</template>

<script>
import Common_functions from 'components/mixins/common_functions'
import ListingTaskModule from 'components/listing-task-module.vue'
import ReviewReplyModal from 'components/reviews/review-reply-modal.vue'
import ColorsMixin from 'components/mixins/colors-mixin'
import ScCreateBtn from 'components/common/sc-create-btn.vue'
import BadgeButton from 'components/common/badge-button.vue'

export default {
  name: 'ReviewsDataTable',
  components: { BadgeButton, ScCreateBtn, ReviewReplyModal, ListingTaskModule },
  props: ['items', 'pagination', 'filteredHeaders', 'fetching'],
  mixins: [Common_functions, ColorsMixin],
  data() {
    return {
      menu: false,
      paginationRows: {
        page: 1,
        itemsPerPage: 10,
        itemsLength: null,
      },
      scoreMetrics: {
        Communication: 'normilized_communication',
        Cleaning: 'normilized_cleaning',
        Accuracy: 'normilized_accuracy',
        Location: 'normilized_location',
        Checkin: 'normilized_checkin',
        Value: 'normilized_value',
      },
      statuses: [
        { text: 'New', color: 'grey' },
        { text: 'In Progress', color: 'yellow' },
        { text: 'No Action', color: 'warning' },
        { text: 'Replied', color: 'error' },
        { text: 'Removed - Refund', color: 'indigo' },
        { text: 'Removed - Rules', color: 'info' },
      ],
      headers: [
        { text: 'Guest', value: 'guest', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Notes', value: 'notes', sortable: false },
        { text: 'Listing', value: 'listing', sortable: false },
        { text: 'Created', value: 'created', sortable: false },
        { text: 'Channel', value: 'channel', align: 'center', sortable: false },
        { text: 'Score', value: 'score', sortable: false },
        {
          text: 'Review Link',
          value: 'review_link',
          align: 'center',
          sortable: false,
        },
        { text: 'Tags', value: 'tags', sortable: false },
        { text: 'Replied', value: 'replied', sortable: false },
        { text: 'Task', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    tableHeaders() {
      if (this.filteredHeaders) {
        return this.headers.filter(h => this.filteredHeaders.includes(h.value))
      }
      return this.headers
    },
    quickFieldCatalog() {
      const catalog =
        this.$store.state.tasksCatalog.catalogItemSelect.find(
          c => c.system_key === 'guest_review'
        ) || {}
      return {
        task_type: catalog.department,
        sub_topic: catalog.sub_topic,
        listing_task_catalog_item_name: catalog.description,
        listing_task_catalog_item_id: catalog.id,
      }
    },
  },
  methods: {
    noneCreatedTasks(review) {
      return (
        Object.values(review.ai_service_calls || {}).filter(
          task => task.created_id
        ).length + (review.listing_task ? 1 : 0)
      )
    },
    bookingOrAirbnb(item) {
      return item.source === 'Booking.com' || item.source === 'Airbnb'
    },
    getListingId(item) {
      const listing = this.$store.getters.listingsPicker.find(
        l => l.nickname === item.listing_nickname
      )
      return listing ? [listing.id] : []
    },
    openChat(id) {
      this.$router.push({ query: { conversation: id } })
    },
    afterTaskCreate() {
      this.$emit('pagination', this.pagination)
    },
    scoreClass(score) {
      if (score >= 8) {
        return 'green--text lighten-2'
      }
      if (score >= 5) {
        return 'yellow--text accent-4'
      }
      if (score < 5) {
        return 'red--text lighten-2'
      }
    },
    paginationChanged(pagination) {
      this.$emit('pagination', pagination)
    },
    updateReview(val, key, review) {
      let res = {}
      res[key] = val
      res['id'] = review.id
      this.$store.dispatch('updateReview', res)
    },
    addressFor(id) {
      return { path: `/dashboard/sales-chat/${id}` }
    },
    showReviewModal(item) {
      this.$store.commit('showModal', {
        name: 'ReviewCard',
        props: {
          review: item,
          nonNormalized: item.source === 'Airbnb',
        },
        isPersistent: false,
      })
    },
  },
}
</script>

<style scoped></style>
