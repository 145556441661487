<template>
  <div v-if="currentListingSetup">
    <v-card class="listing-header-name pr-3 pl-3">
      <v-card-text>
        <v-row class="ml-1 wrap align-center">
          <v-col cols="10" sm="8" lg="7" mt-2 class="offset-sm1">
            <h3 class="purple-icon">Listing Address:</h3>
            <h4 v-if="!isDemoUser" class="mb-0 grey-text">
              {{ currentListing.address }}
              <span v-if="currentListing.nickname"
                >/ {{ currentListing.nickname }}</span
              >
            </h4>
            <span v-if="isDemoUser" class="address-text grey-text">{{
              currentListing.city_name
            }}</span>
            <h5 v-if="isAdmin" mr-2>
              <router-link :to="addressEditFor(currentListing.id)">
                Go to manage
              </router-link>
            </h5>
            <h5 v-if="isAdmin && currentListing.pricing_id">
              <router-link
                class="cyan-icon"
                :to="addressPricing(currentListing.pricing_id)"
              >
                Pricing
              </router-link>
            </h5>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-tabs v-model="paramsTab" icons-and-text class="tabs-menu invetor-tab">
      <v-tab href="#tasks">
        Tasks
        <v-icon>date_range</v-icon>
      </v-tab>

      <v-tab v-if="currentListingProject" href="#board">
        Board
        <v-icon>mdi-clipboard-list-outline</v-icon>
      </v-tab>

      <v-tab href="#info">
        Info
        <v-icon>home</v-icon>
      </v-tab>

      <v-tab href="#progress">
        Progress
        <v-icon>donut_small</v-icon>
      </v-tab>

      <v-tab href="#budget">
        Budget
        <v-icon>mdi-cash-register</v-icon>
      </v-tab>

      <v-tabs-items v-model="paramsTab" touchless>
        <v-tab-item value="budget" class="px-4">
          <listing-budget :listing-id="currentListing.id" />
        </v-tab-item>
        <v-tab-item value="info" class="px-4">
          <v-row cols="10" wrap mt-4>
            <v-col cols="12" mt-3 mb-4 class="cyan-icon bolder headline-font">
              <span class="grey-text mr-2">Address: </span>
              <span>{{ currentListingSetup.listing_address }}</span>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="currentListingSetup.listing_info['beds']"
                prepend-icon="king_bed"
                label="Beds"
                required
                @change="infoChange($event, 'beds')"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="currentListingSetup.listing_info['baths']"
                prepend-icon="bathtub"
                label="Baths"
                required
                @change="infoChange($event, 'baths')"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="currentListingSetup.listing_info['sqft']"
                prepend-icon="square_foot"
                label="Sqft"
                required
                @change="infoChange($event, 'sqft')"
              />
            </v-col>
            <v-col v-if="currentListing" cols="12">
              <Personal
                :on-change="updatePersonal"
                :personal="currentListing.personal"
                :personal-types="personalTypes"
              />
            </v-col>
            <v-col cols="12" mt-2 mb-1>
              <v-expansion-panels mt-3>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="small-text">
                      <v-icon class="purple-icon">info</v-icon>
                      Info
                    </span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-col cols="10">
                      <info-items-panel
                        v-if="!loading"
                        :listing-id="currentListingSetup.listing_id"
                      />
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tasks" class="px-4">
          <v-row class="wrap justify-center mt-3">
            <v-col cols="10" class="mt-3">
              <tasks-calendar :listing-id="currentListingSetup.listing_id" />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="board" class="px-4">
          <project
            v-if="currentListingProject"
            :project-id="currentListingProject.id"
          />
        </v-tab-item>
        <v-tab-item value="progress" class="px-4">
          <v-row>
            <v-col cols="5">
              <h3>Service calls</h3>
              {{ listingTaskStatsText }}
              <v-progress-linear
                :color="progressColor(listingTaskStatsVal)"
                height="30"
                :value="listingTaskStatsVal"
                striped
              />
            </v-col>
            <v-col cols="12">
              <listing-task-templates
                division="Setup"
                :enable-flow-start="true"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'

import InfoItemsPanel from 'components/info-items-panel.vue'

import TasksCalendar from 'components/calendar/tasks-calendar'
import Personal from 'components/listing/personal'
import ListingTaskTemplates from 'components/listing-tasks/listing-task-templates'
import Project from 'components/projects/project'
import { last } from 'lodash'
import ListingBudget from 'components/listing/listing-budget'

export default {
  components: {
    ListingBudget,
    Project,
    ListingTaskTemplates,
    Personal,
    TasksCalendar,
    InfoItemsPanel,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data: function () {
    return {
      uploaded: false,
      newRoomDesc: null,
      menu: false,
    }
  },
  computed: {
    listingTaskStatsVal() {
      if (
        this.currentListingSetupStats &&
        this.currentListingSetupStats.listing_tasks.all != 0
      ) {
        return (
          (this.currentListingSetupStats.listing_tasks.status_done /
            this.currentListingSetupStats.listing_tasks.all) *
          100
        )
      }
      return 0
    },
    listingTaskStatsText() {
      if (this.currentListingSetupStats) {
        return `Done - ${this.currentListingSetupStats.listing_tasks.status_done} / Total - ${this.currentListingSetupStats.listing_tasks.all}`
      }
      return ''
    },
    currentListingSetupStats() {
      return this.$store.state.currentListingSetupStats
    },
    currentListing() {
      return this.$store.state.currentListing
    },
    currentListingProject() {
      return this.currentListing &&
        this.currentListing.projects &&
        this.currentListing.projects.length
        ? last(this.currentListing.projects)
        : null
    },
    currentListingSetup() {
      return this.$store.state.currentListingSetup
    },
    currentListingSetupListNames() {
      return this.$store.state.currentListingSetupListNames
    },
    tasksLists() {
      return this.$store.state.currentListingSetupTasks
    },
  },
  watch: {
    currentListingSetup: {
      handler: function (newValue) {
        this.$store.commit('updateCurrentListingSetup', newValue)
        this.$store.dispatch('updateListingSetup', newValue)
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.commit('updateDisplaySetupMode', true)
  },
  beforeDestroy() {
    this.$store.commit('updateDisplaySetupMode', false)
  },
  methods: {
    addressEditFor(id) {
      return { path: `/dashboard/edit/${id}` }
    },
    addressPricing(id) {
      return id ? { path: `/dashboard/pricing/${id}` } : null
    },
    updatePersonal(personal) {
      this.$store.dispatch('updateListingPartial', {
        id: this.currentListing.id,
        personal,
      })
    },
    infoChange(newVal, key) {
      this.currentListingSetup.listing_info[key] = newVal
      let clone = cloneDeep(this.currentListingSetup)
      this.$store.commit('updateCurrentListingSetup', clone)
      this.$store.dispatch('updateListingSetup', clone)
    },
    reFetch() {
      this.$store.dispatch('updateListingSetup', this.currentListingSetup)
    },
  },
}
</script>
