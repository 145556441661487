<template>
  <v-card mt-3>
    <v-card-title>
      <span class="text-h5">Edit Listing</span>
      <v-spacer></v-spacer>
      <v-btn small color="primary" @click.native="save"
        >{{ $t('Save') }}
      </v-btn>
    </v-card-title>
    <v-card-text>
      <span class="grey-text">PMS id: {{ currentEditListing.guesty_id }}</span>
      <!--      <a-->
      <!--        :href="-->
      <!--          'https://app.guesty.com/listings/' + currentEditListing.guesty_id-->
      <!--        "-->
      <!--        >To PMS listing</a-->
      <!--      ><br />-->
      <!--      <a-->
      <!--        :href="currentEditListing.picture"-->
      <!--        :download="currentEditListing.nickname + '.jpeg'"-->
      <!--        >Show first image</a-->
      <!--      >-->
      <v-container grid-list-md>
        <v-row class="wrap">
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="editAttributes.address"
              :label="$t('Address')"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="editAttributes.nickname"
              label="Nickname"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-select
              v-model="editAttributes.listing_type"
              :items="['hotel', 'unit', 'regular']"
              :label="$t('Listing Type')"
              item-text="name"
              item-value="id"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6" class="pa-0">
            <v-select
              v-model="editAttributes.ota_type"
              label="OTA Type"
              :items="otaTypes"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-select
              v-if="editAttributes.listing_type == 'regular'"
              v-model="editAttributes.stage"
              :items="['setup', 'regular', 'long-term']"
              :label="$t('Listing stage')"
              item-text="name"
              item-value="id"
              outlined
              dense
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <multi-property-picker
              v-if="editAttributes.listing_type === 'unit'"
              v-model="editAttributes.hotel_id"
              :disabled="true"
              label="Complex"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <multi-property-picker
              v-if="editAttributes.listing_type === 'hotel'"
              v-model="editAttributes.units_ids"
              :close-on-content-click="false"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-select
              v-model="editAttributes.region_id"
              :disabled="
                !(
                  isAdmin ||
                  isPropertyManager ||
                  hasAbility('regions-management')
                )
              "
              :label="$t('Region')"
              outlined
              dense
              :items="regionItems"
            />
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-select
              v-model="editAttributes.zone_id"
              :disabled="
                !(
                  isAdmin ||
                  isPropertyManager ||
                  hasAbility('regions-management')
                )
              "
              :label="$t('Zone')"
              outlined
              dense
              :items="zoneItems"
            />
          </v-col>
        </v-row>
        <v-row class="wrap">
          <v-col v-if="isAdmin" cols="12">
            <span class="bolder">{{ $t('Duplicates settings') }}</span>
            <v-select
              v-model="editAttributes.master_guesty_id"
              outlined
              dense
              :items="currentEditListing.optional_masters"
              :label="$t('Main listing')"
              item-value="guesty_id"
            >
              <template slot="selection" slot-scope="data">
                <!-- HTML that describe how select should render selected items -->
                {{ data.item.nickname }}
                <span
                  class="pl-2"
                  :class="data.item.listed ? 'green--text' : 'red--text'"
                  >{{ data.item.listed ? $t('Listed') : $t('Unlisted') }}</span
                >
              </template>
              <template slot="item" slot-scope="data">
                <!-- HTML that describe how select should render items when the select is open -->
                {{ data.item.nickname }}
                <span
                  class="pl-2"
                  :class="data.item.listed ? 'green--text' : 'red--text'"
                  >{{ data.item.listed ? $t('Listed') : $t('Unlisted') }}</span
                >
              </template>
            </v-select>
            <v-autocomplete
              v-model="editAttributes.second_guesty_id"
              :items="listingsPickerWithoutCurrent"
              item-value="guesty_id"
              item-text="nickname"
              clearable
              dense
              outlined
              :label="$t('Duplicate of')"
              @change="updateSecondGuestyId"
            />
          </v-col>
          <v-col
            v-if="isAdmin && currentEditListing.stage === 'setup'"
            cols="12"
            class="comp-wrapper"
          >
            <span class="bolder">{{ $t('Retire listing') }}</span>
            <listing-select :filter-change="listingChange" />

            <v-btn v-if="replacementId" @click="retireListing">{{
              $t('Retire')
            }}</v-btn>
          </v-col>
          <v-col>
            <h4 class="mb-2">Main Owner</h4>
            <v-autocomplete
              v-model.number="editAttributes.main_owner_id"
              :items="currentEditListing.investors.concat(usersList)"
              label="Owner"
              item-text="name"
              item-value="id"
              outlined
              dense
              hide-details
              clearable
            />
          </v-col>
          <v-col v-if="isAdmin && isDesignedVr" cols="12">
            <v-text-field
              v-model="editAttributes.property_tax_id"
              :label="$t('Tax id')"
              outlined
              dense
            />
          </v-col>
          <v-col v-if="false" cols="12" class="comp-wrapper mt-2">
            <h4>Multi-unit settings</h4>
            <v-switch
              v-model="editAttributes.is_multi_unit"
              :label="$t('is multi unit')"
            />
            <br />
            <v-text-field
              v-model="editAttributes.container_multi_id"
              outlined
              dense
              :label="$t('unit in multi unit id')"
            />
          </v-col>
          <!--          <v-col v-if="!isHotel && isDesignedVr" cols="12" class="comp-wrapper">-->
          <!--            <h4 class="purple-icon mt-2 mb-2">Parking settings</h4>-->
          <!--            <v-switch-->
          <!--              v-if="!editAttributes.has_parking_pass"-->
          <!--              v-model="editAttributes.has_assigned_parking"-->
          <!--              :label="$t('This unit has an assigned parking')"-->
          <!--            />-->
          <!--            <v-text-field-->
          <!--              v-if="editAttributes.has_assigned_parking"-->
          <!--              v-model="editAttributes.assigned_parking_desc"-->
          <!--              outlined-->
          <!--              dense-->
          <!--              :label="$t('Assigned parking description')"-->
          <!--            />-->
          <!--            <v-switch-->
          <!--              v-if="!editAttributes.has_assigned_parking"-->
          <!--              v-model="editAttributes.has_parking_pass"-->
          <!--              :label="$t('Parking pass')"-->
          <!--            />-->
          <!--            <v-text-field-->
          <!--              v-if="editAttributes.has_parking_pass"-->
          <!--              v-model="editAttributes.parking_pass_desc"-->
          <!--              :label="$t('Parking pass description')"-->
          <!--              outlined-->
          <!--              dense-->
          <!--            />-->
          <!--          </v-col>-->
        </v-row>
        <v-row v-if="isAdmin && stripeItems.length" class="comp-wrapper mt-2">
          <h4>Stripe</h4>
          <v-col cols="12">
            <v-select
              v-model="editAttributes.stripe_account_id"
              :label="$t('Stripe account')"
              :items="stripeItems"
              outlined
              dense
            />
          </v-col>
          <!--          <v-col cols="12">-->
          <!--            <v-checkbox-->
          <!--              v-model="editAttributes.auto_charge_third"-->
          <!--              :label="$t('Auto charge third')"-->
          <!--            />-->
          <!--          </v-col>-->
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import { mapActions, mapGetters, mapState } from 'vuex'
import ListingSelect from 'components/listing-select.vue'
import MultiPropertyPicker from 'components/multi-property-picker.vue'
import { pick } from 'lodash'

export default {
  components: {
    MultiPropertyPicker,
    ListingSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  data() {
    return {
      menu: false,
      replacementId: null,
      editAttributes: {
        personal: {},
        piggy_bank: {},
        units_ids: [],
        region_id: null,
        listing_type: null,
        investors: [],
      },
    }
  },
  async mounted() {
    this.editAttributes = pick(this.currentEditListing, [
      'id',
      'logo',
      'guesty_id',
      'address',
      'nickname',
      'listing_type',
      'ota_type',
      'stage',
      'hotel_id',
      'units_ids',
      'region_id',
      'zone_id',
      'master_guesty_id',
      'second_guesty_id',
      'main_owner_id',
      'property_tax_id',
      'is_multi_unit',
      'container_multi_id',
    ])
    this.editAttributes.units_ids = this.currentEditListing.units.map(l => l.id)
  },
  computed: {
    ...mapState(['currentEditListing']),
    ...mapState('stripe', ['accounts']),
    ...mapState('regions', ['regions']),
    ...mapState('zones', ['zones']),
    ...mapGetters('users', ['investors', 'usersList']),
    ...mapGetters(['listingsPicker', 'otaTypes']),
    stripeItems() {
      return this.accounts.map(a => ({ text: a.name, value: a.id }))
    },
    regionItems() {
      return this.regions.map(a => ({ text: a.name, value: a.id }))
    },
    zoneItems() {
      return this.zones.map(a => ({ text: a.name, value: a.id }))
    },
    listingsPickerWithoutCurrent() {
      return this.listingsPicker.filter(
        e => e.id !== this.currentEditListing.id
      )
    },
  },
  methods: {
    ...mapActions('listings', ['retireListing']),
    updateSecondGuestyId() {
      this.save()
    },
    retireListing() {
      this.$store.commit('updateLoading', true)

      axios
        .post(`/api/listing/${this.currentEditListing.id}/retire/`, {
          replacement_id: this.replacementId,
        })
        .then(() => {
          this.$store.commit('updateLoading', false)
        })
        .catch(error => {
          this.$store.commit('updateLoading', false)
          alert(error)
        })
    },
    listingChange(listingID) {
      this.replacementId = listingID
    },
    save() {
      this.$store.commit('updateLoading', true)
      this.editAttributes.units = []
      this.$store
        .dispatch('listings/updateListing', {
          id: this.editAttributes.id,
          payload: this.editAttributes,
        })
        .finally(this.$store.commit('updateLoading', false))
    },
  },
}
</script>
