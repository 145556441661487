<template>
  <v-row>
    <v-radio-group v-model="typeToggle" hide-details row class="mt-2 ml-2 mx-1">
      <v-radio class="mr-1" value="owner">
        <template #label>
          <div :class="radioClass">Owner</div>
        </template></v-radio
      >
      <v-radio class="mr-1" value="business">
        <template #label>
          <div :class="radioClass">Business</div>
        </template></v-radio
      >
    </v-radio-group>
    <v-col v-show="typeToggle === 'owner'" cols="12">
      <v-row>
        <v-col cols="12" md="6" class="d-flex flex-column">
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Sum of expenses"
              color="#8126C4"
              :value="dollarFormatter(stats.owners_expenses_sum)"
            />
            <dashboard-data-box
              title="Num of expenses"
              color="#E69B51"
              :value="stats.owners_expenses_num"
            />
          </v-sheet>
          <v-sheet rounded class="pa-4 my-2" elevation="1">
            <sliders-chart
              title="Expenses"
              :items="stats.owners_expenses_sum_sources"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet rounded class="pa-4" elevation="1">
            <line-chart
              graph-id="expenses"
              title="Expenses"
              :stats-object="chartsConfig(stats.owners_expenses_sum_period)"
              :config="chartsConfig(stats.owners_expenses_sum_period)"
              color="#02BC77"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
    <v-col v-show="typeToggle === 'business'" cols="12">
      <v-row>
        <v-col cols="12" md="6" class="d-flex flex-column">
          <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
            <dashboard-data-box
              title="Sum of expenses"
              color="#8126C4"
              :value="dollarFormatter(stats.business_expenses_sum)"
            />
            <dashboard-data-box
              title="Num of expenses"
              color="#E69B51"
              :value="stats.business_expenses_num"
            />
          </v-sheet>
          <v-sheet rounded class="pa-4 my-2" elevation="1">
            <sliders-chart
              title="Expenses"
              :items="stats.business_expenses_sum_sources"
            />
          </v-sheet>
        </v-col>
        <v-col cols="12" md="6">
          <v-sheet rounded class="pa-4" elevation="1">
            <line-chart
              graph-id="business-expenses"
              title="Expenses"
              :stats-object="chartsConfig(stats.business_expenses_sum_period)"
              :config="chartsConfig(stats.business_expenses_sum_period)"
              color="#02BC77"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import DashboardDataBox from 'components/accounting/dashboard-data-box.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import LineChart from 'components/line-chart.vue'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import SlidersChart from 'components/accounting/sliders-chart.vue'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'AccountingExpensesTab',
  mixins: [FormattersMixin, CommonFunctions],
  components: { SlidersChart, LineChart, DashboardDataBox },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    dates: {
      type: Object,
    },
    year: {
      type: Number,
    },
    yearly: {
      type: Boolean,
    },
  },
  data() {
    return {
      radioClass: 'text-caption text-capitalize font-weight-medium',
      typeToggle: 'owner',
    }
  },
  methods: {
    chartsConfig(stats) {
      return {
        type: 'bar',
        data: {
          labels: this.yearly
            ? this.months
            : this.getDaysInMonth(this.dates.month, this.dates.year),
          datasets: [
            {
              data: Object.values(stats) || [],
              borderColor: '#169CA8',
              backgroundColor: '#169CA8',
              fill: false,
              label: this.year,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            labels: {
              usePointStyle: true,
            },
            display: true,
            align: 'start',
            textAlign: 'left',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
  computed: {
    months() {
      return MONTHS
    },
  },
}
</script>

<style scoped></style>
