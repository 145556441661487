<template>
  <v-sheet elevation="1" rounded class="d-flex flex-column mb-4">
    <span class="ml-3 grey--text py-2">{{ title }}</span>
    <v-divider></v-divider>
    <dashboard-data-box
      title="Missing reconcile"
      color="#FFCCCB"
      :value="dollarFormatter(reconcileObj.unreconciled_amount)"
    />
    <dashboard-data-box
      title="Total unreconciled"
      color="#FFCCCB"
      :value="reconcileObj.unreconciled_count"
    />
    <dashboard-data-box
      title="Total reconciled"
      color="#02BC77"
      :value="reconcileObj.reconciled_count"
    />
    <dashboard-data-box
      title="Reconcile Percent"
      color="#8126C4"
      :value="toPercent(reconcileObj.reconciled_percent)"
    />
  </v-sheet>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import dashboardDataBox from 'components/accounting/dashboard-data-box.vue'
export default {
  name: 'DataFilterButton',
  props: ['reconcileObj', 'title'],
  mixins: [FormattersMixin],
  components: {
    dashboardDataBox,
  },
}
</script>

<style scoped>
.border-primary {
  border-color: var(--v-primary-darken1) !important;
}
</style>
