<template>
  <div v-if="stats && (isAdmin || isPropertyManager)">
    <v-container fluid>
      <v-row align="center">
        <v-col cols="12" sm="auto">
          <contractor-payments-date-range
            :contractor-id="chosenContractorId"
            @change="getStats(1)"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <contractor-select
            :filter-change="contractorSelected"
            label="Choose contractor"
            :value="chosenContractorId"
            :hide-details="true"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            outlined
            dense
            hide-details
            :items="divisions"
            clearable
            label="Division"
            @change="divisionSelected"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="payrollFilter"
            :items="['Staff', '3rd party']"
            multiple
            outlined
            dense
            hide-details
            label="Employee Type"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="sortBy"
            :items="[
              { text: 'Unpaid', value: 'unpaid' },
              { text: 'Manual', value: 'manual' },
            ]"
            outlined
            dense
            hide-details
            label="Sort By"
            @change="getStats(1)"
          />
        </v-col>

        <v-spacer />
        <v-col cols="12" sm="1">
          <v-tooltip
            v-if="payableUsersWithMissingBankInfo.length"
            bottom
            open-delay="100"
          >
            <template #activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-btn
                  class="mx-5"
                  color="primary"
                  icon
                  @click="openModalOfContractorsWithMissingBankInfo"
                >
                  <v-icon>mdi-bank-minus</v-icon>
                </v-btn>
              </span>
            </template>
            <span>Contractors With Missing Bank Info</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :options="pagination"
      :server-items-length="pagination.itemsLength"
      :headers="headers"
      :loading="fetchingData"
      :items="stats"
      :footer-props="{
        'items-per-page-options': [5, 10, 15, 20],
      }"
      @pagination="paginationChanged($event)"
    >
      <template #top>
        <div class="text-end">
          <v-btn small color="success" @click="getStats(1, true)">
            <v-icon small left>fas fa-table</v-icon> Export
          </v-btn>
        </div>
      </template>
      <template #item="{ item }">
        <contractor-lt-stats :stats="item" @show-details="showDetails" />
      </template>
    </v-data-table>
    <v-dialog v-model="isDetailsModal" max-width="1200">
      <contractor-listing-task-details @marked-as-processing="getStats()" />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ContractorLtStats from 'components/contractor-lt-stats'
import ContractorListingTaskDetails from 'components/contractor-listing-task-details'
import ContractorPaymentsDateRange from 'components/contractor-payments-date-range'
import ContractorSelect from 'components/contractor-select'

export default {
  components: {
    ContractorSelect,
    ContractorPaymentsDateRange,
    ContractorListingTaskDetails,
    ContractorLtStats,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: ['miniVersion'],
  data: () => ({
    fetchingData: true,
    isDetailsModal: false,
    sortBy: 'unpaid',
    chosenContractorId: null,
    payrollFilter: ['Staff', '3rd party'],
    divisionFilter: null,
    pagination: {
      page: 1,
      itemsPerPage: 10,
      itemsLength: null,
    },
    headers: [
      { text: 'Contractor Name', value: 'name', sortable: false },
      { text: 'Recipient Name', value: 'ach_recipient_name', sortable: false },
      { text: 'Hours', value: 'hours', align: 'center', sortable: false },
      {
        text: 'Unpaid',
        value: 'amount_unpaid',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Manual',
        value: 'manual_unpaid',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Routine',
        value: 'routine_unpaid',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Processing',
        value: 'amount_processing',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Paid',
        value: 'amount_paid',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Total',
        value: 'amount',
        align: 'center',
        sortable: false,
      },
      { text: 'Status', value: 'status', align: 'center', sortable: false },
      { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    ],
  }),
  watch: {
    payrollFilter(newVal, oldVal) {
      if (newVal.length === 0) {
        this.payrollFilter = [...oldVal]
      } else if (oldVal.length > 0) {
        this.getStats(1)
      }
    },
  },
  computed: {
    ...mapState({
      paymentsStats: 'listingTasksPaymentsStats',
      dateRange: 'contractorPaymentsDateRange',
    }),
    ...mapGetters('users', ['payableUsersWithMissingBankInfo']),
    stats() {
      return this.paymentsStats || []
    },
  },
  methods: {
    ...mapMutations(['updateLtdSelectedIDS']),
    ...mapActions([
      'getListingTaskPaymentsStats',
      'getContractorPaymentsDetails',
    ]),
    async getStats(page, excel = false) {
      try {
        this.fetchingData = true
        if (page) this.pagination.page = page
        const onPayrollFilter = !this.payrollFilter.includes('3rd party')
          ? true
          : !this.payrollFilter.includes('Staff')
          ? false
          : undefined
        const res = await this.getListingTaskPaymentsStats({
          from: this.dateRange.from,
          to: this.dateRange.to,
          id: this.chosenContractorId || undefined,
          page: this.pagination.page,
          perPage: this.pagination.itemsPerPage,
          onPayrole: onPayrollFilter,
          division: this.divisionFilter,
          sort_by: this.sortBy,
          excel,
        })
        if (this.pagination.page === 1 && !excel) {
          this.pagination.itemsLength = parseInt(res.pagi_info.count)
        }
        this.fetchingData = false
      } catch (e) {
        this.fetchingData = false
      }
    },
    paginationChanged(pagination) {
      const page = this.pagination.page
      const itemsPerPage = this.pagination.itemsPerPage
      this.pagination = pagination
      if (
        page !== pagination.page ||
        itemsPerPage !== pagination.itemsPerPage
      ) {
        this.getStats()
      }
    },
    contractorSelected(id) {
      this.chosenContractorId = id
      this.getStats(1)
    },
    divisionSelected(val) {
      this.divisionFilter = val
      this.getStats(1)
    },
    showDetails(id) {
      this.isDetailsModal = true
      this.getContractorPaymentsDetails({
        id,
        from: this.dateRange.from,
        to: this.dateRange.to,
      })
    },
    openModalOfContractorsWithMissingBankInfo() {
      this.$store.commit('showModal', {
        name: 'UsersListModal',
        maxWidth: 950,
        isPersistent: false,
        props: {
          title: 'Contractors With Missing Bank Info',
          users: this.payableUsersWithMissingBankInfo,
        },
      })
    },
  },
  mounted() {
    this.getStats()
  },
  beforeDestroy() {
    this.updateLtdSelectedIDS(new Set())
  },
}
</script>
