import { render, staticRenderFns } from "./accounting-pnl-tab.vue?vue&type=template&id=0ba90fee&scoped=true&"
import script from "./accounting-pnl-tab.vue?vue&type=script&lang=js&"
export * from "./accounting-pnl-tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ba90fee",
  null
  
)

export default component.exports