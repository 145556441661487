<template>
  <v-sheet>
    <conversation-drawer />
    <task-calendar-filters :open-on-change="false" @change="onFiltersChanged" />
    <v-data-table
      :headers="headers"
      :items="items"
      disable-pagination
      disable-sort
      fixed-header
      hide-default-footer
      height="70vh"
    >
      <template #item.status="{ item }"> {{ item.status }} </template>
      <template #item.amount="{ item }">
        {{ toMoney(item.request_info.amount) }}
      </template>
      <template #item.check_in_date="{ item }">
        {{ parseDate(item.request_info.check_in_date) }}
      </template>
      <template #item.guest_name="{ item }">
        <a
          class="text-decoration-underline blue-grey--text text--darken-4 font-weight-medium"
          @click="openConversation(item.request_info.conversation_id)"
          >{{ item.request_info.guest_name }}</a
        >
      </template>
      <template #item.task="{ item }">
        <a
          class="text-decoration-underline blue--text font-weight-medium"
          @click="openTaskDrawer(item.id)"
          >#{{ item.id }}</a
        >
      </template>
      <template #item.status="{ item }">
        <v-sheet width="140px">
          <lt-status
            :listing-task="item"
            small="true"
            @change="onStatusChange({ id: item.id, status: $event })"
            @after-change="getTasks"
          />
        </v-sheet>
      </template>
    </v-data-table>
    <v-pagination
      v-if="listingTasksPagination"
      v-model="page"
      :length="pageLength"
      prev-icon="chevron_left"
      next-icon="chevron_right"
      class="mt-3 mb-3 flex"
      @input="getTasks"
    />
  </v-sheet>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
import TaskCalendarFilters from 'components/calendar/task-calendar-filters.vue'
import ConversationDrawer from 'components/crm/conversation-drawer.vue'
import LtMixin from 'components/mixins/lt-mixin'
import formattersMixin from 'components/mixins/formatters-mixin'
import LtStatus from 'components/listing-tasks/lt-status'

export default {
  name: 'Requests',
  components: {
    TaskCalendarFilters,
    ConversationDrawer,
    LtStatus,
  },
  props: ['catalog'],
  mixins: [LtMixin, formattersMixin],
  data() {
    return {
      page: 1,
      headers: [
        { text: 'Status', value: 'status', align: 'center', width: '180px' },
        { text: 'Task', value: 'task', width: '180px', align: 'center' },
        { text: 'Guest', value: 'guest_name' },
        { text: 'Store Item', value: 'request_info.store_item' },
        { text: 'Price', value: 'amount' },
        { text: 'Check-in', value: 'check_in_date' },
        { text: 'Listing', value: 'request_info.listing_nickname' },
      ],
    }
  },
  async mounted() {
    await this.updateExcludedFilters([
      'listingCatalogItemId',
      'division',
      'project_id',
      'openedBy',
      'Show Only',
    ])
    if (this.catalog) this.getTasks()
  },
  methods: {
    ...mapActions(['getRequestTasks']),
    ...mapMutations('taskCalendar', ['updateExcludedFilters']),

    openConversation(conversation) {
      this.$router.push({ query: { conversation } })
    },
    openTaskDrawer(taskId) {
      this.addToQuery({ reportTaskDrawer: taskId })
    },
    onFiltersChanged() {
      this.page = 1
      this.getTasks()
    },
    onStatusChange(val) {
      console.log(val)
    },
    getTasks() {
      const filters = this.prepareFiltersPayload(this.filters, {})
      this.getRequestTasks({
        filters: {
          ...filters,
          page: this.page,
          listingCatalogItemId: this.catalog,
        },
      })
    },
  },
  computed: {
    ...mapState([
      'listingTasks',
      'listingTasksPagination',
      'listingTaskLoading',
    ]),
    ...mapGetters('taskCalendar', ['filters']),
    pageLength() {
      return Math.ceil(
        this.listingTasksPagination.tasks_count /
          this.listingTasksPagination.per_page
      )
    },
    items() {
      return [...this.listingTasks]
    },
  },
}
</script>

<style></style>
