var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_c('div',{staticClass:"grey lighten-4"},[_c('v-container',{staticClass:"max-w-1600 pb-0"},[_c('v-tabs',{attrs:{"slider-color":"white","background-color":"#f6f6f7","active-class":"white","grow":_vm.isMobile},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[_c('v-tab',{attrs:{"disabled":!(
              _vm.showModule('accounting') &&
              (_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)
            ),"href":"#accounting"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$calculator")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Bank Transactions')))])],1),_c('v-tab',{attrs:{"disabled":!(
              _vm.hasAbility('listings-owners-payments') &&
              _vm.showModule('accounting')
            ),"href":"#listings-payments"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-credit-card-marker-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Owners Payments')))])],1),_c('v-tab',{attrs:{"disabled":!(
              _vm.showModule('accounting') &&
              _vm.showModule('ops') &&
              (_vm.isPropertyManager ||
                _vm.isAdmin ||
                _vm.isAccountant ||
                _vm.isHotelReception)
            ),"href":"#contractors-payments"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$bill")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Contractors Payments')))])],1),_c('v-tab',{attrs:{"href":"#expenses"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$accounting")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Expenses')))])],1),_c('v-tab',{attrs:{"href":"#expense-types"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("view_timeline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Expense types')))])],1),(_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)?_c('v-tab',{attrs:{"href":"#res"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$money")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Reservations')))])],1):_vm._e(),(_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)?_c('v-tab',{attrs:{"href":"#payments"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("$finance")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Payments')))])],1):_vm._e(),(_vm.showModule('accounting') && _vm.hasAbility('management-payments'))?_c('v-tab',{attrs:{"href":"#management-payments"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-credit-card-chip-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('Management Payments')))])],1):_vm._e(),(_vm.showModule('accounting') && _vm.hasAbility('ach-transactions'))?_c('v-tab',{attrs:{"href":"#ach-transactions"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-cash-fast")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v(_vm._s(_vm.$t('ACH Transactions')))])],1):_vm._e(),(
            _vm.showModule('accounting') && _vm.hasAbility('modify-bank-accounts')
          )?_c('v-tab',{attrs:{"href":"#bank-accounts"}},[_c('v-icon',{staticClass:"mr-md-2"},[_vm._v("mdi-bank-outline")]),_c('span',{staticClass:"d-none d-md-inline text-capitalize"},[_vm._v("Bank Accounts")])],1):_vm._e()],1)],1)],1),_c('div',[_c('div',[_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.paramsTab),callback:function ($$v) {_vm.paramsTab=$$v},expression:"paramsTab"}},[(_vm.hasAbility('accounting-dash'))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"dash"}},[_c('accounting-dash')],1):_vm._e(),(_vm.hasAbility('modify-bank-accounts'))?_c('v-tab-item',{attrs:{"value":"bank-accounts"}},[_c('bank-accounts')],1):_vm._e(),_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"expenses"}},[(_vm.paramsTab === 'expenses')?_c('expenses',{staticClass:"mt-3",attrs:{"show-filters":true,"management-view":true,"enable-global-create":true}}):_vm._e()],1),_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"expense-types"}},[(_vm.paramsTab === 'expense-types')?_c('expense-types'):_vm._e()],1),_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"payments"}},[_c('payments',{staticClass:"mt-3",attrs:{"show-filters":true,"management-view":true,"enable-global-create":true}})],1),_c('v-tab-item',{staticClass:"main-background",attrs:{"value":"res"}},[_c('reservation-manager')],1),(_vm.hasBankAccounts || _vm.isAdmin || _vm.isAccountant)?_c('v-tab-item',{staticClass:"pl-4 pb-2 pr-4 main-background",attrs:{"value":"accounting"}},[_c('bank-transactions',{staticClass:"pt-3"})],1):_vm._e(),(
            _vm.isPropertyManager || _vm.isAdmin || _vm.isAccountant || _vm.isHotelReception
          )?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"contractors-payments"}},[_c('listing-tasks-payments')],1):_vm._e(),(_vm.hasAbility('listings-owners-payments'))?_c('v-tab-item',{staticClass:"pl-4 pb-2 pr-4 main-background",attrs:{"value":"listings-payments"}},[_c('listings-payments',{attrs:{"natcha-support":_vm.config.is_natcha_support,"payment-period":_vm.config.listing_payment_period}})],1):_vm._e(),(_vm.hasAbility('management-payments'))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"management-payments"}},[_c('management-payments',{attrs:{"natcha-support":_vm.config.is_natcha_support}})],1):_vm._e(),(_vm.hasAbility('ach-transactions'))?_c('v-tab-item',{staticClass:"pl-4 pr-4 main-background",attrs:{"value":"ach-transactions"}},[(_vm.paramsTab === 'ach-transactions')?_c('ach-transactions'):_vm._e()],1):_vm._e(),(_vm.hasAbility('bi-accounting'))?_c('v-tab-item',{attrs:{"value":"bi-accounting"}},[_c('iframe',{attrs:{"width":"100%","height":"1000","src":"https://app.powerbi.com/reportEmbed?reportId=8e5ff6a2-1fc5-41af-8eb5-bb9617d6d171&appId=6afcd18f-37df-4c94-8ad0-7fe3dd4f5ad5&autoAuth=true&ctid=cfaa53b4-8414-410c-ad73-470f4cecfeab&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QtYS1wcmltYXJ5LXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9","frameborder":"0","allowFullScreen":"true"}})]):_vm._e()],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }