<template>
  <form-dialog title="Add Invoice" @submit="submitInvoice">
    <template #activator="{ on }">
      <v-btn
        color="info"
        small
        dark
        depressed
        class="text-capitalize py-4"
        v-on="on"
      >
        <v-icon class="mr-2" small color="white">mdi-plus</v-icon>Add Invoice
        Item
      </v-btn>
    </template>
    <div>
      <v-select
        v-model="type"
        class="mr-2"
        label="Type"
        outlined
        dense
        :rules="[required]"
        :items="invoiceTypes"
        @change="typeChange"
      />
      <v-radio-group
        v-model="taxRelation"
        class="pb-2 ma-0"
        row
        hide-details
        :disabled="type === 'TAX'"
      >
        <v-radio label="Owner" value="owner" />
        <v-radio label="PMC" value="pmc" />
      </v-radio-group>
      <v-text-field
        v-model.number="amount"
        outlined
        dense
        label="Amount ($)"
        :rules="[required]"
      />
    </div>
    <v-text-field
      v-model="description"
      outlined
      dense
      label="Description"
      :rules="[required]"
    />
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRulesMixin from 'components/mixins/form-rules-mixin'

export default {
  components: { FormDialog },
  mixins: [FormRulesMixin],
  props: ['reservation'],
  data() {
    return {
      type: 'FEE',
      taxRelation: 'owner',
      amount: null,
      description: '',
      invoiceTypes: [
        {
          value: 'FEE',
          text: 'FEE',
        },
        { text: 'TAX', value: 'TAX' },
      ],
    }
  },
  methods: {
    resetForm() {
      this.type = null
      this.amount = null
      this.description = null
    },
    typeChange() {
      if (this.type === 'TAX') {
        this.taxRelation = 'pmc'
      }
    },
    submitInvoice() {
      this.$emit('add-invoice', {
        amount: this.amount,
        description: this.description,
        type: this.type,
        tax_relation: this.taxRelation,
      })
      this.resetForm()
    },
  },
}
</script>
