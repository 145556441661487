<template>
  <form-dialog
    ref="`TaxProfile${taxProfile.id}`"
    :title="$t('Edit Tax Profile')"
    :max-width="900"
    @submit="updateTaxProfile"
  >
    <template #activator="{ on }">
      <v-btn
        color="primary"
        class="text-capitalize"
        icon
        elevation="0"
        v-on="on"
      >
        <v-icon size="25">edit</v-icon>
      </v-btn>
    </template>
    <v-text-field
      v-model="taxProfile.name"
      outlined
      dense
      :label="$t('Name')"
      required
    />
    <div v-for="(tax, ind) in taxProfile.taxes" :key="ind" class="pa-2">
      <v-row class="align-center">
        <v-col cols="6">
          <v-autocomplete
            v-model="tax.tax_type"
            outlined
            dense
            :items="taxTypes"
            label="Tax Type"
            :rules="[required]"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="tax.name"
            outlined
            dense
            :label="$t('Name')"
            required
          />
        </v-col>
        <v-col cols="2">
          <v-text-field
            v-model="tax.amount"
            type="number"
            outlined
            dense
            :label="$t('Amount')"
            required
          />
        </v-col>
        <v-col cols="3">
          <v-select
            v-model="tax.units"
            outlined
            dense
            :items="taxUnits"
            label="Unit"
            :rules="[required]"
          />
        </v-col>
        <v-col v-if="tax.units === 'fixed'" cols="auto">
          <v-select
            v-model="defaultQuantifier"
            outlined
            dense
            disabled
            :items="taxQuantifier"
            label="Quantifier"
            :rules="[required]"
          />
        </v-col>
        <v-col v-if="tax.units === 'percentage'" cols="6">
          <v-select
            v-model="tax.applicable_to_s"
            outlined
            dense
            :items="taxApplicableTo"
            label="Tax applicable to"
            :rules="[required]"
          />
        </v-col>
        <v-col cols="1">
          <v-icon class="ml-auto" small color="error" @click="removeTax(ind)"
            >$trash</v-icon
          >
        </v-col>
        <v-col v-if="tax.tax_type.toLowerCase().includes('fee')" cols="12">
          <v-radio-group
            v-model="tax.tax_relation"
            class="pb-2 ma-0"
            hide-details
            row
          >
            <v-radio label="Belongs to Owner" value="owner" />
            <v-radio label="Belongs to PMC" value="pmc" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-divider />
    </div>
    <v-btn outlined class="my-3" color="info" @click="addTax"
      ><v-icon left>fas fa-plus</v-icon> Add Tax
    </v-btn>
  </form-dialog>
</template>

<script>
import FormDialog from 'components/common/form-dialog'
import FormRules from 'components/mixins/form-rules-mixin'
import FormattersMixin from 'components/mixins/formatters-mixin'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TaxProfileEditModal',
  components: { FormDialog },
  mixins: [FormRules, FormattersMixin],
  props: ['taxDataSet', 'taxProfile'],
  data() {
    return {
      taxProfiles: {
        name: null,
        taxes: [],
      },
      toRemove: [],
    }
  },

  computed: {
    taxTypes() {
      if (this.taxDataSet !== null) {
        return Object.keys(this.taxDataSet.tax_types)
      }
      return []
    },
    taxUnits() {
      return [
        { text: this.currencySign, value: 'fixed' },
        { text: '%', value: 'percentage' },
      ]
    },
    defaultQuantifier() {
      return this.taxQuantifier[0]
    },
    taxQuantifier() {
      if (this.taxDataSet !== null) {
        return Object.values(this.taxDataSet.tax_quantifier)
      }
      return []
    },
    taxApplicableTo() {
      return [
        { text: 'Accommodation Fare', value: 'AF' },
        { text: 'Accommodation Fare + Cleaning', value: 'AF,CF' },
      ]
    },
  },
  methods: {
    ...mapActions('taxProfile', ['updateTaxProfile']),
    addTax() {
      this.taxProfile.taxes.push({
        tax_type: '',
        units: '',
        quantifier: 'per_stay',
        applicable_to: '',
        tax_relation: 1,
      })
    },
    removeTax(idx) {
      this.toRemove.push(this.taxProfile.taxes[idx].id)
      this.taxProfile.taxes.splice(idx, 1)
    },
    async updateTaxProfile() {
      this.$store.commit('updateLoading', true)
      await this.$store
        .dispatch('taxProfile/updateTaxProfile', {
          tax_profile: {
            id: this.taxProfile.id,
            name: this.taxProfile.name,
            taxes: this.taxProfile.taxes,
            tax_relation: this.taxProfile.tax_relation,
          },
          removed: this.toRemove,
        })
        .finally(() => {
          this.$store.commit('updateLoading', false)
          this.toRemove = []
        })
    },
  },
}
</script>

<style scoped></style>
