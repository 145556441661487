<template>
  <v-sheet>
    <div class="d-flex justify-space-between align-center my-3">
      <div class="text-h6">Personnel</div>
      <v-btn
        v-if="!hideSave"
        small
        color="primary"
        :disabled="disabledSave"
        @click="updateListing"
        >Save
      </v-btn>
    </div>
    <v-sheet max-height="700" class="overflow-y-auto px-3">
      <div>
        <contractor-select
          v-for="type in types"
          :key="type"
          class="mt-2"
          :disabled="disabled || !hasAbility('edit-personal')"
          :value="personal[type]"
          :label="personalTitle(type)"
          :filter-change="setPersonal"
          :add-on-data="type"
        />
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
import CommonFunctions from 'components/mixins/common_functions'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import ContractorSelect from '../contractor-select'

export default {
  components: {
    ContractorSelect,
  },
  mixins: [CommonFunctions, PermissionsMixin],
  props: [
    'disabled',
    'hideSave',
    'personal',
    'onChange',
    'autoSuggest',
    'overridePersonalTypes',
  ],
  computed: {
    types() {
      return this.overridePersonalTypes || this.personalTypes
    },
  },
  data() {
    return {
      disabledSave: true,
    }
  },
  methods: {
    setPersonal(val, type) {
      this.disabledSave = false
      this.personal[type] = val
      this.onChange(this.personal)
    },
    async updateListing() {
      await this.$store.dispatch('listings/updateListing', {
        id: this.$route.params.id,
        payload: { personal: this.personal },
      })
      this.disabledSave = true
    },
    personalTitle(val) {
      switch (val) {
        case 'project_manager':
          return 'Project Manager'
        case 'host':
          return 'Host'
        case 'region_manager':
          return 'Region Manager'
        case 'realator':
          return 'Realtor'
        case 'customer_success':
          return 'Customer Success'
        case 'office_manager':
          return 'Office Manager'
        case 'designer':
          return 'Designer'
        case 'cleaning_supervisor':
          return 'Cleaning Supervisor'
        case 'pool':
          return 'Pool'
        case 'pest':
          return 'Pest'
        case 'landscape':
          return 'Landscape'
        case 'accounting':
          return 'Accounting'
        default:
          return val
      }
    },
  },
}
</script>
