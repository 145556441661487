<template>
  <v-sheet width="100%">
    <v-card class="pa-4">
      <div class="mb-4 text-h5 font-weight-bold">Reservations</div>
      <form-dialog
        max-width="300"
        title="Import reservations from PMS"
        @submit="submit"
      >
        <template #activator="{ on }">
          <v-btn color="success" small v-on="on">
            <v-icon small left>fas fa-table</v-icon>Import Reservations</v-btn
          >
        </template>
        <v-select v-model="pms" outlined dense :items="pmsList" label="PMS" />
        <v-file-input
          v-model="file"
          accept=".xlsx,.csv,.xls"
          label="Excel sheet"
          outlined
          dense
          hide-details
        />
      </form-dialog>
      <form-dialog
        title="Please pick a date from to update all reservations"
        max-width="500"
        @submit="updateAllReservations"
      >
        <template #activator="{ on }">
          <v-btn color="info" small class="mx-2" v-on="on">
            {{ $t('Update reservations by date') }}</v-btn
          >
        </template>
        <date-picker v-model="from" :label="$t('Check-in from')" />
      </form-dialog>
    </v-card>
  </v-sheet>
</template>

<script>
import FormDialog from 'components/common/form-dialog.vue'
import axios from '@/axios/config'
import DatePicker from 'components/form-fields/date-picker.vue'
export default {
  components: {
    DatePicker,
    FormDialog,
  },
  props: {
    tenant: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pms: null,
      file: null,
      from: null,
      pmsList: ['guesty', 'hostaway', 'avantio', 'kross', 'lodgify', 'track'],
    }
  },
  methods: {
    async updateAllReservations() {
      try {
        await axios.post(`/api/sync-reservations`, { from: this.from })
      } catch (error) {
        console.error(error)
      }
    },
    submit() {
      const formData = new FormData()
      formData.append('file', this.file)
      formData.append('pms_type', this.pms)
      formData.append('tenant_id', this.tenant)
      axios
        .post('/api/reservations/pms-import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {})
    },
  },
}
</script>

<style></style>
