<template>
  <v-card mt-4 flat align="center">
    <v-card-title>Owner dashboard</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          v-for="(value, feature) in settings.dashboard_settings"
          :key="feature"
          class="d-flex align-center flex-column"
        >
          <v-checkbox
            v-model="settings.dashboard_settings[feature]"
            :label="feature | capitalize"
            @change="toggleFeature(feature)"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ['settings'],
  methods: {
    toggleFeature(feature) {
      this.$emit('update:settings', {
        ...this.settings,
        dashboard_settings: {
          ...this.settings.dashboard_settings,
          [feature]: this.settings.dashboard_settings[feature],
        },
      })
    },
  },
  filters: {
    capitalize: value => value.charAt(0).toUpperCase() + value.slice(1),
  },
}
</script>
