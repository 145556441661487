<template>
  <div class="d-flex flex-0 pa-3">
    <v-sheet width="6" class="rounded" :color="color" />
    <div class="d-flex justify-space-between flex align-center">
      <div
        class="text-body-2 grey--text text--darken-1 text-capitalize font-weight-medium px-3"
      >
        {{ title }}
      </div>
      <div :class="injectedClass" class="text-h6 font-weight-medium px-3 my-2">
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DashboardDataBox',
  props: ['title', 'value', 'color', 'injectedClass'],
}
</script>

<style scoped></style>
