<template>
  <v-row v-if="stats.total_sales">
    <v-col cols="12" md="6" class="d-flex flex-column">
      <v-sheet elevation="1" rounded class="d-flex flex-column">
        <dashboard-data-box
          title="Total reservations"
          color="#8126C4"
          :value="dollarFormatter(stats.total_sales)"
        />
        <dashboard-data-box
          title="Management fees"
          color="#E69B51"
          :value="dollarFormatter(stats.management_fee)"
        />
        <dashboard-data-box
          title="Cleaning fees"
          color="#4367B5"
          :value="dollarFormatter(stats.cleaning_income)"
        />
        <dashboard-data-box title="Store" color="#2FBE50" :value="140000" />
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6">
      <v-sheet rounded class="pa-4" elevation="1">
        <line-chart
          graph-id="income_total"
          label="Incomes"
          title="Income Management Fee"
          :stats-object="chartsConfig"
          :config="chartsConfig"
          color="#02BC77"
        />
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import DashboardDataBox from 'components/accounting/dashboard-data-box.vue'
import FormattersMixin from 'components/mixins/formatters-mixin'
import LineChart from 'components/line-chart.vue'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import CommonFunctions from 'components/mixins/common_functions'

export default {
  name: 'AccountingManagementFeeTab',
  mixins: [FormattersMixin, CommonFunctions],
  components: { LineChart, DashboardDataBox },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    dates: {
      type: Object,
    },
    year: {
      type: Number,
    },
    yearly: {
      type: Boolean,
    },
  },
  computed: {
    months() {
      return MONTHS
    },

    chartsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data: this.stats.incomes_months.map(v => round(v, 2)),
              borderColor: '#169CA8',
              backgroundColor: '#169CA8',
              fill: false,
              label: this.year,
            },
            {
              data: this.stats.incomes_prev_months.map(v => round(v, 2)) || [],
              borderColor: '#7ED9D9',
              backgroundColor: '#7ED9D9',
              fill: false,
              label: this.year - 1,
            },
            {
              data:
                this.stats.incomes_prev_pit_months.map(v => round(v, 2)) || [],
              borderColor: '#E69B51',
              backgroundColor: '#E69B51',
              fill: false,
              label: 'Point in time -1y',
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            labels: {
              usePointStyle: true,
            },
            display: true,
            align: 'start',
            textAlign: 'left',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
}
</script>

<style scoped></style>
