import { render, staticRenderFns } from "./accounting-balance-tab.vue?vue&type=template&id=caee9a82&scoped=true&"
import script from "./accounting-balance-tab.vue?vue&type=script&lang=js&"
export * from "./accounting-balance-tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "caee9a82",
  null
  
)

export default component.exports