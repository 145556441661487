<template>
  <div class="grey lighten-3 py-1">
    <v-card
      v-for="item in items"
      :key="item.id"
      class="h-100 mx-1 my-2 pa-2 pt-4"
      elevation="0"
    >
      <div class="d-flex">
        <taskim-text-field
          v-model="item.description"
          :disabled="Boolean(projectId)"
          @change="onFieldChange(item, 'description', $event)"
        />
        <v-btn
          color="info"
          icon
          small
          class="ms-1"
          @click="$emit('show-feed', item)"
        >
          <v-icon
            v-if="
              item.internal_comments ||
              (item.listing_task_comments && item.listing_task_comments.length)
            "
            small
          >
            mdi-comment-text
          </v-icon>
          <v-icon v-else small color="info lighten-2">
            mdi-comment-text-outline
          </v-icon>
        </v-btn>
      </div>
      <div class="d-flex mt-3 align-center justify-space-between overflow-auto">
        <div class="scale-80 ms-n2">
          <lt-priority
            :value="item.priority"
            @input="onFieldChange(item, 'priority', $event)"
          />
        </div>
        <div class="scale-80">
          <lt-status
            :small="true"
            :listing-task="item"
            @after-change="$emit('lt-after-change')"
            @change="$emit('lt-status-update', { id: item.id, status: $event })"
          />
        </div>
        <div class="ms-2 me-1">
          <assignee-select
            :key="item.assigned_contractor_id"
            :users="activeUsers"
            :value="usersMap[item.assigned_contractor_id]"
            @change="onFieldChange(item, 'assigned_contractor_id', $event.id)"
          />
        </div>
        <div class="scale-80">
          <listing-picker
            :value="item.listing_id"
            @input="onFieldChange(item, 'listing_id', $event)"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import TaskimTextField from 'components/taskim/taskim-text-field.vue'
import LtPriority from 'components/listing-tasks/lt-priority.vue'
import AssigneeSelect from 'components/common/assignee-select.vue'
import ListingPicker from 'components/listing/listing-picker.vue'
import LtStatus from 'components/listing-tasks/lt-status.vue'

export default {
  name: 'TaskimSectionMobile',
  components: {
    TaskimTextField,
    LtPriority,
    AssigneeSelect,
    ListingPicker,
    LtStatus,
  },
  props: ['items', 'projectId', 'activeUsers', 'usersMap'],
  data() {
    return {
      hello: 'world',
    }
  },
  methods: {
    onFieldChange(item, field, value) {
      this.$emit('listingTaskFieldChange', item, field, value)
    },
  },
}
</script>

<style scoped>
.scale-80 {
  transform: scale(0.8);
}
</style>
