<template>
  <v-skeleton-loader v-if="!listing.id" />
  <div
    v-else
    class="d-flex flex-column flex-sm-row grey lighten-4 pa-0 pa-sm-2"
  >
    <profile-upload
      v-if="uploadable || editProfilePic"
      v-model="listing.picture"
      @on-upload="uploadPicture"
    />
    <v-avatar v-else rounded :size="isMobile ? 60 : 120">
      <v-img :src="listing.picture || placeholderImg" />
      <v-btn
        v-if="!editProfilePic"
        fab
        info
        x-small
        absolute
        center
        color="primary"
        class="mx-2"
        @click="editProfilePic = true"
      >
        <v-icon small center>$complete</v-icon>
      </v-btn>
    </v-avatar>
    <div class="d-flex flex flex-column px-0 px-sm-2 justify-space-around">
      <div class="d-flex flex-column align-start px-0 px-sm-2">
        <div
          class="text-subtitle-2 text-sm-subtitle-1 font-weight-medium d-flex align-center flex-wrap-reverse mt-1"
        >
          <div
            :class="isReport && 'info--text text-decoration-underline pointer'"
            @click="goToListing"
          >
            {{ listing.nickname }}
          </div>
          <div class="text-caption text-capitalize">
            <tag
              color="tertiary"
              :inverted="true"
              size="xs"
              :class="['d-inline-block text-uppercase']"
            >
              {{ listing.app_integration_name }}
            </tag>
            <span v-if="listing.tags && !isInvestor">
              <tag
                v-for="tag in listing.tags"
                :key="tag"
                color="primary"
                :inverted="true"
                size="xs"
                :class="['d-inline-block ml-1']"
              >
                {{ tag }}
              </tag>
            </span>
          </div>
        </div>
        <div class="text-subtitle-2 font-weight-regular text-sm-subtitle-1">
          {{ listing.title }}
        </div>
        <div
          class="text-caption d-flex align-center pointer info--text my-1"
          @click="directionsTo(listing)"
        >
          <v-icon left small color="info">mdi-map-marker</v-icon>
          {{ listing.address }}
        </div>
        <div v-if="doorCode">
          <div class="font-weight-medium">
            <span class="text-caption">{{ $t('Door Code') }}:</span>
            {{ doorCode }}
          </div>
        </div>
        <div class="d-flex align-center">
          <!--          <span v-if="guestyId && managementRoles" class="mr-4">-->
          <!--            <v-icon small :color="listed ? 'success' : 'secondary'">{{-->
          <!--              listed ? 'fas fa-check-circle' : 'fas fa-times-circle'-->
          <!--            }}</v-icon>-->
          <!--            <a-->
          <!--              class="small-text blue&#45;&#45;text"-->
          <!--              :target="isMobile ? '_self' : '_blank'"-->
          <!--              :href="-->
          <!--                listing.pms_name === 'Guesty'-->
          <!--                  ? `https://app.guesty.com/properties/${guestyId}`-->
          <!--                  : ''-->
          <!--              "-->
          <!--            >-->
          <!--              {{ listing.pms_name }} link-->
          <!--            </a>-->
          <!--            <span v-if="masterGuestyId == guestyId" class="pl-2 small-text">-->
          <!--              &ndash;&gt; Master</span-->
          <!--            >-->
          <!--          </span>-->
          <span v-if="managementRoles">
            <span
              v-for="(dup, index) in isMultiUnit ? multiUnits : dups"
              :key="index"
              class="mr-4"
            >
              <v-icon small :color="dup.listed ? 'success' : 'secondary'">{{
                dup.listed ? 'fas fa-check-circle' : 'fas fa-times-circle'
              }}</v-icon>
              <a
                :key="dup.id"
                class="small-text blue--text"
                :href="`/dashboard/edit/${dup.id}/listing-channel-general`"
                target="_blank"
              >
                {{ dup.nickname }}
              </a>
              <span
                v-if="listing.master_guesty_id == dup.guesty_id"
                class="pl-2 small-text"
              >
                --> Master</span
              >
            </span>
          </span>
        </div>
      </div>

      <div class="d-flex justify-space-between flex-wrap py-2 py-sm-0">
        <div v-if="!isReport" class="d-flex align-center">
          <confirmation-modal
            v-if="hasAbility('super-admin') || hasAbility('disable-listing')"
            :text="`Are you sure you want to ${
              isActive ? 'deactivate' : 'activate'
            } this listing?`"
            title="Listing status"
            :disabled="!isMgmt"
            @action="toggleActive"
          >
            <template #activator="{ on }">
              <v-switch
                :disabled="!isMgmt"
                color="success"
                class="mt-0"
                readonly
                :hide-details="true"
                :label="$t('Active')"
                :input-value="isActive"
                v-on="on"
              />
            </template>
          </confirmation-modal>
          <confirmation-modal
            v-if="hasAbility('super-admin') || hasAbility('disable-listing')"
            :text="`Are you sure you want to ${
              isListed ? 'unlist' : 'list'
            } this listing?`"
            title="Listing status"
            :disabled="!isMgmt"
            @action="toggleListed"
          >
            <template #activator="{ on }">
              <v-switch
                class="ml-2 ml-sm-5 mt-0"
                color="info"
                :disabled="!isMgmt"
                :label="$t('Listed')"
                :hide-details="true"
                :input-value="isListed"
                v-on="on"
              />
            </template>
          </confirmation-modal>
        </div>
        <div class="d-flex align-center mt-1 mt-sm-0">
          <div v-if="Object.keys(filteredPersonal).length && isMgmt">
            <v-menu bottom offset-y>
              <template #activator="{ on }">
                <v-btn
                  class="mx-1"
                  elevation="0"
                  outlined
                  :small="!isMobile"
                  :x-small="isMobile"
                  v-on="on"
                  ><v-icon left x-small>fas fa-user</v-icon> Personnel
                </v-btn>
              </template>
              <div class="grey lighten-3 text-body-2 pa-2">
                <div
                  v-for="[type, id] in Object.entries(filteredPersonal)"
                  :key="type"
                  class="pa-1"
                >
                  <span
                    class="black--text font-weight-medium mr-1 text-capitalize"
                  >
                    {{ type.replace(/[_]/g, ' ') }}:</span
                  ><span>{{ nameById(id) }}</span>
                </div>
              </div>
            </v-menu>
          </div>
          <v-btn
            v-if="linkShow && addressPricing && showModule('pricing')"
            :small="!isMobile"
            :x-small="isMobile"
            class="mx-1"
            :to="addressPricing"
            outlined
          >
            <span class="text-capitalize">{{ $t('Pricing') }} </span>
          </v-btn>
          <v-btn
            v-if="linkShow && addressOps"
            :small="!isMobile"
            :x-small="isMobile"
            class="mx-1"
            :to="addressOps"
            outlined
          >
            <span class="text-capitalize">{{ $t('Ops') }} </span>
          </v-btn>
          <investment-details-btn
            v-if="
              !isDesignedVr &&
              (isInvestor || isAdmin) &&
              !listing.purchase_price &&
              !isReport
            "
            :listing="listing"
            mode="warn"
            class="mx-1"
          />
          <v-btn
            v-if="linkShow"
            :small="!isMobile"
            :x-small="isMobile"
            class="mx-1"
            :to="isMgmt ? viewLink : mgmtLink"
            outlined
          >
            <span class="text-capitalize">
              {{ isMgmt ? $t('View') : $t('Manage') }}
            </span>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DeviceMixin from 'components/mixins/device-mixin'
import PermissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'
import LtMixin from 'components/mixins/lt-mixin'
import pick from 'lodash/fp/pick'
import InvestmentDetailsBtn from 'components/listing/investment-details-btn.vue'
import ProfileUpload from 'components/common/profile-upload.vue'
import axios from 'axios'
import Tag from 'components/common/tag.vue'
import ConfirmationModal from 'components/modals/confirmation-modal.vue'
import placeholderImg from '@/assets/images/home.png'

export default {
  name: 'ListingInfoPanel',
  components: { ConfirmationModal, ProfileUpload, InvestmentDetailsBtn, Tag },
  mixins: [DeviceMixin, PermissionsMixin, CommonFunctions, LtMixin],
  props: ['isReport', 'doorCode', 'isMgmt', 'listing'],
  data() {
    return {
      dupLoading: false,
      pic: this.listing,
      placeholderImg,
      editProfilePic: false,
      syncing: false,
    }
  },
  computed: {
    linkShow() {
      return this.isAdmin || this.isAccountant
    },
    filteredPersonal() {
      return pick(
        ['pest', 'pool', 'cleaning_supervisor', 'landscape'],
        this.listing.personal
      )
    },
    listingId() {
      return this.listing?.id
    },
    isListed() {
      return this.listing.listed
    },
    isActive() {
      return this.listing.active
    },
    dups() {
      return this.listing.dups.filter(l => l.id !== this.listing.id)
    },
    isMultiUnit() {
      return this.listing.is_multi_unit
    },
    multiUnits() {
      return this.listing.multi_units.filter(l => l.id !== this.listing.id)
    },
    uploadable() {
      return !this.listing.picture
    },
    addressPricing() {
      if (this.listing.pricing_id) {
        return { path: `/dashboard/pricing/${this.listing.pricing_id}` }
      } else {
        return null
      }
    },
    mgmtLink() {
      return { path: `/dashboard/edit/${this.listing.id}` }
    },
    viewLink() {
      return { path: `/dashboard/property/${this.listing.id}` }
    },
    addressOps() {
      return this.listing.id
        ? {
            path: `/dashboard/service-calls/area-report/listing/${this.listing.id}?filter=high-open`,
          }
        : null
    },
  },
  methods: {
    async uploadPicture(pic) {
      this.$store.commit('setListingProfileImage', pic)
      await axios.post(`/api/listings/${this.listing.id}/update`, {
        picture: pic,
      })
    },
    toggleListed() {
      this.listing.listed = !this.listing.listed
      this.$store.dispatch('listings/updateListing', {
        id: this.listing.id,
        payload: { listed: this.listing.listed },
      })
    },
    goToListing() {
      if (this.isReport) {
        this.$router.push({
          path: `/dashboard/edit/${this.listing.id}/`,
        })
      }
    },
    toggleActive() {
      this.listing.active = !this.isActive
      this.$store.dispatch('listings/activateListing', {
        id: this.listing.id,
        is_active: this.isActive,
      })
    },
  },
  watch: {
    image(val) {
      this.pic = val
    },
  },
}
</script>

<style scoped></style>
