<template>
  <v-autocomplete
    v-model="tenant"
    :items="tenants"
    :label="$t('Tenant')"
    dense
    item-text="company_name"
    item-value="tenant_id"
    hide-details
    outlined
    class="pb-2 mt-1"
    @change="loginAsTenant"
  />
</template>

<script>
export default {
  name: 'LoginAsTenant',
  data() {
    return {
      tenant: null,
      tenants: [],
    }
  },
  async mounted() {
    this.tenant = this.$store.state.user?.tenant_id
    const { data } = await this.$store.dispatch('tenants/getTenants')
    this.tenants = data
  },
  methods: {
    loginAsTenant(val) {
      if (!val) {
        return
      }
      window.open(`/api/login-as-tenant/${this.tenant}`, '_blank')
    },
  },
}
</script>

<style scoped></style>
