<template>
  <v-row>
    <v-col cols="12" md="3" class="d-flex flex-column">
      <reconcile-card
        title="Reservations"
        :reconcile-obj="stats.reservations"
      />
    </v-col>
    <v-col cols="12" md="3" class="d-flex flex-column">
      <reconcile-card title="Expenses" :reconcile-obj="stats.expenses" />
    </v-col>
    <v-col cols="12" md="3" class="d-flex flex-column">
      <reconcile-card title="Payments" :reconcile-obj="stats.payments" />
    </v-col>
    <v-col cols="12" md="3" class="d-flex flex-column">
      <reconcile-card
        title="Bank Transactions"
        :reconcile-obj="stats.bank_transactions"
      />
    </v-col>
  </v-row>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import reconcileCard from 'components/accounting/reconcile-card.vue'
export default {
  mixins: [FormattersMixin],
  components: { reconcileCard },
  props: {
    stats: {
      type: Object,
      default: () => ({}),
    },
    year: {
      type: Number,
    },
    yearly: {
      type: Boolean,
    },
  },
  computed: {
    months() {
      return MONTHS
    },
    chartsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data:
                this.stats.owners_expenses_sum_months.map(v => round(v, 2)) ||
                [],
              borderColor: '#169CA8',
              backgroundColor: '#169CA8',
              fill: false,
              label: this.year,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            labels: {
              usePointStyle: true,
            },
            display: true,
            align: 'start',
            textAlign: 'left',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
  },
}
</script>

<style scoped></style>
