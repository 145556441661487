<template>
  <v-data-table
    :headers="headers"
    item-key="id"
    :items="items"
    disable-sort
    hide-default-footer
  >
    <template v-if="!isMobile" #item="{ item }">
      <tr class="title-black">
        <td
          :class="[
            'text-capitalize ',
            { 'font-weight-medium': !!item.adjusted_items.length },
          ]"
        >
          {{ item.title }}
        </td>
        <td :class="{ 'font-weight-medium': !!item.adjusted_items.length }">
          {{ item.amount }}
        </td>
        <td>
          <div v-if="!!item.additional" class="taskim-task-actions flex-center">
            <ConfirmationModal
              text="Are you sure you want to delete this invoice item?"
              @action="removeInvoiceItem(item)"
            >
              <template #activator="{ on }">
                <v-tooltip bottom open-delay="200">
                  <template #activator="item">
                    <span v-bind="item.type" v-on="item.on">
                      <v-btn color="info" icon small v-on="on">
                        <v-icon small>mdi-delete-forever-outline</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Delete Invoice Item</span>
                </v-tooltip>
              </template>
            </ConfirmationModal>
          </div>
        </td>
      </tr>
      <tr v-for="(it, ind) in item.adjusted_items" :key="ind">
        <td class="secondary--text lighten-2 px-5 text-capitalize">
          {{ it.title }}
        </td>
        <td class="secondary--text lighten-2">{{ it.amount }}</td>
        <td>
          <div v-if="!!it.additional" class="taskim-task-actions flex-center">
            <ConfirmationModal
              text="Are you sure you want to delete this invoice item?"
              @action="removeInvoiceItem(it)"
            >
              <template #activator="{ on }">
                <v-tooltip bottom open-delay="200">
                  <template #activator="slot">
                    <span v-on="slot.on">
                      <v-btn color="info" icon small v-on="on">
                        <v-icon small>mdi-delete-forever-outline</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <span>Delete Invoice Item</span>
                </v-tooltip>
              </template>
            </ConfirmationModal>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import FormattersMixin from 'components/mixins/formatters-mixin'
import Common_functions from 'components/mixins/common_functions'
import ConfirmationModal from 'components/modals/confirmation-modal'
import deviceMixin from 'components/mixins/device-mixin'

export default {
  name: 'ReservationInvoicesTable',
  mixins: [FormattersMixin, Common_functions, deviceMixin],
  components: { ConfirmationModal },
  props: ['items'],
  data() {
    return {
      headers: [
        { text: 'Invoices', value: 'title', width: '80%' },
        { text: '', value: 'amount' },
        { text: '', value: 'actions' },
      ],
    }
  },
  computed: {},
  methods: {
    removeInvoiceItem(item) {
      this.$emit('remove-invoice', {
        invoice: item,
      })
    },
  },
}
</script>

<style scoped>
.title-black {
  color: #3e3f59;
}
:deep() .v-data-table-header > tr > th {
  font-weight: 600;
  font-size: 14px !important;
  color: black !important;
}
</style>
