<template>
  <div>
    <v-container>
      <div class="pb-0">
        <v-row class="justify-space-between align-center">
          <v-col v-if="false" cols="auto">
            <listing-select
              item-text="nickname"
              label="Property"
              :hide-details="true"
            />
          </v-col>
          <v-progress-circular v-show="isLoading" fixed indeterminate />
          <v-col cols="auto" class="ml-auto">
            <month-year-toggle
              :is-yearly="isYearlyStats"
              @toggle="changeYearMonth"
            />
          </v-col>
          <v-col cols="auto">
            <month-year-picker
              v-model="dates"
              class="ml-auto"
              :is-yearly="isYearlyStats"
              @change="filterChanged"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="2">
            <data-filter-button
              title="Company P&L"
              icon="$accounting"
              :active="tabFilter === 'pnl'"
              @click.native="tabFilter = 'pnl'"
            />
          </v-col>
          <v-col cols="6" md="2">
            <data-filter-button
              title="Receivables"
              icon="$accounting"
              :active="tabFilter === 'income'"
              @click.native="tabFilter = 'income'"
            />
          </v-col>
          <v-col cols="6" md="2">
            <data-filter-button
              title="Payables"
              icon="$expenses"
              :active="tabFilter === 'expenses'"
              @click.native="tabFilter = 'expenses'"
            />
          </v-col>
          <v-col cols="6" md="2">
            <data-filter-button
              title="Balance"
              icon="$wallet"
              :active="tabFilter === 'balance'"
              @click.native="tabFilter = 'balance'"
            />
          </v-col>
          <v-col cols="6" md="2">
            <data-filter-button
              title="Reconciliation"
              icon="$money"
              :active="tabFilter === 'reconcile'"
              @click.native="tabFilter = 'reconcile'"
            />
          </v-col>
        </v-row>
        <accounting-pnl-tab
          v-if="tabFilter === 'pnl'"
          :year="dates.year"
          :stats="accountingStats.pnl"
          :yearly="isYearlyStats"
          :dates="dates"
        />
        <accounting-management-fee-tab
          v-if="tabFilter === 'income'"
          :year="dates.year"
          :stats="accountingStats.income"
          :yearly="isYearlyStats"
          :dates="dates"
        />
        <accounting-expenses-tab
          v-if="tabFilter === 'expenses'"
          :year="dates.year"
          :stats="accountingStats.expenses"
          :yearly="isYearlyStats"
          :dates="dates"
        />
        <accounting-balance-tab
          v-if="tabFilter === 'balance'"
          :year="dates.year"
          :stats="accountingStats.balance"
          :yearly="isYearlyStats"
          :dates="dates"
        />
        <accounting-reconcile-tab
          v-if="tabFilter === 'reconcile'"
          :year="dates.year"
          :stats="accountingStats.reconcile"
          :yearly="isYearlyStats"
          :dates="dates"
        />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import formattersMixin from 'components/mixins/formatters-mixin'
import deviceMixin from 'components/mixins/device-mixin'
import permissionsMixin from 'components/mixins/permissions-mixin'
import CommonFunctions from 'components/mixins/common_functions'

import navigationMixin from 'components/mixins/navigation-mixin'
import MonthYearPicker from 'components/common/month-year-picker'
import axios from '@/axios/config'
import ListingSelect from 'components/listing-select.vue'
import DataFilterButton from 'components/accounting/data-filter-button.vue'
import { MONTHS } from '@/consts'
import round from 'lodash/fp/round'
import AccountingManagementFeeTab from 'components/accounting/accounting-management-fee-tab.vue'
import AccountingExpensesTab from 'components/accounting/accounting-expenses-tab.vue'
import AccountingBalanceTab from 'components/accounting/accounting-balance-tab.vue'
import AccountingReconcileTab from 'components/accounting/accounting-reconcile-tab.vue'
import AccountingPnlTab from 'components/accounting/accounting-pnl-tab.vue'

import MonthYearToggle from 'components/month-year-toggle.vue'

export default {
  components: {
    MonthYearToggle,
    AccountingExpensesTab,
    AccountingManagementFeeTab,
    DataFilterButton,
    ListingSelect,
    MonthYearPicker,
    AccountingPnlTab,
    AccountingBalanceTab,
    AccountingReconcileTab,
  },
  mixins: [
    formattersMixin,
    deviceMixin,
    permissionsMixin,
    navigationMixin,
    CommonFunctions,
  ],
  data() {
    return {
      isYearlyStats: false,
      tabFilter: 'pnl',
      isLoading: false,
      accountingStats: {},
      dates: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
    }
  },
  async mounted() {
    if (this.currentUser) {
      this.getAccountingData({})
    }
  },
  methods: {
    changeYearMonth(val) {
      this.isYearlyStats = val
      this.getAccountingData({})
    },
    async getAccountingData(_, params) {
      this.isLoading = true
      if (!params) {
        params = {}
      }
      params['year'] = this.dates.year
      params['month'] = this.dates.month + 1
      params['yearly'] = this.isYearlyStats
      const { data } = await axios.get(`/api/company_accounting`, {
        params,
      })
      this.isLoading = false
      this.accountingStats = data
    },
    filterChanged() {
      this.getAccountingData({})
    },
  },

  computed: {
    ...mapState('regions', ['regions']),
    ...mapGetters(['currentUser', 'listingCities']),
    months() {
      return MONTHS
    },
    chartsConfig() {
      return {
        type: 'bar',
        data: {
          labels: this.months,
          datasets: [
            {
              data:
                this.accountingStats.incomes_this_year.map(v => round(v, 2)) ||
                [],
              borderColor: '#169CA8',
              backgroundColor: '#169CA8',
              fill: false,
              label: this.dates.year,
            },
            {
              data:
                this.accountingStats.incomes_prev_year.map(v => round(v, 2)) ||
                [],
              borderColor: '#7ED9D9',
              backgroundColor: '#7ED9D9',
              fill: false,
              label: this.dates.year - 1,
            },
          ],
        },
        options: {
          tooltips: {
            backgroundColor: 'white',
            bodyFontColor: '#707070',
            titleFontColor: '#000000',
            displayColors: false,
          },
          maintainAspectRatio: true,
          legend: {
            labels: {
              usePointStyle: true,
            },
            display: true,
            align: 'start',
            textAlign: 'left',
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  drawTicks: false,
                },
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      }
    },
    listingRegions() {
      return this.regions.map(r => ({
        text: r.name,
        value: r.id,
      }))
    },
  },
}
</script>
<style scoped></style>
